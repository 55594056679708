import React, { useEffect } from 'react';
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "../Data/QuestionData";
import TextInput from "../Components/TextInput";
import MailInput from "../Components/MailInput";
import fetchData from '../contexts/API';
import toast from 'react-hot-toast';

export default function EnumReg() {

    const navigate = useNavigate();
    const { state, handleInputChange, flushEnumReg } = useContext(StateContext);
    useEffect(() => {
        document.title = "Enum Reg | Travel Demand Digital Survey Tool"
      }, [])
    const apiUrl = '/enumReg';
    const postData = {
        enumeratorName: state.enumeratorRegistration?.enumeratorName,
        enumeratorEmail: state.enumeratorRegistration?.enumeratorEmail,
        userRole: state.enumeratorRegistration?.userRole
    };
    const handleClickNext = (event) => {
        event.preventDefault();
        fetchData(apiUrl, postData)
            .then((responseData) => {
                if (responseData.status === 200) {
                    navigate('');
                    toast.success("Enumerator successfully registered!")
                }
                else {
                    toast.error("Enumerator is already registered!")
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong while registering the enumerator")
            }).finally(() => {
                flushEnumReg()
            });

    }

    return (
        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4'>
                <div className='w-full p-[1%] font-semibold'>{QuestionData.enumeratorRegistration.title}</div>
                <TextInput
                    questionData={QuestionData.enumeratorRegistration.enumeratorName}
                    handleInputChange={handleInputChange}
                    value={state.enumeratorRegistration?.enumeratorName}
                    required={true}
                />
                <MailInput
                    questionData={QuestionData.enumeratorRegistration.enumeratorEmail}
                    handleInputChange={handleInputChange}
                    value={state.enumeratorRegistration?.enumeratorEmail}
                // required={true}

                />
                <TextInput
                    questionData={QuestionData.enumeratorRegistration.enumeratorUserRole}
                    handleInputChange={handleInputChange}
                    value={state.enumeratorRegistration?.userRole}
                    required={true}
                />


            </div>
            <button type='submit' className="bg-other hover:bg-slate-900 text-white  py-2 px-2 rounded my-8">
                Submit
            </button>
        </form>
    )
}
