import React, { useState, useEffect, useContext } from 'react';
import ScratchCard from 'react-scratchcard-v2';
import images from '../assets/images';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../contexts/StateContext';
import fetchData from '../contexts/API';

const Thankyou = () => {

  const navigate = useNavigate()

  const { state } = useContext(StateContext);

  const [rewaredIndex, setRewaredIndex] = useState('')
  const [rewardShown, setRewardShown] = useState(1)


  const apiUrl = '/gethhscore';
  useEffect(() => {
    document.title = "Thank you | Travel Demand Digital Survey Tool"
    fetchData(apiUrl, { hhRefNo: state.hhRefNo })
      .then((responseData) => {
        setRewaredIndex(responseData.data.hh_score)
        setRewardShown(responseData.data.hh_score_shown)
      })
  }, [])


  //api url to set the status of reward shown as true
  const rewardShownUrl = '/updatehhscore'
  //use effect gets called when ever user finishes the scratch card
  useEffect(() => {
    fetchData(rewardShownUrl, { hhRefNo: state.hhRefNo })
  }, [rewardShown])

  const { scratch, reward1 } = images;

  function reward() {
    if (rewaredIndex === 0) return <img src={reward1} alt="" />
    if (rewaredIndex === 1) return <img src={reward1} alt="" />
    if (rewaredIndex === 2) return <img src={reward1} alt="" />
    if (rewaredIndex === 3) return <img src={reward1} alt="" />
  }

  return (
    <dialog open className='rounded-lg border-black animate-fade-in-up' >
      {
        rewardShown ?
          reward() : //only show the image if peson has already scratched the card
          <ScratchCard
            width={400}
            height={400}
            image={scratch}
            finishPercent={30}
            onComplete={() => { //gets called when user finished scratching the card
              setRewardShown(1)
            }}
            customBrush={{
              image: scratch,
              width: 40,
              height: 40
            }}
          >
            {reward()}
          </ScratchCard>
      }

      <button onClick={() => navigate('/')} className='flex bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
        Thanks
      </button>
    </dialog>
  );
};

export default Thankyou;