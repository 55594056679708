import React, { useContext, useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import Login from '../Components/Login'
import { gapi } from 'gapi-script'
import fetchData from '../contexts/API'
import {StateContext} from '../contexts/StateContext'

const CLIENT_ID = '417493031128-2h317vv4sk2q8jvpvfi39pqqro03vtio.apps.googleusercontent.com'


const Auth = () => {
  useEffect(() => {
    document.title = "Auth | Travel Demand Digital Survey Tool"
  }, [])

  return (
    <div className='flex justify-center item-center'>
      <Login buttonText={"Login with Google"}/>
    </div>
)
}

export default Auth


// useEffect(() => {
//   gapi.load('client:auth2', () => {
//     gapi.client.init({
//       clientId: CLIENT_ID,
//       scope: ""
//     })
//   })
// }, [])

// return (
//   <div>
//     <Login />
//   </div>