import React, { useState } from 'react';

//This component takes 6 props
//1. questionData - this is an object that contains the question, options, field 
//  a. question - this is a string that contains the question
//  b. options - this is an array that contains the options to be searched from and selected
//  c. field - this is an string that contains the action type
//2. handleInputChange - this is a function that is used to update the state
//3. value - this contains the value of the selected option
//4. required - this is a boolean value that is used to make the checkbox required
//5. width - this is a string that is used to set the width of the checkbox
//6. bgcolor - this is a string that is used to set the background color of the checkbox


const SelectSearchInput = ({ questionData, handleInputChange, required, value, width, bgcolor, isDynamic }) => {

  const { question, options, field } = questionData;
  const [values, setValues] = useState('');

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setValues(selectedValue);
    const selectedIndex = options.findIndex((option) => option === selectedValue);
    handleInputChange(field, selectedIndex + 1);
  };

  // Generate a unique ID for zthe datalist element
  const dataListId = `datalist-${field}`;

  return (
    <div className={`p-[2%] hover:bg-main rounded-lg ${bgcolor} w-[${width}] flex-grow`}>
      <div>
        <div className="mb-1 font-bold ">{question}</div>
        <input
          type="search"
          list={dataListId} // Use the generated unique ID for the datalist
          placeholder="Choose from the given list"
          value={options[value - 1]}
          onChange={handleChange}
          className="border-2 border-slate-600 rounded mb-2 w-full p-1"
          required={required}
        />
        {/* Render the datalist options as options of the select element */}
        <datalist id={dataListId}>
          {options.map((option, index) => (
            <option key={index} value={option} />
          ))}
        </datalist>
      </div>
    </div>
  );
};

export default SelectSearchInput;
