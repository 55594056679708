import { FaUser, FaCog, FaChartBar,FaPercentage,FaUsers} from 'react-icons/fa';
import { BsFillHouseFill,BsPersonLinesFill } from 'react-icons/bs';
import {BiTrip} from 'react-icons/bi';
import {MdOutlineCommute} from 'react-icons/md';

export const DashboardData={
    data:{
        "hh_count":5,
        "person_count":5,
        "trip_count":5,
        "per_capita_trip_rate":5,
        "dumy1":5,
        "dumy2":5,
        trip_tt_data:{
        "0-5": 4,
        "5-10": 14,
        "10-15": 30,
        "15-20": 20,
        "20-25": 18,
        "25-30": 12,
        "30-35": 6,
        "35-40": 3,
        "40-45": 1,}
    }
}
export const icons = [
    <BsFillHouseFill />,
    <FaUsers />,
    <MdOutlineCommute />,
    <FaChartBar />,
    <BiTrip />,
    <FaCog />,
    <FaUser />,
    <FaPercentage />,
  ];
