import React, { useEffect, useState } from 'react';
import { StateContext } from "../contexts/StateContext";
import fetchData from '../contexts/API';
import { useContext } from "react";
import axios from 'axios';
import * as XLSX from 'xlsx';


const apiUrl = '/surveyReport';
var postData = {};

const SurveyReport = () => {
  useEffect(() => {
    document.title = "Survey Report | Travel Demand Digital Survey Tool"
  }, [])
  const HandleClick = (event) => {
    event.preventDefault();
    fetchData(apiUrl, postData)
      .then((responseData) => {
        const { hh_info, person_info, trip_info } = responseData.data;
        const workbook = XLSX.utils.book_new();

        const hhSheet = XLSX.utils.json_to_sheet(hh_info);
        XLSX.utils.book_append_sheet(workbook, hhSheet, 'Household Info');

        const personSheet = XLSX.utils.json_to_sheet(person_info);
        XLSX.utils.book_append_sheet(workbook, personSheet, 'Person Info');

        const tripSheet = XLSX.utils.json_to_sheet(trip_info);
        XLSX.utils.book_append_sheet(workbook, tripSheet, 'Trip Info');

        XLSX.writeFile(workbook, 'survey_report.xlsx');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <div>
      <h1 className='text-center font-bold text-5xl' >Survey Report</h1>
      <button onClick={HandleClick} className="bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8">Download Survey Report</button>
    </div>
  );
}

export default SurveyReport;
