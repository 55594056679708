import React, { createContext, useReducer, useEffect } from "react";
import StateReducer from "./StateReducer";
import initialState from './InitialState'

// const initializer = () =>  
//     JSON.parse(localStorage.getItem('state')) || initialState

//this is the context that will be used to pass the state and the dispatch function
export const StateContext = createContext(initialState);

export const StateContextProvider = ({ children }) => {

    //this is the reducer that will be used to update the state
    //this returns the updated state and the dispatch function
    //the dispatch function is used to update the state by passing an action
    const [state, dispatch] = useReducer(StateReducer, initialState);


    //this function is used to flush the hh info from the state i.e the state is set to initial state
    //for page3 and page4
    const flushHhInfo = () => {
        dispatch(
            {
                type: `FLUSH_HH_INFO`,
                payload: null
            });
    }
    //this function is used to flush the person info from the state i.e the state is set to initial state
    //for page3 and page4
    const flushPersonInfo = () => {
        dispatch(
            {
                type: `FLUSH_PERSON_INFO`,
                payload: null
            });
    }

    //this function is used to flush the trip info from the state i.e the state is set to initial state
    //for page5 and page6

    const flushTripInfo = () => {
        dispatch(
            {
                type: `FLUSH_TRIP_INFO`,
                payload: null
            });
    }
    //this function is used to flush the enumerator registration data from the state i.e the state is set to initial state
    //for enumReg
    const flushEnumReg = () => {
        dispatch(
            {
                type: `FLUSH_ENUM_REG`,
                payload: null
            });
    }

    const flushPersonSummary = () => {
        dispatch(
            {
                type: `FLUSH_PERSON_SUMMARY`,
                payload: null
            }
        )
    }

    //whenever the state changes the current localState is overridden by the new state object
    //with the help of use effect hook
    useEffect(() => {
        localStorage.setItem('localState', JSON.stringify(state));
    }, [state]);

    // Dispatch function is used to update the state
    // This function is passed to the components that need to update the state
    // This function takes two arguments
    // 1. field - which is action type
    // 2. value - which is payload

    const handleInputChange = (field, value) => {
        dispatch(
            //this line is made with assumption that all the fields will
            //be in the format of UPDATE_FIELDNAME
            {
                type: `UPDATE_${field}`,
                payload: value
            });
    }
    const handleLegChange = (field, value) => {
        dispatch({
            type: `APPEND_${field}`,
            payload: value
        });
    }
    const handleLegDelete = (field, value) => {
        dispatch({
            type: `REMOVE_${field}`,
            payload: value
        });
    }

    const handleLegEdit = (index, value) => {
        dispatch({
            type: `EDIT_LEG`,
            payload: {
                index, value
            },
        });
    }

    const handleTripEdit = (data) => {
        dispatch({
            type: `EDIT_TRIP`,
            payload: { data },
        });
    }

    const handlePersonInfoEdit = (data) => {
        dispatch({
            type: `EDIT_PERSON_INFO`,
            payload: {
                data
            }
        })
    }

    const newSurveyReset = () => {
        dispatch({
            type: `FLUSH_NEW_SURVEY`,
        })
    }

    const handleIsEditing = (data) => {
        dispatch({
            type: `IS_EDITING`,
            payload: {
                isEditing: data
            }
        })
    }
    return (
        <StateContext.Provider value={{ state, handleInputChange, flushPersonSummary, newSurveyReset, handleIsEditing, handlePersonInfoEdit, flushHhInfo, flushEnumReg, flushTripInfo, flushPersonInfo, handleLegChange, handleLegDelete, handleLegEdit, handleTripEdit }}>
            {children}
        </StateContext.Provider>
    )
}