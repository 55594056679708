import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home';
import EnumReg from '../Pages/EnumReg';
import Page0 from '../Pages/Page0';
import Page1 from '../Pages/Page1';
import Page2 from '../Pages/Page2';
import Page3 from '../Pages/Page3';
import Page4 from '../Pages/Page4';
import Page5 from '../Pages/Page5';
import Page6 from '../Pages/Page6';
import Summary from '../Pages/Summary';
import SurveyReport from '../Pages/SurveyReport';
import TripSummary from '../Pages/TripSummary';
import ThankYou from '../Pages/ThankYou';
import ProtectedRoute from '../Utils/ProtectedRoute';
import Dashboard from '../Pages/Dashboard';


const RouteList = () => {
  return (
    <Routes>
      {/* <Route exact path="/Auth" element={<Auth />} /> */}
      <Route exact path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route exact path="/EnumReg" element={<ProtectedRoute ><EnumReg /></ProtectedRoute>} />
      <Route exact path="/Page0" element={<ProtectedRoute ><Page0 /></ProtectedRoute>} />
      <Route exact path="/Page1" element={<ProtectedRoute ><Page1 /></ProtectedRoute>} />
      <Route exact path="/Page2" element={<ProtectedRoute ><Page2 /></ProtectedRoute>} />
      <Route exact path="/Page3" element={<ProtectedRoute ><Page3 /></ProtectedRoute>} />
      <Route exact path="/Page4" element={<ProtectedRoute ><Page4 /></ProtectedRoute>} />
      {/* <Route exact path="/Page5" element={<ProtectedRoute ><Page5 /></ProtectedRoute>} /> */}
      <Route exact path="/Page5" element={<Page5> </Page5>} />
      <Route exact path="/Page6" element={<ProtectedRoute ><Page6 /></ProtectedRoute>} />
      <Route exact path="/SurveyReport" element={<ProtectedRoute ><SurveyReport /></ProtectedRoute>} />
      <Route exact path="/Summary" element={<ProtectedRoute ><Summary /></ProtectedRoute>} />
      <Route exact path="/TripSummary" element={<ProtectedRoute ><TripSummary /></ProtectedRoute>} />
      <Route exact path="/ThankYou" element={<ProtectedRoute ><ThankYou /></ProtectedRoute>} />
      <Route exact path="/Dashboard" element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />
    </Routes>
  );
};

export default RouteList;
