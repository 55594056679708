import React, { useContext, useEffect } from 'react'
// import RadioInput from '../Components/RadioInput';
import { StateContext } from '../contexts/StateContext';
// import API from '../Reducers/API';
import { QuestionData } from '../Data/QuestionData';
import NumInput from '../Components/NumInput';
import { useNavigate } from 'react-router-dom';
// import Geolocation from '../Components/geolocation';
import TextInput from '../Components/TextInput';
import fetchData from '../contexts/API';
import toast from 'react-hot-toast';



const Page0 = () => {
  let navigate = useNavigate();
  const { state, handleInputChange, flushHhInfo } = useContext(StateContext);
  useEffect(() => {
    document.title = "Page0 | Travel Demand Digital Survey Tool"
  }, [])
  const apiUrl = '/newhhsurvey';
  const postData = {
    wardNo: state.pageZero?.wardNo,
    enumeratorCode: state.pageZero?.enumeratorCode,
    locationCode: state.pageZero?.locationCode,
  };

  const handleClickNext = (event) => {
    event.preventDefault()
    flushHhInfo()
    fetchData(apiUrl, postData)
      .then((responseData) => {
        if (responseData.status == 1) {

          handleInputChange('HH_REF_NO', responseData.data[0]);
          navigate('/page1');
        }
        else toast.error("Internal Server failed!")
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => toast.success("Page0 Data Saved!"));
  }


  return (
    <form onSubmit={handleClickNext}>
      <h1 className='font-semibold text-2xl text-center mb-8'>Terms and Conditions</h1>
      <p className='bg-white p-4 rounded-lg h-88 overflow-auto'>
        Hi
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt dolorem odio expedita
        praesentium vitae enim repudiandae eum quas voluptatibus ad,
        tenetur veritatis aliquid alias quia explicabo amet reprehenderit
        possimus laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quisquam aperiam ullam quae accusamus nihil expedita facere
        aspernatur iure quod rerum reprehenderit at quo iusto doloremque magnam non, sapiente placeat similique!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt dolorem odio expedita
        praesentium vitae enim repudiandae eum quas voluptatibus ad,
        tenetur veritatis aliquid alias quia explicabo amet reprehenderit
        possimus laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quisquam aperiam ullam quae accusamus nihil expedita facere
        aspernatur iure quod rerum reprehenderit at quo iusto doloremque magnam non, sapiente placeat similique!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt dolorem odio expedita
        praesentium vitae enim repudiandae eum quas voluptatibus ad,
        tenetur veritatis aliquid alias quia explicabo amet reprehenderit
        possimus laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quisquam aperiam ullam quae accusamus nihil expedita facere
        aspernatur iure quod rerum reprehenderit at quo iusto doloremque magnam non, sapiente placeat similique!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt dolorem odio expedita
        praesentium vitae enim repudiandae eum quas voluptatibus ad,
        tenetur veritatis aliquid alias quia explicabo amet reprehenderit
        possimus laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quisquam aperiam ullam quae accusamus nihil expedita facere
        aspernatur iure quod rerum reprehenderit at quo iusto doloremque magnam non, sapiente placeat similique!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt dolorem odio expedita
        praesentium vitae enim repudiandae eum quas voluptatibus ad,
        tenetur veritatis aliquid alias quia explicabo amet reprehenderit
        possimus laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quisquam aperiam ullam quae accusamus nihil expedita facere
        aspernatur iure quod rerum reprehenderit at quo iusto doloremque magnam non, sapiente placeat similique!
      </p>
      {/* <div className='flex w-full justify-center align-center bg-white rounded-lg mt-4'> */}
      <div className='flex flex-row flex-wrap bg-white rounded-lg mt-4'>
        {/* <Geolocation handleInputChange={handleInputChange} questionData={QuestionData.pageZero.enumeratorAddress} /> */}
        <NumInput
          bgcolor={"bg-white"}
          questionData={QuestionData.pageZero.wardNo}
          required={true}
          value={state.pageZero.wardNo}
          handleInputChange={handleInputChange}
          width={"100%"}
          min={1}
        />
        <TextInput
          bgcolor={"bg-white"}
          questionData={QuestionData.pageZero.locationCode}
          required={true}
          value={state.pageZero?.locationCode}
          handleInputChange={handleInputChange}
          width={"100%"}
        />
      </div>
      <div className='flex flex-row flex-wrap w-full justify-center rounded-lg mt-4'>
        <button type='submit' className="bg-other hover:bg-slate-900 text-white  py-2 px-2 rounded my-8">
          Accept and Start Survey
        </button>
      </div>
    </form>
  )
}

export default Page0