import React from 'react';

const RadioInput = ({ questionData, handleInputChange, value, required, width, bgcolor }) => {
  const { question, options, field } = questionData;

  const handleChange = (index) => {
    handleInputChange(field, index + 1);
  };

  return (
    // <div className={`w-full p-[2%] hover:bg-main rounded-lg w-[${width}]`}>
    <div className={`w-full p-[2%] hover:bg-main ${bgcolor} w-[${width}]`}>
      <h3 className="text-lg  mb-2 text-gray-800 select-none font-bold">{question}</h3>
      <div className="flex flex-wrap w-full bg-transparent">
        {options.map((answer, index) => (
          <div
            key={index}
            className='my-1 mr-4 bg-white text-gray-800 select-none rounded-lg flex items-center'
          >
            <label className="select-none  p-2 rounded">
              <input
                type="radio"
                id={`answer-${index}`}
                value={index}
                className='mr-1'
                name={field}
                checked={index === value - 1}
                onChange={() => handleChange(index)}
                required={required}
              />
              {answer}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
