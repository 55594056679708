import React from 'react';

//This component takes 6 props
//1. questionData - this is an object that contains the question,field.
//2. handleInputChange - this is a function that is used to update the state
//3. value - this is contains the previously picked date or an emptys string initially
//4. required - this is a boolean value that is used to make the datepicker required
//5. bgcolor - this contains the background colour passed to the component
//6. width - this is to specify the required width for the component.

export default function DatePicker({ questionData, required, handleInputChange, width, bgcolor, value }) {
  const { question, field } = questionData;

  const handleDateChange = (event) => {
    event.preventDefault();
    handleInputChange(field, event.target.value);
  };

  return (
    <div className={`p-[2%] hover:bg-main ${bgcolor} rounded-lg w-[${width}] flex-grow`}>
      <div className="mb-1 font-bold">{question}</div>
      <input
        type="date" // Change the input type to "date"
        className="border-2 border-slate-600 mb-1 p-1 rounded w-full "
        value={value}
        onChange={handleDateChange}
        required={required}
      />
    </div>
  );
}
