import React, { useEffect } from "react";
import RadioInput from "../Components/RadioInput";
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
import NumInput from "../Components/NumInput";
import { QuestionData } from "../Data/QuestionData";
import TextInput from "../Components/TextInput";
import SearchCurrent from "../Components/SearchCurrent";
import SelectSearchInput from "../Components/SelectSearchInput";
import fetchData from '../contexts/API';
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";



export default function Page6() {
    const navigate = useNavigate();
    const { state, handleInputChange, handleIsEditing } = useContext(StateContext);
    useEffect(() => {
        document.title = "Page6 | Travel Demand Digital Survey Tool"
    }, [])
    useEffect(() => {
        if ((state.pageFive.tripStopCount) <= 0) {
            handleInputChange('TRIP1_PURPOSE', -100);
            handleInputChange('TRIP1_IN_THE_ROUTE', -100);
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', -100);
            handleInputChange('TRIP1_MAIN_MODE_USAGE', -100);
            handleInputChange('TRIP1_OTHER_MODE', -100);
            handleInputChange('TRIP1_TRAVEL_TIME', -100);
            handleInputChange('TRIP1_TIME_SPENT', -100);
        }
        if ((state.pageFive.tripStopCount) > 0 && state.pageFive.tripStop1Purpose == -100) {
            handleInputChange('TRIP1_PURPOSE', -10);
            handleInputChange('TRIP1_IN_THE_ROUTE', -10);
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', 0);
            handleInputChange('TRIP1_MAIN_MODE_USAGE', -10);
            handleInputChange('TRIP1_OTHER_MODE', -10);
            handleInputChange('TRIP1_TRAVEL_TIME', 0);
            handleInputChange('TRIP1_TIME_SPENT', 0);
        }

    }, [state.pageFive.tripStopCount]);
    useEffect(() => {
        if ((state.pageFive.tripStopCount) <= 1) {
            handleInputChange('TRIP2_PURPOSE', -100);
            handleInputChange('TRIP2_IN_THE_ROUTE', -100);
            handleInputChange('TRIP2_ADDITIONAL_DISTANCE', -100);
            handleInputChange('TRIP2_MAIN_MODE_USAGE', -100);
            handleInputChange('TRIP2_OTHER_MODE', -100);
            handleInputChange('TRIP2_TRAVEL_TIME', -100);
            handleInputChange('TRIP2_TIME_SPENT', -100);
        }
        if ((state.pageFive.tripStopCount) > 1 && (state.pageFive.tripStop2Purpose) === -100) {
            handleInputChange('TRIP2_PURPOSE', -10);
            handleInputChange('TRIP2_IN_THE_ROUTE', -10);
            handleInputChange('TRIP2_ADDITIONAL_DISTANCE', 0);
            handleInputChange('TRIP2_MAIN_MODE_USAGE', -10);
            handleInputChange('TRIP2_OTHER_MODE', -10);
            handleInputChange('TRIP2_TRAVEL_TIME', 0);
            handleInputChange('TRIP2_TIME_SPENT', 0);
        }

    }, [state.pageFive.tripStopCount]);

    useEffect(() => {
        if ([1, -100].includes(state.pageSix.tripStop1InTheRoute)) {
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', -100);
        }
        if ((state.pageSix.tripStop1InTheRoute !== 1) && (state.pageSix.tripStop1InTheRoute !== -100)) {
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', 0);
        }
    }, [state.pageSix.tripStop1InTheRoute]);
    useEffect(() => {
        if ([1, -100].includes(state.pageSix.tripStop2InTheRoute)) {
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', -100);
        }
        if ((state.pageSix.tripStop2InTheRoute !== 1) && (state.pageSix.tripStop2InTheRoute !== -100)) {
            handleInputChange('TRIP1_ADDITIONAL_DISTANCE', 0);
        }
    }, [state.pageSix.tripStop2InTheRoute]);

    useEffect(() => {
        if ([1, -100].includes(state.pageSix.tripStop1MainModeUsage)) {
            handleInputChange('TRIP1_OTHER_MODE', -100);
        }
        else if (state.pageSix.tripStop1OtherMode === -100) {
            handleInputChange('TRIP1_OTHER_MODE', -10);
        }
    }, [state.pageSix.tripStop1MainModeUsage]);
    useEffect(() => {
        if ([1, -100].includes(state.pageSix.tripStop2MainModeUsage)) {
            handleInputChange('TRIP2_OTHER_MODE', -100);
        }
        else if (state.pageSix.tripStop2OtherMode === -100) {
            handleInputChange('TRIP2_OTHER_MODE', -10);
        }
    }, [state.pageSix.tripStop2MainModeUsage]);

    useEffect(() => {
        if ((state.pageSix.moreTrips === 1) || (state.pageFive.tripMainPurpose === 2)) {
            handleInputChange('TP_NOT_HOME_REASON', -100);
        }
        else if (state.pageSix.tpNotHomeReason === -100) {
            handleInputChange('TP_NOT_HOME_REASON', "");
        }
    }, [state.pageSix.moreTrips, state.pageFive.tripMainPurpose]);

    useEffect(() => {
        if ([1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, -100].includes(state.pageSix.oosMode)) {
            handleInputChange('SOURCE_TRANSIT_STATION_ADDRESS', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_LONG', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_LAT', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_MODE', -100);
        }
        if ([3, 13, 14].includes(state.pageSix.oosMode) && (state.pageFive.sourceTransitStationAddress === -100)) {
            handleInputChange('SOURCE_TRANSIT_STATION_ADDRESS', "");
            handleInputChange('SOURCE_TRANSIT_STATION_LONG', "");
            handleInputChange('SOURCE_TRANSIT_STATION_LAT', "");
            handleInputChange('SOURCE_TRANSIT_STATION_MODE', -10);
        }
    }, [state.pageSix.oosMode]);
    useEffect(() => {
        if ((state.pageSix.moreTrips === 1) || (state.pageFive.tripMainPurpose !== 14)) {
            handleInputChange('OOS_VILLAGE', -100);
            handleInputChange('OOS_DISTRICT', -100);
            handleInputChange('OOS_STATE', -100);
            handleInputChange('OOS_MODE', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_ADDRESS', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_LONG', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_LAT', -100);
            handleInputChange('SOURCE_TRANSIT_STATION_MODE', -100);
        }
        if (((state.pageSix.moreTrips === 2) || (state.pageFive.tripMainPurpose === 14)) && (state.pageSix.oosVillage === -100)) {
            handleInputChange('OOS_VILLAGE', "");
            handleInputChange('OOS_DISTRICT', "");
            handleInputChange('OOS_STATE', "");
            handleInputChange('OOS_MODE', -10);
            handleInputChange('SOURCE_TRANSIT_STATION_ADDRESS', "");
            handleInputChange('SOURCE_TRANSIT_STATION_LONG', "");
            handleInputChange('SOURCE_TRANSIT_STATION_LAT', "");
            handleInputChange('SOURCE_TRANSIT_STATION_MODE', -10);
        }
    }, [state.pageSix.moreTrips, state.pageFive.tripMainPurpose]);



    const path = '/page6';
    const postData = {
        tripStop1Purpose: state.pageSix.tripStop1Purpose,
        tripStop1InTheRoute: state.pageSix.tripStop1InTheRoute,
        tripStop1AddlDistance: state.pageSix.tripStop1AddlDistance,
        tripStop1MainModeUsage: state.pageSix.tripStop1MainModeUsage,
        tripStop1OtherMode: state.pageSix.tripStop1OtherMode,
        tripStop1Tt: state.pageSix.tripStop1Tt,
        tripStop1TimeSpent: state.pageSix.tripStop1TimeSpent,
        tripStop2Purpose: state.pageSix.tripStop2Purpose,
        tripStop2InTheRoute: state.pageSix.tripStop2InTheRoute,
        tripStop2AddlDistance: state.pageSix.tripStop2AddlDistance,
        tripStop2MainModeUsage: state.pageSix.tripStop2MainModeUsage,
        tripStop2OtherMode: state.pageSix.tripStop2OtherMode,
        tripStop2Tt: state.pageSix.tripStop2Tt,
        tripStop2TimeSpent: state.pageSix.tripStop2TimeSpent,
        tpNotHomeReason: state.pageSix.tpNotHomeReason,
        moreTrips: state.pageSix.moreTrips,
        oosVillage: state.pageSix.oosVillage,
        oosDistrict: state.pageSix.oosDistrict,
        oosState: state.pageSix.oosState,
        oosMode: state.pageSix.oosMode,
        sourceTransitStationAddress: state.pageSix.sourceTransitStationAddress,
        sourceTransitStationLat: state.pageSix.sourceTransitStationLat,
        sourceTransitStationLong: state.pageSix.sourceTransitStationLong,
        sourceTrasitStationMode: state.pageSix.sourceTrasitStationMode,
        currentPage: state.pageSix.currentPage,
        hhRefNo: state.hhRefNo,
        personNo: state.personNo,
        tripNo: state.tripNo,
    };

    var timeSpent = parseInt(window.performance.now() / 1000)
    const handleClickNext = (event) => {
        event.preventDefault();
        handleInputChange('TIME_SPENT_IN_TI', timeSpent);
        fetchData(path, postData)
            .then((responseData) => {
                if (responseData.status === 1) {
                    navigate('/TripSummary');
                    if(state.isEditing) {
                        toast.success(`Trip ${state.tripNo} updated successfully!`)
                    } else {
                        toast.success(`Trip ${state.tripNo} added successfully!`)
                    }
                }
                else toast.error(responseData.data)
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                handleIsEditing(false)
            });

    }

    return (
        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>
                <div className='w-full p-[1%] font-semibold'>{QuestionData.pageSix.title}</div>

                {[1, 2, 3, 4, 5, 6, 7, 8].includes(state.pageFive.tripStopCount) && (
                    <div>
                        <SelectSearchInput
                            questionData={QuestionData.pageSix.tripStop1Purpose}
                            handleInputChange={handleInputChange}
                            required={true}
                            value={state.pageSix.tripStop1Purpose}
                        />
                        <RadioInput
                            questionData={QuestionData.pageSix.tripStop1InTheRoute}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop1InTheRoute}
                            required={true}
                        />
                        {[2].includes(state.pageSix.tripStop1InTheRoute) && (<NumInput
                            questionData={QuestionData.pageSix.tripStop1AddlDistance}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop1AddlDistance}
                            required={true}
                            width={"50%"}
                        />)}
                        <RadioInput
                            questionData={QuestionData.pageSix.tripStop1MainModeUsage}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop1MainModeUsage}
                            required={true}
                        />
                        {[2].includes(state.pageSix.tripStop1MainModeUsage) && (<SelectSearchInput
                            questionData={QuestionData.pageSix.tripStop1OtherMode}
                            handleInputChange={handleInputChange}
                            required={true}
                            value={state.pageSix.tripStop1OtherMode}
                        />)}
                        <NumInput
                            questionData={QuestionData.pageSix.tripStop1Tt}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop1Tt}
                            required={true}
                            width={"50%"}
                        />
                        <NumInput
                            questionData={QuestionData.pageSix.tripStop1TimeSpent}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop1TimeSpent}
                            required={true}
                            width={"50%"}
                        />
                    </div>
                )}

                {[2, 3, 4, 5, 6, 7, 8].includes(state.pageFive.tripStopCount) && (

                    <div>
                        <SelectSearchInput
                            questionData={QuestionData.pageSix.tripStop2Purpose}
                            handleInputChange={handleInputChange}
                            required={true}
                            value={state.pageSix.tripStop2Purpose}
                        />
                        <RadioInput
                            questionData={QuestionData.pageSix.tripStop2InTheRoute}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop2InTheRoute}
                            required={true}
                        />
                        {[2].includes(state.pageSix.tripStop2InTheRoute) && (
                            <NumInput
                                questionData={QuestionData.pageSix.tripStop2AddlDistance}
                                handleInputChange={handleInputChange}
                                value={state.pageSix.tripStop2AddlDistance}
                                required={true}
                                width={"50%"}
                            />)}
                        <RadioInput
                            questionData={QuestionData.pageSix.tripStop2MainModeUsage}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop2MainModeUsage}
                            required={true}
                        />
                        {[2].includes(state.pageSix.tripStop2MainModeUsage) && (
                            <SelectSearchInput
                                questionData={QuestionData.pageSix.tripStop2OtherMode}
                                handleInputChange={handleInputChange}
                                required={true}
                                value={state.pageSix.tripStop2OtherMode}
                            />)}
                        <NumInput
                            questionData={QuestionData.pageSix.tripStop2Tt}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop2Tt}
                            required={true}
                            width={"50%"}
                        />
                        <NumInput
                            questionData={QuestionData.pageSix.tripStop2TimeSpent}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.tripStop2TimeSpent}
                            required={true}
                            width={"50%"}
                        />

                    </div>
                )}
                <RadioInput
                    questionData={QuestionData.pageSix.moreTrips}
                    handleInputChange={handleInputChange}
                    value={state.pageSix.moreTrips}
                    required={true}
                />
                {[2].includes(state.pageSix.moreTrips) && (
                    (state.pageFive.tripMainPurpose !== 2) &&
                    <TextInput
                        questionData={QuestionData.pageSix.tpNotHomeReason}
                        handleInputChange={handleInputChange}
                        value={state.pageSix.tpNotHomeReason}
                        required={true}
                    />
                )}
                {([2].includes(state.pageSix.moreTrips) &&
                    ((state.pageFive.tripMainPurpose === 14))) &&
                    (<div>
                        {QuestionData.pageSix.oosQuestion}
                        <TextInput
                            questionData={QuestionData.pageSix.oosVillage}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.oosVillage}
                            required={true}
                        />
                        <TextInput
                            questionData={QuestionData.pageSix.oosDistrict}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.oosDistrict}
                            required={true}
                        />
                        <TextInput
                            questionData={QuestionData.pageSix.oosState}
                            handleInputChange={handleInputChange}
                            value={state.pageSix.oosState}
                            required={true}
                        />
                        <SelectSearchInput
                            questionData={QuestionData.pageSix.oosMode}
                            handleInputChange={handleInputChange}
                            required={true}
                            value={state.pageSix.oosMode}
                        />
                        {[3, 13, 14].includes(state.pageSix.oosMode) && (
                            <div>
                                <SearchCurrent questionData={QuestionData.pageSix.sourceTransitStationAddress} required={true} value={[state.pageSix.sourceTransitStationAddress, state.pageSix.sourceTransitStationLat, state.pageSix.sourceTransitStationLong]} handleInputsChange={handleInputChange} />
                                <SelectSearchInput
                                    questionData={QuestionData.pageSix.sourceTrasitStationMode}
                                    handleInputChange={handleInputChange}
                                    required={true}
                                    value={state.pageSix.sourceTrasitStationMode}
                                />
                            </div>
                        )}


                    </div>
                    )}

            </div>
            {/* <button type='submit' className="bg-other hover:bg-slate-900 text-white  py-2 px-2 rounded my-8">
                Next Page
            </button> */}
            <div className="flex flex-1 justify-between items-center">
                <Link to={'/page5'} className="bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8 flex flex-row-reverse justify-between items-center gap-x-3">
                    Back <FaArrowLeft />
                </Link>
                <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
                    Proceed to Person Summary Table <FaArrowRight />
                </button>
            </div>

        </form>
    )

}

