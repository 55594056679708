import toast from "react-hot-toast";

const fetchData = async (endpoint, postData) => {
  // const baseURL = 'http://10.199.22.18:10103';

  // const baseURL = 'https://oshr.cistup.iisc.ac.in:443';
  const baseURL = 'http://10.199.22.18:10103/';
  try {
    const response = await fetch(baseURL + endpoint, {
      method: 'POST',
      headers: {
        'X-API-Key': '280f52923d5958db246b1db0067a51e1',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle any errors
    toast.error("Internal server error!")
    throw error; // Rethrow the error to be caught by the caller
  }
};

export default fetchData;


