import React, { useState } from "react";

const NumInput = ({ questionData, handleInputChange, value, required, width, bgcolor, min = 0, disabled = false, autoFocus = false, message = "" }) => {
  const { question, field } = questionData;
  const [isInputClicked, setIsInputClicked] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const parsedValue = parseInt(e.target.valueAsNumber);
    const numericValue = isNaN(parsedValue) ? null : parsedValue;
    handleInputChange(field, numericValue);
  };

  const handleFocus = () => {
    if (value <= min && !isInputClicked) {
      handleInputChange(field, null); // Remove zero immediately after focus or click
    }
    setIsInputClicked(true);
  };

  const handleBlur = () => {
    setIsInputClicked(false);

    // If the value is null or NaN, set it to 0 when clicking outside of the field
    if (value === null || isNaN(value)) {
      handleInputChange(field, min);
    }
  };

  const handleKeyDown = (e) => {
    // Detect backspace key and remove zero only when backspace is pressed and the value is null
    if (e.key === "Backspace" && value === null) {
      handleInputChange(field, min);
    }
  };

  return (
    <div className={`p-[2%] hover:bg-main ${bgcolor} rounded-lg w-[${width}] flex-grow`}>
      <div>
        <div className="mb-1 font-bold">{question}</div>
        <input
          type="number"
          className="border-2 border-slate-600 mb-1 p-1 rounded w-full "
          placeholder="Enter the number"
          value={value === null ? "" : value}
          onChange={handleChange}
          required={required}
          disabled={disabled}
          min={min}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
        />
        {isInputClicked && message!="" && (<p className="text-sm text-gray-600 font-semibold">Tip: {message}</p>)}
      </div>
    </div>
  );
};

export default NumInput;
