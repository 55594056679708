import React from 'react';

//This component takes 3 props
//1. data - this is a json object which contains key-value pairs returned by the backend.
//2. icon- this contains the name of the icon to be rendered.
//3. backgroundColor - this contains the background colour passed to the component


export default function DashboardCard({ data, icon, backgroundColor }) {
  const { key, value } = data;

  return (
    <div
      className="relative card bg-gray-500 text-white p-8 rounded-lg transition-all duration-300 hover:scale-105 hover:shadow-lg cursor-pointer"
      style={{
        background: backgroundColor, // Use the background color prop
        maxWidth: '400px', // Limiting the maximum width of the card content
      }}
    >
      <div className="flex items-center justify-between mt-4">
        {/* Icon on the left with increased font size */}
        <span className="mr-4 text-6xl">{icon}</span>

        <div className="text-right">
          {/* Value with bigger font size */}
          <h2 className="font-bold text-3xl text-white mb-2">{value}</h2>
          {/* Key with smaller font size */}
          <h3 className="font-bold text-lg text-white">{key}</h3>
        </div>
      </div>
      <p className="m-0 p-0">
        {/* Any additional data you want to display can be placed here */}
      </p>
    </div>
  );
}
