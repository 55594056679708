import React, { useEffect, useState } from "react";
import RadioInput from "../Components/RadioInput";
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
import NumInput from "../Components/NumInput";
import { QuestionData } from "../Data/QuestionData";
import SearchCurrent from '../Components/SearchCurrent';
import Time from '../Components/Time';
import { PersonNumberCheck } from '../Utils/SurveyChecks';
import fetchData from '../contexts/API';
import { Link, useNavigate } from "react-router-dom";
import Geolocation from '../Components/geolocation';
import toast from "react-hot-toast";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


export default function Page3() {

    const navigate = useNavigate();
    const { state, handleInputChange } = useContext(StateContext);
    const [personStartTime, setPersonStartTime] = useState(null)
    useEffect(() => {
        document.title = "Page3 | Travel Demand Digital Survey Tool"
        setPersonStartTime(new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            timeZone: 'IST'
        }).format(Date.now()))
    }, [])
    const rel = ["Self", "Son/Daughter", "Father/Mother", "Wife/Husband/Partner", "Brother/Sister", "Other relative", "Not related"]
    function resetvalues() {
        //we are checking the options marked and acoordingly giving -100 to the questions that are being skipped.
        //based on the occupation selected im maring the questions that are skipped
        if ([1, 2, 3, 4, 5, 10, 11, 12, 13].includes(state.pageThree?.occupation)) {
            handleInputChange('PROFESSION', -100);
            handleInputChange('WORKPLACE_ADDRESS_NAME', -100);
            handleInputChange('WORKPLACE_ADDRESS_LONG', -100);
            handleInputChange('WORKPLACE_ADDRESS_LAT', -100);
            handleInputChange('INCOME', -100);
        }
        else if (state.pageThree.profession === -100) {
            handleInputChange('PROFESSION', -10);
            handleInputChange('WORKPLACE_ADDRESS_NAME', "");
            handleInputChange('WORKPLACE_ADDRESS_LONG', "");
            handleInputChange('WORKPLACE_ADDRESS_LAT', "");
            handleInputChange('INCOME', -10);
        }

        if ([1, 2, 3, 6, 7, 8, 9, 10, 11, 12, 13].includes(state.pageThree?.occupation)) {
            handleInputChange('SCHOOL_ADDRESS_NAME', -100);
            handleInputChange('SCHOOL_ADDRESS_LONG', -100);
            handleInputChange('SCHOOL_ADDRESS_LAT', -100);
        }
        else if (state.pageThree.schoolAddressText === -100) {
            handleInputChange('SCHOOL_ADDRESS_NAME', "");
            handleInputChange('SCHOOL_ADDRESS_LONG', "");
            handleInputChange('SCHOOL_ADDRESS_LAT', "");
        }

        if ([1, 2, 3, 4, 5, 10, 11, 12, 13].includes(state.pageThree?.occupation)) {
            handleInputChange('EMPLOYER_TRAVEL_OPTION', -100);
            handleInputChange('DAYS_WORKED_FROM_HOME', -100);
            handleInputChange('BEFORE_COVID', -100);
        }
        else if (state.pageThree?.employerProvidedTravelOption === -100) {
            handleInputChange('EMPLOYER_TRAVEL_OPTION', -10);
            handleInputChange('DAYS_WORKED_FROM_HOME', -10);
            handleInputChange('BEFORE_COVID', -10);
        }

    };
    useEffect(() => {
        resetvalues();



    }, [state.pageThree.occupation]);

    useEffect(() => {
        if ([1, 2, -100].includes(state.pageThree?.age)) {
            handleInputChange('DRIVING_LICENSE', -100);
        }
        else if (state.pageThree?.drivingLicense === -100) {
            handleInputChange('DRIVING_LICENSE', -10);
        }

    }, [state.pageThree?.age])



    const apiUrl = '/page3';
    const postData = {
        personNo: state.personNo,
        personEnumeratorCode: state.pageZero?.enumeratorCode,
        enumeratorLat: state.pageThree.enumeratorLat,
        enumeratorLong: state.pageThree.enumeratorLong,
        answeredOnBehalf: state.pageThree?.answeredOnBehalf,
        gender: state.pageThree?.gender,
        age: state.pageThree?.age,
        relationWithPerson1: state.pageThree?.relationWithPerson1,
        education: state.pageThree?.education,
        occupation: state.pageThree?.occupation,
        profession: state.pageThree?.profession,
        income: state.pageThree?.income,
        workplaceAddressLat: state.pageThree?.workplaceAddressLat,
        workplaceAddressLong: state.pageThree?.workplaceAddressLong,
        workplaceAddressText: state.pageThree?.workplaceAddressText,
        schoolAddressLat: state.pageThree?.schoolAddressLat,
        schoolAddressLong: state.pageThree?.schoolAddressLong,
        schoolAddressText: state.pageThree?.schoolAddressText,
        employerProvidedTravelOption: state.pageThree?.employerProvidedTravelOption,
        daysWorkedFromHome: state.pageThree?.daysWorkedFromHome,
        daysWorkedFromHomeBeforeCOVID: state.pageThree?.daysWorkedFromHomeBeforeCOVID,
        drivingLicense: state.pageThree?.drivingLicense,
        currentPage: state.pageThree?.currentPage,
        hhRefNo: state.hhRefNo,
        personStartTime: personStartTime,
        personCp1Time: ""
    };


    // const {personNo}=state.pageThree
    // var PersonNumbersCheck=PersonNumberCheck(personNo)
    const handleClickNext = (event) => {
        event.preventDefault();
        // if(!PersonNumbersCheck) alert("Enter a valid number for Person Number")
        postData.personCp1Time = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            timeZone: 'IST'
        }).format(Date.now())
        fetchData(apiUrl, postData)
            .then((responseData) => {
                if (responseData.status == 1) {
                    navigate('/page4');
                }
                else toast.error(responseData.data)
            })
            .catch((error) => {
                console.error(error);
            });


    }

    return (

        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>
                <div className='w-full p-[1%] font-semibold'>{QuestionData.pageThree.title}</div>
                <Geolocation handleInputChange={handleInputChange} questionData={QuestionData.pageThree.enumeratorAddress} />
                <NumInput
                    questionData={QuestionData.pageThree.PersonNumber}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.personNo}
                    min={1}
                    autoFocus={true}
                />
                <RadioInput
                    questionData={QuestionData.pageThree.answeredOnBehalf}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.answeredOnBehalf}
                />
                <RadioInput
                    questionData={QuestionData.pageThree.Gender}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.gender}
                />
                <RadioInput
                    questionData={QuestionData.pageThree.Age}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.age}
                />
                {
                    (state.personNo > 1) && (state.pageThree.relationWithPerson1 > 1) && (
                        <h1 className="font-bold text-lg mx-5 py-0 my-0 relative top-[24px] right-[8px]">Person-{state.personNo} is {rel[state.pageThree.relationWithPerson1 - 1]} of Person-1 </h1>)
                }
                <RadioInput className="py-0 my-0"
                    questionData={QuestionData.pageThree.RelationWithPerson1}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.relationWithPerson1}
                />

                <RadioInput
                    questionData={QuestionData.pageThree.Education}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.education}
                />
                <RadioInput
                    questionData={QuestionData.pageThree.Occupation}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageThree.occupation}
                />
                {/* Here again the questions are being rendered selectively based the option selected for the 
                previous questions , in this case it based on occupation .*/}

                {
                    [6, 7, 8, 9].includes(state.pageThree.occupation) && (<RadioInput
                        questionData={QuestionData.pageThree.Profession}
                        handleInputChange={handleInputChange}
                        required={true}
                        value={state.pageThree.profession}
                    />)
                }
                {
                    [6, 7, 8, 9].includes(state.pageThree.occupation) && (<RadioInput
                        questionData={QuestionData.pageThree.MonthlyIncome}
                        handleInputChange={handleInputChange}
                        required={true}
                        value={state.pageThree.income}
                    />)
                }

                {
                    [6, 7, 8, 9].includes(state.pageThree.occupation) && (<SearchCurrent
                        questionData={QuestionData.pageThree.WorkAddress}
                        handleInputsChange={handleInputChange}
                        required={true}
                        value={[state.pageThree?.workplaceAddressText, state.pageThree?.workplaceAddressLat, state.pageThree?.workplaceAddressLong]}
                    />

                    )
                }

                {
                    [4, 5].includes(state.pageThree.occupation) && (<SearchCurrent
                        questionData={QuestionData.pageThree.SchoolAddress}
                        handleInputsChange={handleInputChange}
                        required={true}
                        value={[state.pageThree?.schoolAddressText, state.pageThree?.schoolAddressLat, state.pageThree?.schoolAddressLong]}

                    />)
                }
                {/* Here again the questions are being rendered selectively based the option selected for the 
                previous questions */}
                {
                    [6, 7, 8, 9].includes(state.pageThree.occupation) && (
                        <div>
                            <RadioInput
                                questionData={QuestionData.pageThree.EmployerProvidedTravelOption}
                                handleInputChange={handleInputChange}
                                required={true}
                                value={state.pageThree.employerProvidedTravelOption}
                            />
                            <RadioInput
                                questionData={QuestionData.pageThree.DaysWorkedFromHome}
                                handleInputChange={handleInputChange}
                                required={true}
                                value={state.pageThree.daysWorkedFromHome}
                            />
                            <RadioInput
                                questionData={QuestionData.pageThree.DaysWorkedFromHomeBeforeCOVID}
                                handleInputChange={handleInputChange}
                                required={true}
                                value={state.pageThree.daysWorkedFromHomeBeforeCOVID}
                            />

                        </div>
                    )
                }
                {/* Here again the questions are being rendered selectively based the option selected for the 
                previous questions */}
                {
                    [3, 4, 5, 6, 7, 8].includes(state.pageThree.age) && (<RadioInput
                        questionData={QuestionData.pageThree.DrivingLicense}
                        handleInputChange={handleInputChange}
                        required={true}
                        value={state.pageThree.drivingLicense}

                    />)
                }


            </div>
            <div className="flex flex-1 justify-between items-center">
                <Link to={'/Summary'} className={`bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8 ${state.isEditing ? 'flex' : 'invisible'} flex-row-reverse justify-between items-center gap-x-3`}>
                    Back to Person Summary Table <FaArrowLeft />
                </Link>
                <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
                    Next Page <FaArrowRight />
                </button>
            </div>
        </form>
    )

}