import React, { useEffect } from "react";
import toast from "react-hot-toast";

// This component takes 2 props
// 1. questionData - this is an object that contains the field
// 2. handleInputChange - this is a function that is used to update the state
const Geolocation = ({ handleInputChange, questionData }) => {
  const { field } = questionData;

  useEffect(() => {
    // Function to retrieve coordinates using geolocation API
    const retrieveCoordinates = () => {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            // This function returns the latitude and longitude as an object
            // using handleInputChange to set the state variables with appropriate values.
            (position) => {
              const { latitude, longitude } = position.coords;
              handleInputChange(field[0], longitude);
              handleInputChange(field[1], latitude);
            },
            (error) => {
              console.error(error);
              toast.error("Failed to retrieve location");
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
      };

      // Checking if the permission is given before, i.e., if it is present in local storage
      // Accordingly, we do or don't show the permission dialog box.
      const hasPermission = localStorage.getItem("geolocationPermission");
      if (!hasPermission) {
        if (window.confirm("Allow this website to access your location?")) {
          localStorage.setItem("geolocationPermission", "granted");
          getLocation();
        } else {
          localStorage.setItem("geolocationPermission", "denied");
          toast.error("Permission denied");
        }
      } else if (hasPermission === "granted") {
        getLocation();
      }
    };

    // Call the function to retrieve coordinates
    retrieveCoordinates();
  }, [handleInputChange]);

  return null;
};

export default Geolocation;
