import React from "react";

const MailInput = ({ questionData, handleInputChange, value, required, width, bgcolor }) => {
  const { question, field } = questionData;

  const handleChange = (e) => {
    e.preventDefault();
    handleInputChange(field, e.target.value);
  };

  return (
    <div className={`p-[2%] hover:bg-main ${bgcolor} rounded-lg w-[${width}] flex-grow`}>
      <div>
        <div className="mb-1 font-bold">{question}</div>
        <input
          type="email"  // Use 'email' type for email input
          className="border-2 border-slate-600 mb-1 p-1 rounded w-full"
          placeholder="Enter your email"
          value={value}
          onChange={handleChange}
          required={required}
        />
      </div>
    </div>
  );
};

export default MailInput;
