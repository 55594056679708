import React, { useContext, useState } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { StateContext } from '../contexts/StateContext'
import Auth from '../Pages/Auth'


const ProtectedRoute = ({children}) => {

    const { state } = useContext(StateContext)
    return state.pageZero.enumeratorCode=="" ? <Auth />:<>{children}</>
    // return <>{children}</>
}

export default ProtectedRoute