import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { StateContextProvider } from './contexts/StateContext';
import Header from './Components/Header';
import RouteList from './Routes/RouteList';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <StateContextProvider>
      <Toaster toastOptions={{
        success: {
          duration: 2000
        }, error: {
          duration: 5000
        }
      }} />
      <Header />
      <div className=" flex flex-col align-center mt-8 m-[5%]">
        <Router>
          <RouteList />
        </Router>
      </div>
    </StateContextProvider>
  );
}

export default App;
