import React, { useEffect, useState } from "react";
import RadioInput from "../Components/RadioInput";
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
import NumInput from "../Components/NumInput";
import { QuestionData } from "../Data/QuestionData";
import Table from '../Components/Table'
import Time from "../Components/Time";
import SearchCurrent from "../Components/SearchCurrent";
import SelectSearchInput from "../Components/SelectSearchInput";
import fetchData from '../contexts/API';
import { Link, useNavigate } from "react-router-dom";
import Geolocation from '../Components/geolocation';
import toast from "react-hot-toast";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function Page5() {
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    var options = QuestionData.pageFive.legTable.legMode.options;

    const [tableData, setTableData] = useState([{}])
    const navigate = useNavigate();
    const { state, handleInputChange, handleLegChange, handleLegDelete, handleLegEdit } = useContext(StateContext);

    useEffect(() => {
        document.title = "Page5 | Travel Demand Digital Survey Tool"
        console.log({ state })
        return () => console.log({ state })
    }, [])

    const handleOpen = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen)
    }
    const handleclose = (e) => {
        e.preventDefault();
        if (isEdit) setIsEdit(!isEdit)
    }

    useEffect(() => {
        if (state.tripNo > 1) {
            handleInputChange('TRIP_ORIGIN_ADDRESS_TEXT', state.tripDest)
            handleInputChange('TRIP_ORIGIN_ADDRESS_LAT', state.tripDestLat)
            handleInputChange('TRIP_ORIGIN_ADDRESS_LONG', state.tripDestLong)
        } else if (state.tripNo === 1) {
            handleInputChange('TRIP_ORIGIN_ADDRESS_TEXT', state.pageOne.houseAddresstext);
            handleInputChange('TRIP_ORIGIN_ADDRESS_LAT', state.pageOne.houseAddressLat);
            handleInputChange('TRIP_ORIGIN_ADDRESS_LONG', state.pageOne.houseAddressLong);
        }
    }, [state.tripNo])
    useEffect(() => {
        if (state.pageFive.tripOriginLat !== "" || state.pageFive.tripOriginLong !== "") {
            if (state.pageFive.tripDestLat === state.pageFive.tripOriginLat || state.pageFive.tripDestLong === state.pageFive.tripOriginLong) {
                toast.error("Trip Origin and Trip Destination Can not be equal!!")
            }
        }
    }, [state.pageFive.tripDestLat, state.pageFive.tripDestLong])

    const prevdest = () => {
        handleInputChange('TRIP_DEST', state.pageFive.tripDestAddress)
        handleInputChange('TRIP_DEST_LAT', state.pageFive.tripDestLat)
        handleInputChange('TRIP_DEST_LONG', state.pageFive.tripDestLong)
    }

    useEffect(() => {
        if ([1, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, -100].includes(state.pageFive.tripMainMode)) {
            handleInputChange('RIDER_TYPE', -100);
            handleInputChange('TRIP_PARKING', -100);
        }
        else if (state.pageFive.riderType === -100) {
            handleInputChange('RIDER_TYPE', -10);
            handleInputChange('TRIP_PARKING', -10);
        }
        if ([1, 2, 3, 4, 5, 6, 10, 12, 13, 14, 15].includes(state.pageFive.tripMainMode)) {
            handleInputChange('TRIP_FARE', -100);
        }
        else if (state.pageFive.tripFare === -100) {
            handleInputChange('TRIP_FARE', 0);
        }

    }, [state.pageFive.tripMainMode]);

    useEffect(() => {
        if (state.pageFive.tripMainPurpose === 2) {
            handleInputChange('TRIP_DEST_TIME_SPENT', -100)
        }
        else if (state.pageFive.tripDesttimeSpent === -100) {
            handleInputChange('TRIP_DEST_TIME_SPENT', 0)
        }
    }, [state.pageFive.tripMainPurpose])

    useEffect(() => {
        if ([2, -100].includes(state.pageFive.tripParking)) {
            handleInputChange('TRIP_PARKING_COST', -100);
        }
        else if (state.pageFive.tripParkingCost === -100) {
            handleInputChange('TRIP_PARKING_COST', 0);
        }
    }, [state.pageFive.tripParking]);

    useEffect(() => {
        if ([2, -100].includes(state.pageFive.tripStopsDone)) {
            handleInputChange('TRIP_STOP_COUNT', -100);
        }
        else if (state.pageFive.tripStopCount === -100) {
            handleInputChange('TRIP_STOP_COUNT', 0);
        }
    }, [state.pageFive.tripStopsDone]);

    useEffect(() => {
        if ([2, -100].includes(state.pageFive.nextTripNonTransitModeUsage)) {
            handleInputChange('NEXT_NON_TRANSIT_MODE', -100);
        }
        else if (state.pageFive.nextTripNonTransitMode === -100) {
            handleInputChange('NEXT_NON_TRANSIT_MODE', -10);
        }
    }, [state.pageFive.nextTripNonTransitModeUsage]);

    useEffect(() => {
        if ([3, 4, 13, -100].includes(state.pageFive.tripMainMode)) {
            handleInputChange('NEXT_NON_TRANSIT', -100);
            handleInputChange('NEXT_NON_TRANSIT_MODE', -100);
        }
        else if (state.pageFive.nextTripNonTransitModeUsage === -100) {
            handleInputChange('NEXT_NON_TRANSIT', -10);
            handleInputChange('NEXT_NON_TRANSIT_MODE', -10);
        }
    }, [state.pageFive.tripStopsDone]);



    useEffect(() => {
        // Get the selected time values from the input elements
        const startTimeString = state.pageFive.tripStartTime;
        const endTimeString = state.pageFive.tripEndtime;

        // Convert the time values to JavaScript Date objects with a common date (e.g., today's date)
        const startDate = new Date(`1970-01-01T${startTimeString}`);
        const endDate = new Date(`1970-01-01T${endTimeString}`);

        // Calculate the time difference in milliseconds
        const timeDifferenceInMilliseconds = endDate - startDate;

        // Calculate the time difference in minutes
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));

        handleInputChange('TRIP_TOTAL_TIME', timeDifferenceInMinutes);
    }, [state.pageFive.tripStartTime, state.pageFive.tripEndtime]);



    const handleDeleteLeg = (index, e) => {
        e.preventDefault();
        handleLegDelete("LEG", index)
        toast.success("Leg deleted!")
    }
    const handleFinalEdit = (e) => {
        e.preventDefault();
        var legs = state.pageFive.legs;
        var index = state.pageFive.index;
        handleLegEdit(index, {
            "legMode": state.pageFive.legMode,
            "legtt": state.pageFive.legTt,
            "legCost": state.pageFive.legCost,
        });
        handleInputChange('LEG_MODE', -10);
        handleInputChange('LEG_TRAVEL_TIME', 0);
        handleInputChange('LEG_COST', 0);
        setIsEdit(!isEdit)
    }

    const handleEditLeg = (index, e) => {
        e.preventDefault();
        var legs = state.pageFive.legs;
        handleInputChange('LEG_MODE', legs[index]["legMode"]);
        handleInputChange('LEG_TRAVEL_TIME', legs[index]["legtt"]);
        handleInputChange('LEG_COST', legs[index]["legCost"]);
        handleInputChange('INDEX', index);
        setIsEdit(!isEdit)

    }

    const handleAddLeg = (e) => {
        e.preventDefault();
        const data = { "legNo": state.pageFive.legNo, "legMode": state.pageFive.legMode, "legtt": state.pageFive.legTt, "legCost": state.pageFive.legCost };
        handleLegChange("LEGS", data);
        handleInputChange('LEG_MODE', 0);
        handleInputChange('LEG_TRAVEL_TIME', 0);
        handleInputChange('LEG_COST', 0);
        setIsOpen(!isOpen)
    }

    const handleHomeAdd = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_ORIGIN_ADDRESS_TEXT', state.pageOne.houseAddresstext);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LAT', state.pageOne.houseAddressLat);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LONG', state.pageOne.houseAddressLong);
    }

    const handleWorkAdd = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_ORIGIN_ADDRESS_TEXT', state.pageThree.workplaceAddressText);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LAT', state.pageThree.workplaceAddressLat);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LONG', state.pageThree.workplaceAddressLong);
    }

    const handleSchoolAdd = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_ORIGIN_ADDRESS_TEXT', state.pageThree.schoolAddressText);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LAT', state.pageThree.schoolAddressLat);
        handleInputChange('TRIP_ORIGIN_ADDRESS_LONG', state.pageThree.schoolAddressLong);
    }

    //for destination
    const handleHomeAdddes = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_DESTINATION_ADDRESS_TEXT', state.pageOne.houseAddresstext);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LAT', state.pageOne.houseAddressLat);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LONG', state.pageOne.houseAddressLong);
        handleInputChange('TRIP_MAIN_PURPOSE', 2);
    }

    const handleWorkAdddes = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_DESTINATION_ADDRESS_TEXT', state.pageThree.workplaceAddressText);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LAT', state.pageThree.workplaceAddressLat);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LONG', state.pageThree.workplaceAddressLong);
        handleInputChange('TRIP_MAIN_PURPOSE', 3);
    }

    const handleSchoolAdddes = (e) => {
        e.preventDefault();
        handleInputChange('TRIP_DESTINATION_ADDRESS_TEXT', state.pageThree.schoolAddressText);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LAT', state.pageThree.schoolAddressLat);
        handleInputChange('TRIP_DESTINATION_ADDRESS_LONG', state.pageThree.schoolAddressLong);
        handleInputChange('TRIP_MAIN_PURPOSE', 4);
    }


    useEffect(() => {
        var legs = state.pageFive.legs;

        if (legs.length > 0) {
            var modified_list = legs?.map(obj => ({ ...obj, legMode: options[obj.legMode - 1] }));
        }
        else {
            var modified_list = legs
        }
        setTableData(modified_list);
    }, [state.pageFive.legs])







    const apiUrl = '/page5';

    var timeSpent = parseInt(window.performance.now() / 1000)
    const handleClickNext = (event) => {
        if (state.pageFive.tripotherPurpose === 15) {
            handleInputChange("TRIP_OTHER_PURPOSE", -100)
        }
        const postData = {
            hhRefNo: state.hhRefNo,
            personNo: state.personNo,
            tripNo: state.tripNo,
            enumeratorLat: state.pageFive.enumeratorLat,
            enumeratorLong: state.pageFive.enumeratorLong,
            tripOriginAddress: state.pageFive.tripOriginAddress,
            tripOriginLat: state.pageFive.tripOriginLat,
            tripOriginLong: state.pageFive.tripOriginLong,
            tripStartTime: state.pageFive.tripStartTime,
            tripDestAddress: state.pageFive.tripDestAddress,
            tripDestLat: state.pageFive.tripDestLat,
            tripDestLong: state.pageFive.tripDestLong,
            tripMainPurpose: state.pageFive.tripMainPurpose,
            tripotherPurpose: state.pageFive.tripotherPurpose,
            tripEndtime: state.pageFive.tripEndtime,
            tripTotaltime: state.pageFive.tripTotaltime,
            tripDesttimeSpent: state.pageFive.tripDesttimeSpent,
            tripMainMode: state.pageFive.tripMainMode,
            riderType: state.pageFive.riderType,
            tripFare: state.pageFive.tripFare,
            tripParking: state.pageFive.tripParking,
            tripParkingCost: state.pageFive.tripParkingCost,
            tripFamilyMbrCount: state.pageFive.tripFamilyMbrCount,
            tripNonFamilyMbrCount: state.pageFive.tripNonFamilyMbrCount,
            tripStopsDone: state.pageFive.tripStopsDone,
            tripStopCount: state.pageFive.tripStopCount,
            nextTripNonTransitModeUsage: state.pageFive.nextTripNonTransitModeUsage,
            nextTripNonTransitMode: state.pageFive.nextTripNonTransitMode,
            legs: state.pageFive.legs ? state.pageFive.legs?.map(obj => ({ ...obj, legMode: options[obj.legMode - 1] })) : [{}],
            currentPage: state.pageFive.currentPage
        };
        prevdest();

        event.preventDefault();

        handleInputChange('TIME_SPENT_IN_TI', timeSpent);
        if (state.pageFive.tripDestLat === state.pageFive.tripOriginLat || state.pageFive.tripDestLong === state.pageFive.tripOriginLong) {
            toast.error("Trip Origin and Trip Destination Can not be equal!!")
        } else {
            fetchData(apiUrl, postData)
                .then((responseData) => {
                    if (responseData.status === 1) {

                        navigate('/page6');
                    }
                    else toast.error(responseData.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        }       
    }

    return (

        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>

                <div className='w-full p-[1%] font-semibold'>{QuestionData.pageFive.title}</div>
                <Geolocation handleInputChange={handleInputChange} questionData={QuestionData.pageFive.enumeratorAddress} />
                <NumInput
                    questionData={QuestionData.pageFive.tripNo}
                    handleInputChange={handleInputChange}
                    value={state.tripNo}
                    required={true}
                    autoFocus={true}
                    min={1}
                />

                <SearchCurrent questionData={QuestionData.pageFive.tripOriginAddress} handleInputsChange={handleInputChange} required={true} value={[state.pageFive.tripOriginAddress, state.pageFive.tripOriginLat, state.pageFive.tripOriginLong]} />
                <div className="flex flex-wrap flex-col sm:flex-row justify-center">
                    {state.pageThree.workplaceAddressText !== "" && state.pageThree.workplaceAddressText !== -100 && !state.isEditing && (
                        <button onClick={handleWorkAdd} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            Workplace Address
                        </button>
                    )}
                    {state.pageThree.schoolAddressText !== "" && state.pageThree.schoolAddressText !== -100 && !state.isEditing && (
                        <button onClick={handleSchoolAdd} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            School/College Address
                        </button>
                    )}
                    {state.pageOne.houseAddresstext !== "" && state.pageOne.houseAddresstext !== -100 && !state.isEditing && (
                        <button onClick={handleHomeAdd} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            House Address
                        </button>
                    )}
                </div>

                <Time
                    questionData={QuestionData.pageFive.tripStarttime}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripStartTime}
                    required={true}
                />
                <SearchCurrent questionData={QuestionData.pageFive.tripDestinationAddress} value={[state.pageFive.tripDestAddress, state.pageFive.tripDestLat, state.pageFive.tripDestLong]} required={true} handleInputsChange={handleInputChange} />
                <div className="flex flex-wrap flex-col sm:flex-row justify-center">
                    {state.pageThree.workplaceAddressText !== "" && state.pageThree.workplaceAddressText !== -100 && !state.isEditing && (
                        <button onClick={handleWorkAdddes} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            Workplace Address
                        </button>
                    )}
                    {state.pageThree.schoolAddressText !== "" && state.pageThree.schoolAddressText !== -100 && !state.isEditing && (
                        <button onClick={handleSchoolAdddes} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            School/College Address
                        </button>
                    )}
                    {state.pageOne.houseAddresstext !== "" && state.pageOne.houseAddresstext !== -100 && !state.isEditing && (
                        <button onClick={handleHomeAdddes} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded-lg items-center">
                            House Address
                        </button>
                    )}
                </div>
                <SelectSearchInput
                    questionData={QuestionData.pageFive.tripMainPurpose}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageFive.tripMainPurpose}
                />
                <SelectSearchInput
                    questionData={QuestionData.pageFive.tripotherPurpose}
                    handleInputChange={handleInputChange}
                    required={false}
                    value={state.pageFive.tripotherPurpose}
                />
                <Time
                    questionData={QuestionData.pageFive.tripEndtime}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripEndtime}
                    required={true}
                />
                <NumInput
                    questionData={QuestionData.pageFive.tripTotaltime}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripTotaltime}
                    required={true}
                    disabled={true}
                />
                {(state.pageFive.tripMainPurpose != 2 && state.pageFive.tripMainPurpose != -100) && (
                    <div>
                        <NumInput
                            questionData={QuestionData.pageFive.tripDesttimeSpent}
                            handleInputChange={handleInputChange}
                            value={state.pageFive.tripDesttimeSpent}
                            required={true}
                            min={1}
                        />
                    </div>
                )}
                <SelectSearchInput
                    questionData={QuestionData.pageFive.tripMainMode}
                    handleInputChange={handleInputChange}
                    required={true}
                    value={state.pageFive.tripMainMode}
                />
                {[2, 5, 6].includes(state.pageFive.tripMainMode) && (
                    <div>
                        <RadioInput
                            questionData={QuestionData.pageFive.riderType}
                            handleInputChange={handleInputChange}
                            value={state.pageFive.riderType}
                            required={true}
                        />
                        <RadioInput
                            questionData={QuestionData.pageFive.tripParking}
                            handleInputChange={handleInputChange}
                            value={state.pageFive.tripParking}
                            required={true}
                        />
                        {[1].includes(state.pageFive.tripParking) && (
                            <div>
                                <NumInput
                                    questionData={QuestionData.pageFive.tripParkingCost}
                                    handleInputChange={handleInputChange}
                                    value={state.pageFive.tripParkingCost}
                                    required={true}
                                />
                            </div>
                        )}
                    </div>

                )}
                {/* For Main mode -  */}
                {[7, 8, 9, 11, 16].includes(state.pageFive.tripMainMode) && (
                    <NumInput
                        questionData={QuestionData.pageFive.tripFare}
                        handleInputChange={handleInputChange}
                        value={state.pageFive.tripFare}
                        required={true}
                    />
                )}
                {[3, 4, 13].includes(state.pageFive.tripMainMode) && (
                    <div>

                        {/* LEG TABLE */}
                        <div className='flex flex-col w-full mt-4'>
                            <div className='text-2xl font-bold text-center'>Leg Info Table</div>
                            <div className='flex justify-start'>
                                <button onClick={handleOpen} className='w-max right-0 bg-other hover:bg-slate-900 text-white py-2 px-4  mx-4 my-2 mr-2 rounded'>
                                    Add New Leg
                                </button>
                            </div>



                            <Table
                                heading={QuestionData.pageFive.legTable.head}
                                entry={tableData}
                                actions={[["Delete", handleDeleteLeg], ["Edit", handleEditLeg]]}
                            />

                            {/* Add New Leg Summary Dialog box*/}

                            <dialog open={isOpen} className='rounded-lg border-2 border-black w-[70%] animate-fade-in-up' >
                                <div className='flex justify-end bg-slate-300 font-semibold w-max rounded-lg p-2 select-none'>Leg No. {state.pageFive.legs?.length + 1}</div>

                                <div className='flex flex-wrap w-full p-2 items-center'>
                                    <SelectSearchInput
                                        questionData={QuestionData.pageFive.legTable.legMode}
                                        required={true}
                                        value={state.pageFive.legMode}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />
                                    <NumInput
                                        bgcolor={"bg-white"}
                                        questionData={QuestionData.pageFive.legTable.legTt}
                                        required={true}
                                        value={state.pageFive.legTt}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />
                                    <NumInput
                                        bgcolor={"bg-white"}
                                        questionData={QuestionData.pageFive.legTable.legCost}
                                        required={true}
                                        value={state.pageFive.legCost}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />

                                </div>
                                <div className='flex justify-between items-center mx-6'>
                                    <button onClick={handleAddLeg} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                                        Add Leg
                                    </button>
                                    <button onClick={handleOpen} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            </dialog>
                            <dialog open={isEdit} className='rounded-lg border-2 border-black w-[70%] animate-fade-in-up' >
                                <div className='flex justify-end bg-slate-300 font-semibold w-max rounded-lg p-2 select-none'></div>

                                <div className='flex flex-wrap w-full p-2 items-center'>
                                    <SelectSearchInput
                                        questionData={QuestionData.pageFive.legTable.legMode}
                                        required={true}
                                        value={state.pageFive.legMode}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />
                                    <NumInput
                                        bgcolor={"bg-white"}
                                        questionData={QuestionData.pageFive.legTable.legTt}
                                        required={true}
                                        value={state.pageFive.legTt}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />
                                    <NumInput
                                        bgcolor={"bg-white"}
                                        questionData={QuestionData.pageFive.legTable.legCost}
                                        required={true}
                                        value={state.pageFive.legCost}
                                        handleInputChange={handleInputChange}
                                        width={"50%"}
                                    />

                                </div>
                                <div className='flex justify-between items-center mx-6'>
                                    <button onClick={handleFinalEdit} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                                        Edit
                                    </button>
                                    <button onClick={handleclose} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            </dialog>
                        </div>
                        <br />
                        <br />
                        <br />

                    </div>

                )}

                <div className="font-bold px-[1%]">{QuestionData.pageFive.familyCount}</div>
                <NumInput
                    questionData={QuestionData.pageFive.tripFamilyMemberCount}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripFamilyMbrCount}
                    required={true}
                />
                <NumInput
                    questionData={QuestionData.pageFive.tripNonFamilyMemberCount}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripNonFamilyMbrCount}
                    required={true}
                />
                <RadioInput
                    questionData={QuestionData.pageFive.tripStopsDone}
                    handleInputChange={handleInputChange}
                    value={state.pageFive.tripStopsDone}
                    required={true}
                />
                {[1].includes(state.pageFive.tripStopsDone) && (
                    <div>
                        <NumInput
                            questionData={QuestionData.pageFive.tripStopCount}
                            handleInputChange={handleInputChange}
                            value={state.pageFive.tripStopCount}
                            required={true}
                        />
                    </div>)
                }

                {[1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16].includes(state.pageFive.tripMainMode) && (
                    <div>
                        <RadioInput
                            questionData={QuestionData.pageFive.nextTripNonTransit}
                            handleInputChange={handleInputChange}
                            value={state.pageFive.nextTripNonTransitModeUsage}
                            required={true}
                        />
                        {[1].includes(state.pageFive.nextTripNonTransitModeUsage) && (
                            <div>
                                <SelectSearchInput
                                    questionData={QuestionData.pageFive.nextTripNonTransitMode}
                                    handleInputChange={handleInputChange}
                                    required={true}
                                    value={state.pageFive.nextTripNonTransitMode}
                                />
                            </div>)
                        }
                    </div>)}
            </div>



            {/* <button type='submit' className="bg-other hover:bg-slate-900 text-white  py-2 px-2 rounded my-8">
                Next Page
            </button> */}
            <div className="flex flex-1 justify-between items-center">
                <Link to={'/TripSummary'} className={`bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8 ${state.isEditing ? 'flex' : 'invisible'} flex-row-reverse justify-between items-center gap-x-3`}>
                    Back to Trip Summary Table <FaArrowLeft />
                </Link>
                <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
                    Next Page <FaArrowRight />
                </button>
            </div>
        </form>
    )

}

