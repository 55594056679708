import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { QuestionData } from '../Data/QuestionData'
import TextInput from '../Components/TextInput';
import { StateContext } from '../contexts/StateContext';
import toast from 'react-hot-toast'

const Home = () => {

    const { state, handleInputChange } = useContext(StateContext);

    let navigate = useNavigate()
    useEffect(() => {
      document.title = "Home | Travel Demand Digital Survey Tool"
    }, [])
    
    const handleNewSurvey=()=>{
        navigate('/page0')
        toast.success("New survey started")
    }
    
    const handleExistingSurvey=()=>{
        setExisting(!existing)
    }

    
    const [existing, setExisting] = useState(false)

    return (
        <div className='flex w-full justify-evenly align-center bg-white rounded-lg mt-4'>
            <button onClick={handleNewSurvey} className="bg-other h-max hover:bg-slate-900 text-white  py-2 px-4 rounded my-8">
                Start New Survey
            </button>
            <div>
                <button onClick={handleExistingSurvey} className="bg-other hover:bg-slate-900 text-white py-2 px-4 rounded my-8">
                    Continue Existing Survey
                </button>
                {
                    existing&&
                    <div className='flex flex-col'>
                        <TextInput
                            questionData={QuestionData.home.hhRefNo}
                            handleInputChange={handleInputChange}
                            value={state.hhRefNo}
                            width={'50%'}
                        />
                        <button onClick={()=>navigate('/summary')} className="bg-other h-max hover:bg-slate-900 text-white rounded p-1 my-4">
                            Start
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Home