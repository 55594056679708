import React, { useContext } from 'react';
import images from '../assets/images';
import Title from './Title';
import { StateContext } from '../contexts/StateContext';
import Login from './Login';
//imported the logo from assets and stroing it in a array for future use 
const { bosch, iisc, dult } = images;

const Header = () => {

  const { state } = useContext(StateContext);

  return (
    <nav className='flex flex-col'>
      {/* Adding flex to all the title and the logos to make them come  in the same row . the flex helps them shrink and grow based on the sze of the screen*/}
      {/* Added a 10 10 60 20 split to the elements within the div. Added links to the logos in order to navigate to the respective websites */}
      {/* Custom Tailwind colour other has been and colour has been defined in tailwind.config.js  */}

      <div className="border-b-4 border-other select-none flex justify-between items-center w-full px-[5%]">
        {/* First logo */}
        <a className='flex justify-between items-center' style={{ width: '10%' }} href="https://iisc.ac.in" target="_blank" rel="noopener noreferrer">
          <img
            src={iisc}
            alt="Logo 1"
            style={{ height: '100%', width: '100%' }}
          />
        </a>

        {/* Second logo */}
        <a className='flex justify-between items-center' style={{ width: '10%' }} href="https://dult.karnataka.gov.in" target="_blank" rel="noopener noreferrer">
          <img
            src={dult}
            alt="Logo 2"
            style={{ height: '100%', width: '100%' }}
          />
        </a>

        {/* Survey Title */}
        <h1 className='w-full text-center font-semibold my-4 flex-shrink flex-grow' style={{ width: '60%', fontSize: '3vw' }}>
          <Title />
        </h1>

        {/* Last logo */}
        <a className='flex justify-between items-center' style={{ width: '20%' }} href="https://boschbrandstore.com" target="_blank" rel="noopener noreferrer">
          <img
            src={bosch}
            alt="Logo 3"
            style={{ height: '100%', width: '100%' }}
          />
        </a>

      </div>

      {/* Show the household reference number */}

      {state.pageZero.enumeratorCode &&
        <div className='flex justify-between items-center bg-slate-300'>
          <h1 className='text-center font-semibold my-4 align-end mx-4' style={{ fontSize: '1.5vw' }}>
            Enumerator Code: <span className='text-other'>{state.pageZero.enumeratorCode}</span>
          </h1>
          <Login buttonText={"Login as different Enumerator"} />
          <h1 className='text-center font-semibold my-4 align-end mx-4' style={{ fontSize: '1.5vw' }}>
            HH Reference Number: <span className='text-other'>{state.hhRefNo}</span>
          </h1>
        </div>
      }
    </nav>
  );
};

export default Header;
