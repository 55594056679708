import React, { useContext, useEffect, useState } from 'react';
import RadioInput from '../Components/RadioInput';
// import TextInput from '../Components/TextInput';
// import SelectSearchInput from '../Components/SelectSearchInput';
import { StateContext } from '../contexts/StateContext';
// import { Metro } from '../Components/MetroStationList';
import { QuestionData } from '../Data/QuestionData';
import { useNavigate } from 'react-router-dom';
// import Search from '../Components/Search';
import NumInput from '../Components/NumInput';
import PhoneInput from '../Components/PhoneInput';
// import API from '../Reducers/API'
import { verifyGenderCount, verifyProfessionCount } from '../Utils/SurveyChecks';
import SearchCurrent from '../Components/SearchCurrent';
import fetchData from '../contexts/API';
import Geolocation from '../Components/geolocation';
import toast from 'react-hot-toast';
import { FaArrowRight } from "react-icons/fa";
export default function Page1() {

    let navigate = useNavigate();
    const { state, handleInputChange } = useContext(StateContext);
    const [surveyStartTime, setSurveyStartTime] = useState(null)
    useEffect(() => {
        document.title = "Page1 | Travel Demand Digital Survey Tool"
        setSurveyStartTime(new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            timeZone: 'IST'
        }).format(Date.now()))
    }, [])

    const apiUrl = '/page1';
    const postData = {
        hhRefNo: state.hhRefNo,
        enumeratorCode: state.pageZero?.enumeratorCode,
        enumeratorLat: state.pageOne?.enumeratorLat,
        enumeratorLong: state.pageOne?.enumeratorLong,
        houseAddressLat: state.pageOne?.houseAddressLat,
        houseAddressLong: state.pageOne?.houseAddressLong,
        houseAddresstext: state.pageOne?.houseAddresstext,
        mobileNumber: state.pageOne?.phoneNumber,
        landlineNumber: state.pageOne?.landlineNumber,
        houseType: state.pageOne?.houseType,
        houseOwnership: state.pageOne?.houseOwnership,
        houseSize: state.pageOne?.houseSize,
        houseMRV: state.pageOne?.houseMRV,
        totalNoOfPersons: state.pageOne?.totalNoOfPersons,
        totalNoMale: state.pageOne?.totalNoMale,
        totalNoFemale: state.pageOne?.totalNoFemale,
        otherGenderCount: state.pageOne?.otherGenderCount,
        kidsBelowFive: state.pageOne?.kidsBelowFive,
        schoolStudent: state.pageOne?.schoolStudent,
        collegeStudent: state.pageOne?.collegeStudent,
        adultEmployees: state.pageOne?.adultEmployees,
        adultRetired: state.pageOne?.adultRetired,
        adultUnemployed: state.pageOne?.adultUnemployed,
        speciallyAbled: state.pageOne?.speciallyAbled,
        currentPage: state.pageOne?.currentPage,
        surveyStartTime: surveyStartTime,
        hhCp1Time: ""
    };


    const {
        totalNoOfPersons,
        totalNoMale,
        totalNoFemale,
        otherGenderCount,
        kidsBelowFive,
        schoolStudent,
        collegeStudent,
        adultEmployees,
        adultRetired,
        adultUnemployed,
        speciallyAbled
    } = state.pageOne

    //calling the verification function from utils/surveyChecks.js 
    //these variables hold the result of the verification function
    //if both are true only then the next button will work

    var genderCountCheck = verifyGenderCount(parseInt(totalNoMale), parseInt(totalNoFemale), parseInt(otherGenderCount), parseInt(totalNoOfPersons))
    var proffesionCountCheck = verifyProfessionCount(parseInt(kidsBelowFive), parseInt(schoolStudent), parseInt(collegeStudent), parseInt(adultEmployees), parseInt(adultRetired), parseInt(adultUnemployed), parseInt(speciallyAbled), parseInt(totalNoOfPersons))

    const handleClickNext = (event) => {
        event.preventDefault();

        if (!genderCountCheck) toast.error("Invalid entry sum of all Gender count should be equal to Total no of people in home")
        else if (!proffesionCountCheck) toast.error("Invalid entry sum of all Profession count should be less than or equal to Total no of people in home")
        else {
            postData.hhCp1Time = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
                timeZone: 'IST'
            }).format(Date.now())
            fetchData(apiUrl, postData)
                .then((responseData) => {
                    if (responseData.status == 1) {
                        navigate('/page2');
                    }
                    else toast.error(responseData.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }



    return (
        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>
                <Geolocation handleInputChange={handleInputChange} questionData={QuestionData.pageOne.enumeratorAddress} />
                <SearchCurrent questionData={QuestionData.pageOne.Address} handleInputsChange={handleInputChange} required={true} value={[state.pageOne.houseAddresstext, state.pageOne?.houseAddressLat, state.pageOne?.houseAddressLong]} />
                <div className='flex flex-row bg-slate-200'>
                    <PhoneInput
                        questionData={QuestionData.pageOne.mobileNumber}
                        handleInputChange={handleInputChange}
                        value={state.pageOne.phoneNumber}
                        required={false}
                        width={"50%"}
                        autoFocus={true}
                        title={"Phone number should be of 10 Digits"}
                    />
                    <PhoneInput
                        questionData={QuestionData.pageOne.landlineNumber}
                        handleInputChange={handleInputChange}
                        value={state.pageOne.landlineNumber}
                        required={false}
                        width={"50%"}
                        title={"The landline number should be of 11 digits starting with '080'"}
                    />
                </div>
                <RadioInput bgcolor={"bg-white"}
                    questionData={QuestionData.pageOne.houseType}
                    handleInputChange={handleInputChange}
                    value={state.pageOne.houseType}
                    required={true}
                />
                <RadioInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageOne.houseSize}
                    handleInputChange={handleInputChange}
                    value={state.pageOne.houseSize}
                    required={true}
                />
                <RadioInput bgcolor={"bg-white"}
                    questionData={QuestionData.pageOne.houseOwnership}
                    handleInputChange={handleInputChange}
                    value={state.pageOne.houseOwnership}
                    required={true}
                />
                <NumInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageOne.houseMRV}
                    handleInputChange={handleInputChange}
                    value={state.pageOne?.houseMRV}
                    required={true}
                    width={"33%"}
                />
                {/* </div> */}

                {/* <div className='flex flex-row flex-wrap w-full justify-start items-center bg-white rounded-lg mt-4'>     */}
                <div className='flex flex-row flex-wrap w-full justify-start items-center'>
                    <NumInput
                        message='Total Person should greater than 0'
                        questionData={QuestionData.pageOne.totalNoOfPersons}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.totalNoOfPersons}
                        width={"100%"}
                        min={1}
                    />
                </div>
                <div className='flex flex-row flex-wrap w-full justify-start items-center'>
                    <NumInput
                        questionData={QuestionData.pageOne.totalNoMale}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.totalNoMale}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.totalNoFemale}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.totalNoFemale}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.otherGenderCount}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.otherGenderCount}
                        width={"33%"}
                    />
                </div>
                <div className='flex flex-row flex-wrap w-full justify-start bg-white'>
                    <NumInput
                        questionData={QuestionData.pageOne.kidsBelowFive}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.kidsBelowFive}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.schoolStudent}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.schoolStudent}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.collegeStudent}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.collegeStudent}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.adultEmployees}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.adultEmployees}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.adultRetired}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.adultRetired}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.adultUnemployed}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.adultUnemployed}
                        width={"20%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageOne.speciallyAbled}
                        handleInputChange={handleInputChange}
                        value={state.pageOne?.speciallyAbled}
                        width={"100%"}
                    />
                </div>
            </div>
            {/* </div> */}
            <div className="flex flex-row-reverse flex-1 justify-between items-center">
                <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
                    Next Page <FaArrowRight />
                </button>
            </div>
        </form>
    )
}
