import React, { useEffect, useState, useContext } from 'react'
import Table from '../Components/Table'
import { QuestionData } from '../Data/QuestionData'
// import TextInput from '../Components/TextInput'
import NumInput from '../Components/NumInput'
import SelectSearchInput from '../Components/SelectSearchInput'
import Datepicker from '../Components/Datepicker'
import { useNavigate } from 'react-router-dom'
import fetchData from '../contexts/API'
import { StateContext } from '../contexts/StateContext'
import ScratchCard from 'react-scratchcard-v2';
import images from '../assets/images';
import toast from 'react-hot-toast'
import { timeClockClasses } from '@mui/x-date-pickers'


const Summary = () => {

    const { scratch } = images;

    const { state, handleInputChange, flushPersonSummary, handleIsEditing, handlePersonInfoEdit, flushPersonInfo } = useContext(StateContext);
    const [addPersonSumaryStartTime, setAddPersonSumaryStartTime] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [tableData, setTableData] = useState([{}])

    const navigate = useNavigate()
    useEffect(() => {
        document.title = "Summary | Travel Demand Digital Survey Tool"
        handleIsEditing(false)
    }, [])
    useEffect(() => {
        if (isOpen) {
            setAddPersonSumaryStartTime(new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
                timeZone: 'IST'
            }).format(Date.now()))
        }
    }, [isOpen])

    const handleViewTripList = (index) => {
        //fetching data from trip info table
        handleInputChange("CURRENT_PERSON_NO", index + 1)
        handleInputChange("PERSON_NO", index + 1)
        navigate('/TripSummary')
    }

    const handleEdit = (index) => {
        //fetch data from person info table
        handleIsEditing(true)
        fetchData('/getPersonInfo', {
            hh_ref_no: state.hhRefNo,
            person_no: index + 1,
            person_enumerator_code: state.pageZero.enumeratorCode
        }).then((responseData) => {
            handlePersonInfoEdit(responseData.data)
            handleInputChange("PERSON_NO", index + 1)
            navigate('/page3')
            toast.success(`Edit Person ${index + 1} !`)
        }).catch((error) => {
            toast.error(error)
        })
    }




    const setTableDataApiUrl = '/summary';

    const handleOpen = () => {
        if (!isOpen) {
            flushPersonSummary()
        }
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        fetchData(setTableDataApiUrl, { hhRefNo: state.hhRefNo })
            .then((responseData) => {
                setTableData(responseData.data)
                handleInputChange("PERSON_NO", responseData.data.length)
            })
    }, [state.personNo])


    const addPersonSummaryApiUrl = '/addPersonSummary';

    const handleAddPersonSummary = () => {
        fetchData(addPersonSummaryApiUrl, {
            personNo: tableData.length + 1,
            enumeratorCode: state.pageZero.enumeratorCode,
            hhRefNo: state.hhRefNo,
            gender: state.summary.genderSummary,
            age: state.summary.ageSummary,
            occupation: state.summary.occupationSummary,
            tripMadeLWWD: state.summary.tripMadeLWWDSummary,
            noOfTrips: state.summary.noOfTripsSummary,
            tripDate: state.summary.tripDateSummary,
            personSummaryStartTime: addPersonSumaryStartTime
        })
            .then(() => handleInputChange("PERSON_NO", tableData.length + 1))
        setIsOpen(!isOpen)
    }


    return (
        <div className='flex flex-col w-full mt-4'>
            <div className='text-3xl font-bold mb-10 text-center'>Person Info Table</div>
            <div className='flex justify-between'>
                <button
                    onClick={handleOpen}
                    className='w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-2 rounded'
                >
                    Add New Person Summary
                </button>

                <button
                    onClick={() => {
                        handleInputChange("PERSON_NO", state.personNo + 1)
                        flushPersonInfo()
                        navigate('/page3')
                    }}
                    className='w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-2 rounded'
                >
                    Add New Person Deails
                </button>

                <button
                    onClick={() => navigate('/')}
                    className='w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-2 rounded'
                >
                    Query New House Hold
                </button>
            </div>

            {/* Person Summary Table */}

            <Table
                heading={QuestionData.summary.head}
                entry={tableData}
                actions={[["View Trip List", handleViewTripList], ["Edit", handleEdit]]}
            />

            <button onClick={() => navigate('/thankyou')} className='w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-2 mr-2 rounded'>
                Submit Survey and View Rewards
            </button>


            {/****************************** Add New Person Summary Dialog box******************************/}

            <dialog open={isOpen} className='rounded-lg border-2 border-black w-[70%] animate-fade-in-up' >
                <div className='flex justify-end bg-slate-300 font-semibold w-max rounded-lg p-2 select-none'>
                    Person No. {state.personNo + 1}
                </div>
                <h1 className='font-bold'> Please verify if the newly added trip date for this Person Summary is same as the date for last working weekday communicated.</h1>

                <div className='flex flex-wrap w-full p-2 items-center'>
                    <SelectSearchInput
                        questionData={QuestionData.summary.genderSummary}
                        required={true}
                        value={state.summary?.genderSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                    <SelectSearchInput
                        bgcolor={"bg-white"}
                        questionData={QuestionData.summary.ageSummary}
                        required={true}
                        value={state.summary?.ageSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                    <SelectSearchInput
                        bgcolor={"bg-white"}
                        questionData={QuestionData.summary?.occupationSummary}
                        required={true}
                        value={state.summary?.occupationSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                    <SelectSearchInput
                        bgcolor={"bg-white"}
                        questionData={QuestionData.summary?.tripMadeLWWDSummary}
                        required={true}
                        value={state.summary?.tripMadeLWWDSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                    <Datepicker
                        bgcolor={"bg-white"}
                        questionData={QuestionData.summary?.tripDateSummary}
                        required={true}
                        value={state.summary?.tripDateSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                    <NumInput
                        bgcolor={"bg-white"}
                        questionData={QuestionData.summary?.noOfTripsSummary}
                        required={true}
                        value={state.summary?.noOfTripsSummary}
                        handleInputChange={handleInputChange}
                        width={"50%"}
                    />
                </div>
                <div className='flex justify-between items-center mx-6'>
                    <button onClick={handleAddPersonSummary} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                        Add Person
                    </button>
                    <button onClick={handleOpen} className='bg-other hover:bg-slate-900 text-white py-2 px-8 my-2 rounded'>
                        Cancel
                    </button>
                </div>
            </dialog>

        </div>
    )
}

export default Summary

//