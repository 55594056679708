import React from 'react';

export default function Time({questionData, required, handleInputChange, width,value }) {

  const {question, field} = questionData;

  const handleTimeChange = (event) => {
    event.preventDefault();
    handleInputChange(field, event.target.value);
  };

  return (
      <div className={`w-full p-[2%] hover:bg-main rounded-lg w-[${width}]`}>
          <div className="text-lg  mb-2 w-full font-bold">{question}</div>
            <input
              type="time"
              value={value}
              onChange={handleTimeChange}
              required={required}
              className="border-2 border-slate-600 p-2 rounded w-full"
            />
      </div>
  );
}
