import React from 'react';

//This component takes 6 props
//1. questionData - this is an object that contains the question, field
//2. handleInputChange - this is a function that is used to update the state
//3. value - this is an array that contains the boolean value of the options
//4. required - this is a boolean value that is used to make the checkbox required
//5. width - this is a string that is used to set the width of the checkbox
//6. bgcolor - this is a string that is used to set the background color of the checkbox

const TextInput = ({ questionData, handleInputChange, required, width, bgcolor,value }) => {

  const { question, field } = questionData;

  const handleChange = (e) => {
    e.preventDefault();
    handleInputChange(field, e.target.value);
  };

  return (
    // <div className={`w-full p-[2%] hover:bg-main rounded-lg w-[${width}]`}>
    <div className={`p-[2%] hover:bg-main rounded-lg ${bgcolor} w-[${width}] flex-grow`}>
      <div className="mb-1 font-bold ">{question}</div>
      <input
        type="text"
        className='border-2 border-slate-600 mb-1 p-1 rounded w-full '
        placeholder="Type Here"
        value={value}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
};

export default TextInput;
