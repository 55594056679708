import React, { useEffect, useState } from 'react';
import DashboardCard from '../Components/DashboardCard';
import Histogram from '../Components/Histogram';
import fetchData from '../contexts/API';
import { DashboardData, icons } from '../Data/DashboardData';

export default function Dashboard() {
  const [arr, setArr] = useState([])

  const apiUrl = '/dashboardData';
  var postData = {};
  useEffect(() => {
    document.title = "Dashboard | Travel Demand Digital Survey Tool"

    fetchData(apiUrl, postData)
      .then((responseData) => {
        var dataArray = Object.entries(responseData.data);
        setArr(dataArray)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [])
  // Filter out 'trip_tt_data' from dataArray
  var filteredDataArray = arr.filter(([key]) => key !== 'trip_tt_data');

  // Function to generate a random color from the colorArray
  const getRandomColor = () => {
    const colorArray = ['#FF5733', '#42A5F5', '#00C853', '#FFC107', '#9C27B0']; // Add more colors as needed
    const randomIndex = Math.floor(Math.random() * colorArray.length);
    return colorArray[randomIndex];
  };



  return (
    <div>
      <h1 className="text-4xl font-bold text-gray-800 text-center mb-6">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 bg-gray-100 p-4"> {/* Set the white background here */}
        <div className="md:col-span-2">
          <h2 className="text-2xl font-bold text-gray-600 text-center">Data Count</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {filteredDataArray.map(([key, value], index) => (
              <div key={key}>
                <DashboardCard
                  data={{ key, value }}
                  //icon values are defined in dashboard data, change order there.
                  icon={icons[index]}
                  backgroundColor={getRandomColor()} // Pass the random color as a prop
                />
              </div>
            ))}
          </div>
        </div>
        {/* Render Histogram */}
        <div className="md:col-span-2 bg-gray-100 p-4">
          <h2 className="text-2xl font-bold text-gray-600 text-center">Data Histogram</h2>
          <Histogram
            data={DashboardData.data.trip_tt_data}
            width={600} // Adjust the width as needed for a bigger size
            height={450}
            xAxisLabel="Categories"
            yAxisLabel="Number of Arrivals"
          />
        </div>
      </div>
    </div>
  );
}
//Sample dashboard card template
{/* <DashboardCard
  data={{"hh_count", 2 }}
  //icon values are defined in dashboard data, change order there.
  icon={icons[o]}
  backgroundColor={'#FF5733'} // Pass the random color as a prop
/> */}