export function verifyGenderCount(
	maleCount,
	femaleCount,
	otherCount,
	totalCount
) {
	if (maleCount + femaleCount + otherCount === totalCount) return 1;
	else return 0;

}
export function verifyProfessionCount(
	kidsBelowFive,
	schoolStudent,
	collegeStudent,
	adultEmployees,
	adultRetired,
	adultUnemployed,
	speciallyAbled,
	totalNoOfPersons
) {

	return ((kidsBelowFive + schoolStudent + collegeStudent + adultEmployees + adultRetired + adultUnemployed + speciallyAbled) <= totalNoOfPersons)

}
export function verifyCarsUsedAsTaxi(
	fourWheelerPetrol,
	fourWheelerCNG,
	fourWheelerElectric,
	fourWheelerUsedAsTaxi
) {

	return (fourWheelerPetrol + fourWheelerCNG + fourWheelerElectric >= fourWheelerUsedAsTaxi);

}
export function verifyCarsEmployerProvided(
	fourWheelerPetrol,
	fourWheelerCNG,
	fourWheelerElectric,
	fourWheelerEmployerProvided
) {

	return (fourWheelerPetrol + fourWheelerCNG + fourWheelerElectric >= fourWheelerEmployerProvided);

}
export function PersonNumberCheck(personNo) {
	return (personNo >= 1)
}