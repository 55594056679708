import React from 'react'
// This is a generic table component that can be used to display any table
// It takes three props as input
// 1. heading - this is an array of strings that will be used as the heading of the table
//    1. each string in the heading array will be used as the heading of the table
//    2. example: ['Name','Age']
//    3. the above example will create a table with two columns and no rows
// 2. entry - this is an array of json objects that will be used as the entries of the table
//    1. each json object in the entry array will contain the data for each row of the table
//    2. example: [{name:'John',age:20},{name:'Jane',age:21}]
//    3. the above example will create a table with two rows and two columns
// 3. actions - this is an array of arrays that will be used as the actions of the table
//    each array in the actions array will contain two elements
//    1. text - this is a string that will be used as the text of the button
//    2. handleClick - this is a function that will be called when the button is clicked
//    3. example: [['Edit',()=>console.log('Edit button clicked')],['Delete',()=>console.log('Delete button clicked')]]


const Table = ({ heading, entry, actions }) => {
    return (
        <table className='w-full text-center rounded-lg bg-white'>
            <thead>
                <tr className='text-sm bg-slate-300 text-slate-800'>
                    {
                        heading.map((item, index) => {
                            return <th key={index} className='p-3'>{item}</th>
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {entry.map((json, index) => (
                    <tr key={index} className='text-sm text-gray-500'>
                        {
                            Object.keys(json).map((key, idx) => (
                                <td key={idx} className='py-3'>{json[key]}</td>
                            ))
                        }
                        <td key={index} className='text-sm text-gray-500'>
                            <div className='flex flex-col justify-center items-center'>
                                {
                                    actions &&
                                    actions.map(([text, handleClick]) =>
                                        <button onClick={(e) => handleClick(index, e)} className='bg-other hover:bg-slate-900 text-white py-1 px-1 my-1 w-3/4 rounded'>
                                            {text}
                                        </button>
                                    )}
                            </div>
                        </td>
                    </tr>
                ))}

            </tbody>
        </table>
    )
}

export default Table