import React from 'react';
import { useState } from 'react';

//This component takes 6 props
//1. questionData - this is an object that contains the question, options, field and none
//2. handleInputChange - this is a function that is used to update the state
//3. value - this is an array that contains the boolean value of the options
//4. required - this is a boolean value that is used to make the checkbox required
//5. width - this is a string that is used to set the width of the checkbox
//6. noneOfAbove - this is a boolean value that is used to disable the checkbox

const CheckBox = ({ questionData, handleInputChange, value, required, width, noneOfAbove, autoFocus = false }) => {
  const { question, options, field, none } = questionData;

  const handleChange = (optionIndex) => {
    handleInputChange(field[optionIndex], !value[optionIndex]);
  };

  const handleSetNone = () => {
    handleInputChange(none.field, !noneOfAbove); 
    {/* if there is an error "cannot read properties of filed" add ? next to none */}
    value.forEach((item, index) => {
      if (item) {
        handleInputChange(field[index], false);
      }
    });
  };


  return (
    <div className={`w-full p-[2%] hover:bg-main rounded-lg w-[${width}]`}>
      <h3 className="text-lg  mb-2 text-gray-800 select-none font-bold">{question}</h3>
      <div className="flex flex-wrap w-full bg-transparent">
        {options.map((options, index) => (
          <div
            key={index}
            className='my-1 mr-4 bg-white text-gray-800 select-none rounded-lg flex items-center'
          >
            <label className="select-none  p-2 rounded">
              <input
                type="checkbox"
                disabled={noneOfAbove}
                className='mr-1'
                checked={value[index]}
                onChange={() => handleChange(index)}
                required={required}
                autoFocus={autoFocus}
              />
              {options}
            </label>
          </div>
        ))}
        {/* if the user has passed something in none filed the none of the above option will render */}
        {none &&
          <div
            className='my-1 mr-4 bg-white text-gray-800 select-none rounded-lg flex items-center'
          >
            <label className="select-none  p-2 rounded">
              <input
                type="checkbox"
                className='mr-1'
                checked={noneOfAbove}
                onChange={() => handleSetNone()}
              />
              {none.option} {/* if there is an error "cannot read properties of option" add ? next to none */}
            </label>
          </div>
        }
      </div>
    </div>
  );
};

export default CheckBox;
