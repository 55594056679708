import React from "react";

//This component takes 6 props
//1. questionData - this is an object that contains the question, field
//2. handleInputChange - this is a function that is used to update the state
//3. value - this is an array that contains the boolean value of the options
//4. required - this is a boolean value that is used to make the checkbox required
//5. width - this is a string that is used to set the width of the checkbox
//6. bgcolor - this is a string that is used to set the background color of the checkbox


const PhoneInput = ({ questionData, handleInputChange, value, required, width, title, autoFocus = false }) => {

  const { question, field } = questionData;

  const handleChange = (e) => {
    e.preventDefault();
    handleInputChange(field, e.target.value);
  }

  var pattern;


  // Set pattern based on question type
  // Mobile Number: 10 digits
  // Landline Number: 11 digits starting with 080
  
  if (question === 'Mobile Number') pattern = "\\d{10}";
  else pattern = "080\\d{8}";



  return (
    <div className={`w-full p-[2%] hover:bg-main rounded-lg w-[${width}]`}>
      <div>
        <div className="mb-1 ">{question}</div>
        <div className='border-2 border-slate-600 rounded mb-1'>
          <input
            title={title}
            type="tel"
            className="border-1 border-slate-600 p-1 rounded w-full "
            placeholder="Enter your phone number here"
            value={value}
            onChange={handleChange}
            required={required}
            pattern={pattern}
            autoFocus={autoFocus}
          />
        </div>
      </div>
    </div>
  );
}

export default PhoneInput;