import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

//This component takes 5 props
//1. data - this is a json object which contains key-value pairs returned by the backend.
//2. width - this contains the value of width used to set the width of the canvas
//3. height - this contains the value of heightused to set the height of the canvas
//4. xAxisLabel - this contains the value of label shown in the x axis
//5. yAxisLabel- this contains the  value of label shown in the y axis


const Histogram = ({ data, width, height, xAxisLabel, yAxisLabel }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const labels = Object.keys(data);
    const values = Object.values(data);

    if (chartInstanceRef.current) {
      // If a chart instance exists, destroy it before creating a new one
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(chartRef.current, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: yAxisLabel,
          data: values,
          backgroundColor: 'gray',
          barPercentage: 0.6,
          categoryPercentage: 0.8,
        }]
      },
      options: {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: xAxisLabel,
              font: {
                weight: 'bold', // Make x-axis font bolder
              },
            },
            ticks: {
              autoSkip: false,
              max: labels.length - 1,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: yAxisLabel,
              font: {
                weight: 'bold', // Make y-axis font bolder
              },
            },
            ticks: {
              beginAtZero: true,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }, [data, xAxisLabel, yAxisLabel]);

  return <canvas ref={chartRef} width={width} height={height} />;
};

export default Histogram;
