import React, { useEffect } from "react";
import RadioInput from "../Components/RadioInput";
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
import NumInput from "../Components/NumInput";
import { QuestionData } from "../Data/QuestionData";
import DatePicker from "../Components/Datepicker";
import CheckBox from "../Components/CheckBoxInput";
import Time from "../Components/Time";
import TextInput from "../Components/TextInput";
import fetchData from '../contexts/API';
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";



export default function Page4() {
  const navigate = useNavigate();
  const { state, handleInputChange, handleIsEditing } = useContext(StateContext);
  useEffect(() => {
    document.title = "Page4 | Travel Demand Digital Survey Tool"
  }, [])
  useEffect(() => {
    if ([3, -100].includes(state.pageFour.outHome)) {
      handleInputChange('DATE_OF_TRIP', -100);
      handleInputChange('TRIP_TIMES', -100);
    }
    else if (state.pageFour.dateOfTrip === -100) {
      handleInputChange('DATE_OF_TRIP', "");
      handleInputChange('TRIP_TIMES', 0);
    }
  }, [state.pageFour.outHome]);
  const apiUrl = '/page4';
  const postData = {
    // privatePublicNone: state.pageFour?.privatePublicNone,
    privatePublicTwoWheeler: state.pageFour?.privatePublicTwoWheeler,
    privatePublicOwnCar: state.pageFour?.privatePublicOwnCar,
    privatePublicBMTC: state.pageFour?.privatePublicBMTC,
    privatePublicMetro: state.pageFour?.privatePublicMetro,
    privatePublicBicycle: state.pageFour?.privatePublicBicycle,
    privatePublicCarPool: state.pageFour?.privatePublicCarPool,
    // intermediatePublicNone: state.pageFour?.intermediatePublicNone,
    intermediatePublicAutoRickshaw: state.pageFour?.intermediatePublicAutoRickshaw,
    intermediatePublicOfficeCabVan: state.pageFour?.intermediatePublicOfficeCabVan,
    intermediatePublicTaxi: state.pageFour?.intermediatePublicTaxi,
    intermediatePublicRideHailingTwoWheeler: state.pageFour?.intermediatePublicRideHailingTwoWheeler,
    intermediatePublicRideHailingCar: state.pageFour?.intermediatePublicRideHailingCar,
    intermediatePublicRentalVehicle: state.pageFour?.intermediatePublicRentalVehicle,
    transitPassesDailyBus: state.pageFour?.transitPassesDailyBus,
    transitPassesWeeklyBus: state.pageFour?.transitPassesWeeklyBus,
    transitPassesMonthlyBus: state.pageFour?.transitPassesMonthlyBus,
    transitPassesStudent: state.pageFour?.transitPassesStudent,
    transitPassesPremiumVajra: state.pageFour?.transitPassesPremiumVajra,
    transitPassesVayuVajra: state.pageFour?.transitPassesVayuVajra,
    transitPassesMetro: state.pageFour?.transitPassesMetro,
    transitPassesOther: state.pageFour?.transitPassesOther,
    transitPassesNone: state.pageFour?.transitPassesNone,
    workTravel: state.pageFour?.workTravel,
    workTravelBeforeCOVID: state.pageFour?.workTravelBeforeCOVID,
    outHome: state.pageFour?.outHome,
    tripDate: state.pageFour?.dateOfTrip,
    numberOfTrips: state.pageFour?.noOfTrips,
    remarksPI: state.pageFour?.remarksPI,
    currentPage: state.pageFour?.currentPage,
    hhRefNo: state.hhRefNo,
    personNo: state.personNo,
    perosnCp2Time: ""
  };
  const handleClickNext = (event) => {
    event.preventDefault();
    postData.perosnCp2Time = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      fractionalSecondDigits: 3,
      timeZone: 'IST'
    }).format(Date.now())
    fetchData(apiUrl, postData)
      .then((responseData) => {
        if (responseData.status == 1) {
          navigate('/Summary');
          if (state.isEditing) {
            toast.success(`Person ${state.personNo} updated successfully!`)
          } else {
            toast.success(`Person ${state.personNo} added successfully!`)
          }
        }
        else toast.error(responseData.data)
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        handleIsEditing(false)
      });
  }




  return (

    <form onSubmit={handleClickNext}>
      <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>
        <div className='w-full p-[1%] font-semibold'>{QuestionData.pageThree.title}</div>

        <CheckBox
          questionData={QuestionData.pageFour.PrivatePublicTransportModes}
          handleInputChange={handleInputChange}
          value={[state.pageFour.privatePublicTwoWheeler, state.pageFour.privatePublicMetro, state.pageFour.privatePublicOwnCar, state.pageFour.privatePublicBicycle, state.pageFour.privatePublicBMTC, state.pageFour.privatePublicCarPool]}
          noneOfAbove={state.pageFour.privatePublicNone}
          required={false}
          autofocus={true}
        />
        <CheckBox
          questionData={QuestionData.pageFour.IntermediatePublicTransportModes}
          handleInputChange={handleInputChange}
          value={[state.pageFour.intermediatePublicAutoRickshaw, state.pageFour.intermediatePublicRideHailingTwoWheeler, state.pageFour.intermediatePublicOfficeCabVan, state.pageFour.intermediatePublicRideHailingCar, state.pageFour.intermediatePublicTaxi, state.pageFour.intermediatePublicRentalVehicle]}
          noneOfAbove={state.pageFour.intermediatePublicNone}
          required={false}
        />
        {((state.pageFour.privatePublicMetro == true) || (state.pageFour.privatePublicBMTC == true)) && (
          <CheckBox
            questionData={QuestionData.pageFour.TransitPasses}
            handleInputChange={handleInputChange}
            value={[state.pageFour.transitPassesDailyBus, state.pageFour.transitPassesWeeklyBus, state.pageFour.transitPassesMonthlyBus, state.pageFour.transitPassesStudent, state.pageFour.transitPassesMetro, state.pageFour.transitPassesPremiumVajra, state.pageFour.transitPassesVayuVajra, state.pageFour.transitPassesOther]}
            noneOfAbove={state.pageFour.transitPassesNone}
            required={false}
          />)}
        <NumInput
          questionData={QuestionData.pageFour.workTravel}
          handleInputChange={handleInputChange}
          value={state.pageFour.workTravel}
          required={true}
          width={"50%"}
          min={1}
        />
        <NumInput
          questionData={QuestionData.pageFour.workTravelBeforeCOVID}
          handleInputChange={handleInputChange}
          value={state.pageFour.workTravelBeforeCOVID}
          required={true}
          width={"50%"}
          min={1}
        />
        <RadioInput bgcolor={"bg-white"}
          questionData={QuestionData.pageFour.outHome}
          handleInputChange={handleInputChange}
          value={state.pageFour.outHome}
          required={true}
        />
        {[1, 2].includes(state.pageFour.outHome) && (
          <div>
            <DatePicker questionData={QuestionData.pageFour.dateOfTrip} value={state.pageFour.dateOfTrip} required={true} handleInputChange={handleInputChange} />
            <NumInput
              questionData={QuestionData.pageFour.noOfTrips}
              handleInputChange={handleInputChange}
              value={state.pageFour.noOfTrips}
              required={true}
              width={"50%"}
            />
          </div>
        )}

        <TextInput
          questionData={QuestionData.pageFour.RemarksPI}
          handleInputChange={handleInputChange}
          value={state.pageFour.remarksPI}
          required={false}
        />

      </div>


      {/* <button type='submit' className="bg-other hover:bg-slate-900 text-white  py-2 px-2 rounded my-8">
        Proceed to Trip Information Section
      </button> */}

      <div className="flex flex-1 justify-between items-center">
        <Link to={'/page3'} className="bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8 flex flex-row-reverse justify-between items-center gap-x-3">
          Back <FaArrowLeft />
        </Link>
        <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
          Proceed to Person Summary Table <FaArrowRight />
        </button>
      </div>
    </form>
  )

}

