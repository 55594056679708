import React, { useEffect, useRef, useState, useContext } from 'react';
import { StateContext } from '../contexts/StateContext';
import toast from 'react-hot-toast';

//This component takes 4 props
//1. questionData - this is an object that contains the question,field.
//2. handleInputChange - this is a function that is used to update the state
//3. value - this is an array that contains the address, latitude and longitude
//4. required - this is a boolean value that is used to make the map component required


const SearchCurrent = ({ questionData, handleInputsChange, value, required }) => {
  // Refs for map, input, search box, and markers
  const mapRef = useRef(null);
  const map = useRef(null);
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);
  const markersRef = useRef([]);
  // State variables
  const [searchValue, setSearchValue] = useState(value[0]);
  const [currentMarkerInfo, setCurrentMarkerInfo] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const { state, handleInputChange } = useContext(StateContext);
  const { question, field } = questionData;

  const initializeMap = () => {
    try {
      // Check if latitude and longitude are valid
      const isInvalidCoordinates = value[1] === "" || value[1] === -100 || value[2] === "" || value[2] === -100 || value[0] === "" || value[0] === -100;

      // Set the initial position based on the provided coordinates or the default
      // Currently even if a map is not shown (i.e location is -100, then to avoid map load error, the co-ordinates are defaulted to Bangalore)
      const initialPosition = isInvalidCoordinates
        ? { lat: 12.9716, lng: 77.5946 } // Default coordinates for Bengaluru
        : { lat: parseFloat(value[1]), lng: parseFloat(value[2]) }; // Use provided coordinates

      // Map initialization
      map.current = new window.google.maps.Map(mapRef.current, {
        center: initialPosition, // Setting the initial center of the map to Bengaluru
        zoom: 13,
        mapTypeId: 'roadmap',
      });

      // Event to listen when the user types in the search box
      inputRef.current.addEventListener('input', performSearch);

      // Using ref to access the Search box and placing the new address in the search box
      searchBoxRef.current = new window.google.maps.places.SearchBox(inputRef.current);
      map.current.controls[window.google.maps.ControlPosition.TOP_CENTER].push(inputRef.current);

      map.current.addListener('bounds_changed', () => {
        searchBoxRef.current.setBounds(map.current.getBounds());
      });

      searchBoxRef.current.addListener('places_changed', () => {
        const places = searchBoxRef.current.getPlaces();
        // If nothing is searched, the search box shouldn't repeat previous values
        if (places.length === 0) {
          return;
        }

        markersRef.current.forEach((marker) => {
          marker.setMap(null);
        });
        markersRef.current = [];

        // Create bounds for fitting the map view
        const bounds = new window.google.maps.LatLngBounds();

        // Checking if the place really exists according to the user's search
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            toast.error('Returned place contains no geometry');
            return;
          }

          const { lat, lng } = place.geometry.location;
          const latitude = lat();
          const longitude = lng();
          const name = place.name;
          const formattedAddress = place.formatted_address;

          // Function to load the coords and text to the centralized context variables
          handleInputsChange(field[0], longitude);
          handleInputsChange(field[1], latitude);
          handleInputsChange(field[2], name + formattedAddress);

          const icon = {
            // Setting the properties of the icons in the map
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25),
          };

          // Functionality to the marker to appear and allow it to be dragged when pulled by the user
          const marker = new window.google.maps.Marker({
            map: map.current,
            icon,
            title: place.name,
            position: place.geometry.location,
            draggable: true,
          });

          // Event Listener to function when the marker is pulled to a new place
          // This fetches the new coordinates and the text and inserts it into the search box
          marker.addListener('dragend', () => {
            const newPosition = marker.getPosition();
            map.current.panTo(newPosition);
            const newLatitude = newPosition.lat();
            const newLongitude = newPosition.lng();

            const geocoder = new window.google.maps.Geocoder();
            const latLng = { lat: newLatitude, lng: newLongitude };

            geocoder.geocode({ location: latLng }, (results, status) => {
              if (status === window.google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  const newAddress = results[0].formatted_address;
                  setSearchValue(newAddress);
                  setCurrentMarkerInfo({
                    latitude: newLatitude,
                    longitude: newLongitude,
                    name: place.name,
                    formattedAddress: newAddress,
                  });

                  // Update the data based on the new marker position
                  handleInputsChange(field[0], newLongitude);
                  handleInputsChange(field[1], newLatitude);
                  handleInputsChange(field[2], place.name + ',' + newAddress);
                }
              } else {
                console.error('Geocoder failed due to:', status);
              }
            });
          });

          markersRef.current.push(marker);

          // Checking if the place dragged to is within the bounds or not
          // If not, the map will move where the marker is dragged to
          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        // Showing errors accordingly when the bound condition is not met
        if (!bounds.isEmpty()) {
          try {
            map.current.fitBounds(bounds);
          } catch (error) {
            console.error('Failed to fit bounds:', error);
          }
        }

        setSearchValue(places[0].name);
      });

      // Add the initial marker
      const initialMarker = new window.google.maps.Marker({
        map: map.current,
        position: initialPosition, // Set the initial coordinates here
        draggable: true,
      });

      // Event Listener for the initial marker's dragend event
      initialMarker.addListener('dragend', () => {
        const newPosition = initialMarker.getPosition();
        map.current.panTo(newPosition);
        const newLatitude = newPosition.lat();
        const newLongitude = newPosition.lng();

        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: newLatitude, lng: newLongitude };

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              const newAddress = results[0].formatted_address;
              setSearchValue(newAddress);
              setCurrentMarkerInfo({
                latitude: newLatitude,
                longitude: newLongitude,

                formattedAddress: newAddress,
              });

              // Update the data based on the new marker position
              handleInputsChange(field[0], newLongitude);
              handleInputsChange(field[1], newLatitude);
              handleInputsChange(field[2], newAddress);
            }
          } else {
            console.error('Geocoder failed due to:', status);
          }
        });
      });

      markersRef.current.push(initialMarker);
    } catch (error) {
      console.error('Failed to initialize the map:', error);
    }
  };

  // Function to enable search with a search option
  const performSearch = () => {
    setSearchValue(inputRef.current.value);
  };



  // Function to load Google Maps script 
  useEffect(() => {
    if (showMap) {

      // Function to initialize the Google Maps instance and setup the search functionality
      const handleMapScriptError = () => {
        console.error('Failed to load Google Maps script.');
      };


      const loadMapScript = () => {
        try {
          //Loadint the API key to access the google map and place api to retrieve the coords and text of the address
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA-pc2vV26ZqihhGUSYt7Dk-NeDHi5freo&libraries=places`;
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);
          script.onload = initializeMap;
          script.onerror = handleMapScriptError;
        } catch (error) {
          handleMapScriptError();
        }
      };


      loadMapScript();

      // return () => {

      // };
    }
  }, [showMap]);


  //Function to handle the appearance and dissappearance of the map component when the button is
  //
  const handleButtonClick = (event) => {
    event.preventDefault();
    setSearchValue(value[0])
    setShowMap(!showMap);

  };
  // useEffect(()=>{
  //   setSearchValue(value[0])

  // },[state])

  const getCurrentPosition = (event) => {
    //Function to rretrieve the users present location
    event.preventDefault();
    if (navigator.geolocation) {
      //using geolocation api to get the coords of the position the user currently is in
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: latitude, lng: longitude };
        // Use Geocoder to retrieve formatted address for the place
        //Use Geocoder to retrieve formatted address for the new position
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              const address = results[0].formatted_address;
              setSearchValue(address);
              setCurrentMarkerInfo({
                name: 'Current Position',
                formattedAddress: address,
              });



              //Function to send the coords and the text to the centralized context variables
              handleInputsChange(field[0], longitude);
              handleInputsChange(field[1], latitude);
              handleInputsChange(field[2], address);

              // Add the marker for the current position
              const currentPositionMarker = new window.google.maps.Marker({
                map: map.current,
                title: 'Current Position',
                position: latLng,
                draggable: true,
              });
              //Function to act accordingly when current location button is clicked then when the marker is dragged
              //this is a different case as to when place is searched and then when the marker is dragged
              currentPositionMarker.addListener('dragend', () => {
                const newPosition = currentPositionMarker.getPosition();
                map.current.panTo(newPosition);
                const newLatitude = newPosition.lat();
                const newLongitude = newPosition.lng();

                const geocoder = new window.google.maps.Geocoder();
                const latLng = { lat: newLatitude, lng: newLongitude };

                geocoder.geocode({ location: latLng }, (results, status) => {
                  if (status === window.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                      const newAddress = results[0].formatted_address;
                      setSearchValue(newAddress);
                      setCurrentMarkerInfo({
                        latitude: newLatitude,
                        longitude: newLongitude,
                        name: 'Current Position',
                        formattedAddress: newAddress,
                      });


                      // Update the data based on the new marker position

                      handleInputsChange(field[0], newLongitude);
                      handleInputsChange(field[1], newLatitude);
                      handleInputsChange(field[2], newAddress);

                    }
                  } else {
                    console.error('Geocoder failed due to:', status);
                  }
                });
              });

              markersRef.current.push(currentPositionMarker);

              map.current.panTo(latLng);
            }
          } else {
            console.error('Geocoder failed due to:', status);
          }
        });
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };



  return (
    <div className='w-full p-[2%] hover:bg-main rounded-lg'>
      <div className="mb-2 font-bold">{question}</div>
      <div className="flex flex-col items-center ">
        {/* Button to control the visibility of the map */}
        <button className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded" onClick={handleButtonClick}>
          {showMap ? 'Hide Map' : 'Show Map'}
        </button>

        <h1 className="">Operate the map by clicking on the button. Move the Marker By Dragging</h1>
        {/* Normal and condition to render the component based on the value of the show map variable */}
        {showMap && (
          <div className="bg-white p-4 w-full hover:bg-main rounded-lg ">
            <div ref={mapRef} id="map" className="h-80 w-full items-center" style={{ marginBottom: '1rem' }}></div>
            <input
              ref={inputRef}
              id="pac-input"
              className="border border-gray-300 rounded-md px-4 py-2 w-full max-w-md"
              type="text"
              placeholder="Search Box"
              value={searchValue}
              onChange={performSearch}
              required={required}
            />

            <button className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded items-center" onClick={getCurrentPosition}>
              Use Current Position
            </button>

          </div>
        )}
        <div>
          <h1 className='font-bold text-black text-center'>Selected Address</h1>
          <div className='flex flex-row'>
            <span id='set'></span>
            <span className="font-bold text-black text-center">
              {value[0]}    {/*the first index contains the address the next two store the lat and the long*/}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCurrent;