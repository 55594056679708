import React, { useContext, useEffect, useState } from 'react'
import Table from '../Components/Table'
import { QuestionData } from '../Data/QuestionData'
import { useNavigate } from 'react-router-dom'
import fetchData from '../contexts/API'
import { StateContext } from '../contexts/StateContext'
import toast from 'react-hot-toast'


const TripSummary = () => {

    const navigate = useNavigate()
    const { state, handleInputChange, handleIsEditing, flushTripInfo, flushPersonInfo, handleTripEdit } = useContext(StateContext)
    const [tableData, setTableData] = useState([{}])
    useEffect(() => {
        document.title = "Trip Summary | Travel Demand Digital Survey Tool"
        handleIsEditing(false)
    }, [])

    const handleEditTrip = (index) => {
        handleIsEditing(true)
        flushTripInfo()
        handleInputChange("TRIP_NO", index + 1)
        navigate('/page5')
        const ApiUrl = '/tripEdit'
        fetchData(ApiUrl, {
            hhRefNo: state.hhRefNo,
            personNo: state.personNo,
            tripNo: index + 1,
        }).then((responseData) => {
            handleTripEdit(responseData.data)
            toast.success(`Edit Trip ${responseData.data.trip_no} for Person ${responseData.data.person_no} !`)
        }).catch((error) => {
            console.error(error);
            toast.error("Data load error!")
        });
    }


    const handleAddNewTrip = () => {
        //flush local starage from page3,4,5,6
        handleIsEditing(false)
        handleInputChange("TRIP_NO", state.tripNo + 1)
        flushPersonInfo()
        flushTripInfo()
        //setting the workplace and school address back
        // Retrieve school data from local storage
        const schoolAddressText = localStorage.getItem('schoolAddressText');
        const schoolLat = localStorage.getItem('schoolLat');
        const schoolLong = localStorage.getItem('schoolLong');

        // Retrieve workplace data from local storage
        const workplaceAddressText = localStorage.getItem('workplaceAddressText');
        const workplaceLat = localStorage.getItem('workplaceLat');
        const workplaceLong = localStorage.getItem('workplaceLong');
        if (workplaceAddressText != -100) {
            handleInputChange("WORKPLACE_ADDRESS_LONG", workplaceLong)
            handleInputChange("WORKPLACE_ADDRESS_LAT", workplaceLat)
            handleInputChange("WORKPLACE_ADDRESS_NAME", workplaceAddressText)
        }
        if (schoolAddressText != -100) {
            handleInputChange("SCHOOL_ADDRESS_LONG", schoolLong)
            handleInputChange("SCHOOL_ADDRESS_LAT", schoolLat)
            handleInputChange("SCHOOL_ADDRESS_NAME", schoolAddressText)
        }
        navigate('/page5')
    }

    const apiUrl = '/tripSummary'
    useEffect(() => {
        var postData = {
            hhRefNo: state.hhRefNo,
            personNo: state.tripSummary.currentPersonNo
        }
        fetchData(apiUrl, postData)
            .then((responseData) => {
                setTableData(responseData.data)
                handleInputChange("TRIP_NO", responseData.data.length)
                // Set school data in local storage
                localStorage.setItem('schoolAddressText', responseData.wpSchoolInfo.schoolAddressText);
                localStorage.setItem('schoolLat', responseData.wpSchoolInfo.schoolLat);
                localStorage.setItem('schoolLong', responseData.wpSchoolInfo.schoolLong);

                // Set workplace data in local storage
                localStorage.setItem('workplaceAddressText', responseData.wpSchoolInfo.workplaceAddressText);
                localStorage.setItem('workplaceLat', responseData.wpSchoolInfo.workplaceLat);
                localStorage.setItem('workplaceLong', responseData.wpSchoolInfo.workplaceLong);


            })
    }, [state.tripNo])
    return (
        <div className='flex flex-col w-full mt-4'>
            <div className='text-2xl font-bold text-center mb-10'>Trip Info Table</div>
            <div className='flex justify-between items-center'>
                <div className='flex justify-end bg-slate-300 font-semibold w-max rounded-lg py-2 px-4 my-2 select-none'>
                    Person No. {state.tripSummary.currentPersonNo}
                </div>

                <button
                    className='flex justify-start w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-2 rounded'
                    onClick={handleAddNewTrip}
                >
                    Add New Trip
                </button>
            </div>
            <Table
                heading={QuestionData.tripSummary.head}
                entry={tableData}
                actions={[["Edit", handleEditTrip]]}
            />

            <button
                className='flex justify-start w-max bg-other hover:bg-slate-900 text-white py-2 px-4 my-4 rounded'
                onClick={() => navigate('/summary')}
            >
                Go Back to Person Summary
            </button>
        </div>
    )
}

export default TripSummary