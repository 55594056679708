

export const QuestionData = {
  home: {
    hhRefNo:
    {
      question: "Household Reference number",
      field: "HH_REF_NO"
    }
  },
  pageZero: {
    wardNo:
    {
      question: "Ward number",
      field: "WARD_NO"
    },
    enumeratorCode:
    {
      question: "Enumerator Code",
      field: "ENUMERATOR_CODE"
    },
    surveyDate:
    {
      question: "Date of Survey",
      field: "SURVEY_DATE"
    },
    locationCode:
    {
      question: "Location Code",
      field: "LOCATION_CODE"
    },
    enumeratorAddress:
    {
      field: ['ENUMERATOR_LONG', 'ENUMERATOR_LAT']
    }

  },
  pageOne: {
    enumeratorAddress:
    {
      field: ['PAGE1_ENUMERATOR_LONG', 'PAGE1_ENUMERATOR_LAT']
    },
    Address:
    {
      question: "Address of Household",
      field: ['ADDRESS_LONG', 'ADDRESS_LAT', 'ADDRESS_TEXT']
    },
    mobileNumber:
    {
      question: "Mobile Number",
      field: "PHONE_NUMBER"
    },
    landlineNumber:
    {
      question: "Landline",
      field: "LANDLINE_NUMBER"
    },
    houseType: {
      question: 'HouseHold Type',
      options: ['Independent Single House', 'Multi-storey building with <= 5units of Housing', 'Multi-storey building with > 5 units of Housing', 'Apartment with parking, small play area, gym (Medium-sized gated community)', 'Apartment with parking, playground, gym, provision store,etc (Large-sized gated community)', 'Informal Housing'],
      field: "HOUSE_TYPE"
    },
    houseOwnership: {
      question: 'House Ownership',
      options: ['Own House', 'Rented House', 'Govt. Quarters', 'Employer Provided', 'Others'],
      field: "HOUSE_OWNERSHIP"
    },
    houseSize: {
      question: 'House Size',
      options: ['1 Room', '1 Room Kitchen (1 RK)', '1 Bedroom Hall Kitchen (1 BHK)', '2 Bedroom Hall Kitchen (2 BHK)', '3 Bedroom Hall Kitchen (3 BHK)', '>3 Bedroom Hall Kitchen (>4 BHK)'],
      field: "HOUSE_SIZE"
    },
    houseMRV:
    {
      question: 'Monthly Rental Value (Rs.)',
      field: "MONTHLY_RENTAL"
    },
    totalNoOfPersons:
    {
      question: "Total No. of Persons living in the Household",
      field: "TOTAL_NO_OF_PERSONS",
    },
    totalNoMale:
    {
      question: "Number of Male",
      field: "TOTAL_NO_MALE"
    },
    totalNoFemale:
    {
      question: "Number of Female",
      field: "TOTAL_NO_FEMALE"
    },
    otherGenderCount:
    {
      question: "Others",
      field: "OTHER_GENDER_COUNT"
    },
    kidsBelowFive:
    {
      question: "Kids (< 5 years)",
      field: "KIDS_BELOW_FIVE"
    },
    schoolStudent:
    {
      question: "Students ( School - up to 10th Standard)",
      field: "SCHOOL_STUDENT"
    },
    collegeStudent:
    {
      question: "Students (College - post 10th Standard)",
      field: "COLLEGE_STUDENT"
    },
    adultEmployees:
    {
      question: "Adult Employees (Employed/ Self-employed/)",
      field: "ADULT_EMPLOYEES"
    },
    adultRetired:
    {
      question: "Adult (Retired)",
      field: "ADULT_RETIRED"
    },
    adultUnemployed:
    {
      question: "Adult (Unemployed)",
      field: "ADULT_UNEMPLOYED"
    },
    speciallyAbled:
    {
      question: "Number of Specially Abled Persons and Elderly Persons who need support for travel",
      field: "SPECIALY_ABLED"
    },

  },
  pageTwo: {
    title: "Number of Vehicles in the Household",
    fourWheeler:
    {
      question: "Cars/Jeeps/SUVs used for personal use",
      petrolDiesel: {
        question: "Petrol/Diesel",
        field: 'FOUR_WHEELER_PETROL'
      },
      cng: {
        question: "CNG",
        field: "FOUR_WHEELER_CNG"
      },
      electric: {
        question: "Electric",
        field: "FOUR_WHEELER_ELECTRIC"
      },
    },
    twoWheeler: {
      question: "Two-wheelers ",
      petrolDiesel: {
        question: "Petrol/Diesel",
        field: "TWO_WHEELER_PETROL"
      },
      cng: {
        question: "CNG",
        field: "TWO_WHEELER_CNG"
      },
      electric: {
        question: "Electric",
        field: "TWO_WHEELER_ELECTRIC"
      },
    },
    autoRickshaw: {
      question: "Auto-rickshaws",
      petrolDiesel: {
        question: "Petrol/Diesel",
        field: "AUTO_RICKSHAW_PETROL"
      },
      cng: {
        question: "CNG",
        field: "AUTO_RICKSHAW_CNG"
      },
      electric: {
        question: "Electric",
        field: "AUTO_RICKSHAW_ELECTRIC"
      },
    },
    others: {
      main: {
        question: "Others (Please Specify)",
        field: "OTHERS"
      },
      petrolDiesel: {
        question: "Petrol/Diesel",
        field: "OTHERS_PETROL"
      },
      cng: {
        question: "CNG",
        field: "OTHERS_CNG"
      },
      electric: {
        question: "Electric",
        field: "OTHERS_ELECTRIC"
      },
    },
    bicycle:
    {
      question: "Bicycles",
      field: "BICYCLE"
    },
    fourWheelerUsedAsTaxi:
    {
      question: "How many of the above mentioned cars are used as Taxis/Cabs?",
      field: "FOUR_WHEELER_USED_AS_TAXI"
    },
    fourWheelerEmployerProvided:
    {
      question: "How many of the above mentioned cars are employer provided?",
      field: "FOUR_WHEELER_EMPLOYER_PROVIDED"
    },
    hhIncome: {
      question: "Household Monthly Income from all sources (Rs.)",
      options: ["<= 10,000", "10,001-25,000", "25,001-50,000", "50,001-1,00,000", "1,00,001-2,00,000", "> 2 Lakh"],
      field: "HH_INCOME"
    },
    dailyDelivery: {
      question: "Which of the following do you get delivered to your house on a daily basis:",
      options: ["Milk", "Newspaper", "Others"],
      field: ["MILK", "NEWSPAPER", "OTHER_DAILY_DELIVERY"],
      none: {
        option: "No Daily Delivery",
        field: "NO_DAILY_DELIVERY"
      }
    },
    food: {
      question: 'Food: (Zomato, Swiggy, etc.)',
      options: ['No', 'Rarely', 'Once a Month', '2-3 Times a Month', 'Once a Week', '2-3 Times a Week', '4 Time or More in a Week'],
      field: "FOOD_DELIVERY"
    },
    Groceries: {
      question: 'Groceries: (Bigbasket, Grofers, etc.)',
      options: ['No', 'Rarely', 'Once a Month', '2-3 Times a Month', 'Once a Week', '2-3 Times a Week', '4 Time or More in a Week'],
      field: "GROCERY_DELIVERY"
    },
    Others: {
      question: 'Others (Clothes, electronics, etc.): (Amazon, flipkart, etc.)',
      options: ['No', 'Rarely', 'Once a Month', '2-3 Times a Month', 'Once a Week', '2-3 Times a Week', '4 Time or More in a Week'],
      field: "OTHER_DELIVERY"
    },
    Remarks: {
      question: 'Any Remarks?',
      field: 'REMARKS'
    }
    // otherDelivery: {
    //   question: "Other than milk and newspaper, how often do you (including all household members) get the following delivered to your house via online purchases/e-commerce",
    //   options: ["Food:(Zomato, Swiggy, etc.)", "Groceries: (Bigbasket, Grofers, etc.)", "Others (Clothes, electronics, etc.): (Amazon, flipkart, etc.)"],
    //   field: ["FOOD_DELIVERY","GROCERY_DELIVERY","OTHER_DELIVERY"]
    // }
  },
  pageThree: {
    title: "Personal Information",
    enumeratorAddress:
    {
      field: ['PAGE3_ENUMERATOR_LONG', 'PAGE3_ENUMERATOR_LAT']
    },
    PersonNumber: {
      question: "Person-Number",
      field: "PERSON_NO"
    },
    answeredOnBehalf: {
      question: "Answered On Behalf",
      options: ["Yes", "No"],
      field: "ANSWERED_ON_BEHALF"
    },
    Gender: {
      question: "Gender",
      options: ["Male", "Female", "Others"],
      field: "GENDER"
    },
    Age: {
      question: "Age",
      options: ["5-12 years", "13-16 years", "17-18 years", "19-25 years", "26-35 years", "36-45 years", "46-60 years", "> 60 years"],
      field: "AGE"
    },
    RelationWithPerson1: {
      question: "Relation with Person-1",
      options: ["Self", "Son/Daughter", "Father/Mother", "Wife/Husband/Partner", "Brother/Sister", "Other relative", "Not related"],
      field: "RELATION_WITH_PERSON1"
    },
    Education: {
      question: "Education",
      options: ["< 10th Std. / None", "10th Std. (SSLC)", "12th Std. (PUC/HSE)", "Trade Certificate", "Diploma", "Graduate degree", "Post-graduate degree", "Doctorate"],
      field: "EDUCATION"
    },
    Occupation: {
      question: "Occupation",
      options: ["Unemployed", "Jobseeker", "Homemaker", "Student (full-time) ", "Student (part-time) ", "Employed_Govt. (full-time) ", "Employed_Govt. (part-time) ", "Employed_Private (full-time) ", "Employed_Private (part-time) ", "Self Employed", "Daily Wage", "Retired", "Others"],
      field: "OCCUPATION"
    },
    Profession: {
      question: "Profession",
      options: ["Agriculture/Forestry/Fishing", "Govt.(State/Central/Local/Defence/Public Admin)", "IT/IT-services & Communication", "Manufacturing/Construction/Real-estate", "Financial Services/Banking/Insurance/Transport/Logistics", "Education", "Health Care/Social Work", "Own Business/Wholesale or Retail Trade", "Hospitality (Accommodation/Hotels/Dining/Food&Beverages/Tourism)", "Others"],
      field: "PROFESSION"
    },
    MonthlyIncome: {
      question: "Monthly Income",
      options: ["<=10,000 ", "10,001 - 25,000 ", "25,001 - 50,000", "50,001 - 1,00,000", "1,00,001 - 2,00,000 ", ">2 Lakhs",],
      field: "INCOME"
    },
    WorkAddress: {
      question: "Workspace address",
      field: ["WORKPLACE_ADDRESS_LONG", "WORKPLACE_ADDRESS_LAT", "WORKPLACE_ADDRESS_NAME"]
    },
    SchoolAddress: {
      question: "School/College address",
      field: ["SCHOOL_ADDRESS_LONG", "SCHOOL_ADDRESS_LAT", "SCHOOL_ADDRESS_NAME"]
    },
    EmployerProvidedTravelOption: {
      question: "Do you have an employer provided travel option?",
      options: ["Yes", "No"],
      field: "EMPLOYER_TRAVEL_OPTION"
    },
    DaysWorkedFromHome: {
      question: "Do You ever work from home without going to the office? If yes, How many days?",
      options: ["No", "Yes, 1-2 days a month", "Yes, 3-4 days a month", "Yes, 1-2 days a week", "Yes, 3-4 days a week", "Yes, >= 5 days a week"],
      field: "DAYS_WORKED_FROM_HOME"
    },
    DaysWorkedFromHomeBeforeCOVID: {
      question: "Did You ever work from home without going to the office before COVID?",
      options: ["No", "Yes, 1-2 days a month", "Yes, 3-4 days a month", "Yes, 1-2 days a week", "Yes, 3-4 days a week", "Yes, >= 5 days a week"],
      field: "BEFORE_COVID"
    },
    DrivingLicense: {
      question: "Do you have a Driving License?",
      options: ["Yes", "No",],
      field: "DRIVING_LICENSE"
    },
    PersonStarttime: {
      question: "Start time for the Travel?",

      field: "START_TIME"
    }
  },
  pageFour: {
    PrivatePublicTransportModes: {
      question: "Have you travelled by the following private or public transport modes at least once in the last 6 months? (Tick all the modes you used)",
      options: ["Own Two-wheeler (drove it or was a passenger in it)", "Metro/Train", "Own Car (drove it or was a passenger in it)", "Bicycle", "BMTC Bus", "Carpool or bike pool (share ride with friends/colleagues in their car or two-wheeler) NOT Ola/Uber"],
      field: ["PRIVATE_PUBLIC_OWN_TWO_WHEELER", "PRIVATE_PUBLIC_METRO_TRAIN", "PRIVATE_PUBLIC_OWN_CAR", "PRIVATE_PUBLIC_BICYCLE", "PRIVATE_PUBLIC_BMTC_BUS", "PRIVATE_PUBLIC_CARPOOL_BIKE_POOL"],
      none: {
        option: "None",
        field: "PRIVATE_PUBLIC_NONE"
      }
    },
    IntermediatePublicTransportModes: {
      question: "Have you travelled by the following Intermediate public transport modes at least once in the last 6 months? (Tick all the modes you used)",
      options: ["Auto-rickshaw", "Ride-hailing Two-wheeler (Ola/Uber, etc.)", "Office Cab/Van", "Ride-hailing Car (Ola/Uber, etc.)", "Taxi (other than Ola/Uber, etc.)", "Rental Vehicle"],
      field: ["INTERMEDIATE_PUBLIC_AUTO_RICKSHAW", "INTERMEDIATE_PUBLIC_RIDE_HAILING_TWO_WHEELER", "INTERMEDIATE_PUBLIC_OFFICE_CAB_VAN", "INTERMEDIATE_PUBLIC_RIDE_HAILING_CAR", "INTERMEDIATE_PUBLIC_TAXI", "INTERMEDIATE_PUBLIC_RENTAL_VEHICLE"],
      none: {
        option: "None",
        field: "INTERMEDIATE_PUBLIC_NONE"
      }
    },
    TransitPasses: {
      question: "Do you use any of the following transit passes?",
      options: ["Daily Bus Pass", "Weekly Bus Pass", "Monthly Bus Pass", "Student Pass", "Metro Pass", "Premium Vajra Pass", "Vayu Vajra Gold Pass", "Other type of Pass"],
      field: ["TRANSIT_PASSES_DAILY_BUS", "TRANSIT_PASSES_WEEKLY_BUS", "TRANSIT_PASSES_MONTHLY_BUS", "TRANSIT_PASSES_STUDENT", "TRANSIT_PASSES_METRO", "TRANSIT_PASSES_PREMIUM_VAJRA", "TRANSIT_PASSES_VAYU_VAJRA", "TRANSIT_PASSES_OTHER"],
      none: {
        option: "Does not use any pass",
        field: "TRANSIT_PASSES_NONE"
      }
    },
    workTravel: {
      question: "How much time do you spend (on an average) on a typical working day for travel? (in minutes)",
      field: "WORK_TRAVEL"
    },
    workTravelBeforeCOVID: {
      question: "Before COVID-2019, how much time did you spend on a typical working day for travel? (in minutes)",
      field: "WORK_TRAVEL_BEFORE_COVID"
    },
    outHome: {
      question: "Did you go out of your home on last working weekday",
      options: ["Yes", "No", "Denied"],
      field: "OUT_HOME"
    },
    dateOfTrip: {
      question: "Date of Trip",
      field: "DATE_OF_TRIP"
    },
    noOfTrips: {
      question: "How many times did you go out of your home on the date of trip mentioned above  (Please tell even if you went out for a walk/to buy milk/vegetables/provision stores/shopping, etc.)",
      field: "TRIP_TIMES"
    },
    RemarksPI: {
      question: "Remarks",
      field: "REMARKS_PI"
    }

  },
  pageFive: {
    title: "Trip Information",
    enumeratorAddress:
    {
      field: ['PAGE5_ENUMERATOR_LONG', 'PAGE5_ENUMERATOR_LAT']
    },
    tripNo: {
      question: "Trip Number",
      field: "TRIP_NO"
    },
    legTable: {
      head: ["Leg Number", "Mode of Travel", "Travel Time(in minutes)", "Fare paid/Travel cost (in Rupees)", "Actions"],
      legMode: {
        question: "Mode of travel",
        options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
        field: "LEG_MODE"
      },
      legTt: {
        question: "Travel Time(in minutes)",
        field: "LEG_TRAVEL_TIME"
      },
      legCost: {
        question: "Fare paid/Travel cost (in Rupees)",
        field: "LEG_COST"
      },
    },
    tripOriginAddress: {
      question: "Start Location",
      field: ['TRIP_ORIGIN_ADDRESS_LONG', 'TRIP_ORIGIN_ADDRESS_LAT', 'TRIP_ORIGIN_ADDRESS_TEXT']
    },
    tripStarttime: {
      question: "When did you leave home first in the day (on the date of trip mentioned above) (Start time of this Trip)",
      field: "TRIP_START_TIME"
    },
    tripDestinationAddress: {
      question: "Where did you go Destination of this Trip (Address) (Name of building/company/school/college, Street name/no., Area/Colony name, Landmark)",
      field: ['TRIP_DESTINATION_ADDRESS_LONG', 'TRIP_DESTINATION_ADDRESS_LAT', 'TRIP_DESTINATION_ADDRESS_TEXT']
    },
    tripMainPurpose: {
      question: "What was the main purpose of your trip to this trip Destn",
      options: ["Work/Business", "Return Home", "Employer Business/work related", "Education(School/College/University/Learning/Tuition/Trainer)", "Shopping(Milk,Water Can/groceries/Clothes/Household Appliances/Vehicles/Jewelery)", "Socialisation/Recreation/Leisure/Entertainment/movie/Sports Events/Visiting Relative OR Friends/Theatre/Drama/Marriage", "Relegious", "Household Maintenance(Fetching Water/Paying Bills/Bank/Dry Cleaners/Post-Office/Vehicle Maintenance-Repair/Pet Care", "Eat-Out/Meals/Snacks/Drinks", "Health/Medical and Personal Care(Kair-cut Salon/Spa/Beauty parlour of oneself or other persons)", "Pick-up/Drop-off/Escorting Children OR Adults(School/College/Sports/Dance or Music Classes)And other Than Healt Care Tips", "Social Service/ Community Service/Unpaid Voluntering", "Playing Sports/Exercise/Gym/Yoga Sessions/Swimming/Walking/Pet Walking", "Out of Station Travel"],
      field: "TRIP_MAIN_PURPOSE"
    },
    tripotherPurpose: {
      question: "Other than the main purpose, what else did you do at this trip Destn",
      options: ["Work/Business", "Return Home", "Employer Business/work related", "Education(School/College/University/Learning/Tuition/Trainer)", "Shopping(Milk,Water Can/groceries/Clothes/Household Appliances/Vehicles/Jewelery)", "Socialisation/Recreation/Leisure/Entertainment/movie/Sports Events/Visiting Relative OR Friends/Theatre/Drama/Marriage", "Relegious", "Household Maintenance(Fetching Water/Paying Bills/Bank/Dry Cleaners/Post-Office/Vehicle Maintenance-Repair/Pet Care", "Eat-Out/Meals/Snacks/Drinks", "Health/Medical and Personal Care(Kair-cut Salon/Spa/Beauty parlour of oneself or other persons)", "Pick-up/Drop-off/Escorting Children OR Adults(School/College/Sports/Dance or Music Classes)And other Than Healt Care Tips", "Social Service/ Community Service/Unpaid Voluntering", "Playing Sports/Exercise/Gym/Yoga Sessions/Swimming/Walking/Pet Walking", "Out of Station Travel", "NA"],
      field: "TRIP_OTHER_PURPOSE"
    },
    tripEndtime: {
      question: "When did you reach this trip Destn for the main purpose (End time of this Trip)",
      field: "TRIP_END_TIME"
    },
    tripTotaltime: {
      question: "So, the total time (door-to door) taken to reach from this trip Origin to Destn is (in minutes)",
      field: "TRIP_TOTAL_TIME"
    },
    tripDesttimeSpent: {
      question: "Time spent at this trip Destn (in minutes)",
      field: "TRIP_DEST_TIME_SPENT"
    },
    tripMainMode: {
      question: "What was the main mode of travel used to reach this trip Destn",
      options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "TRIP_MAIN_MODE"
    },
    riderType: {
      question: "Driver or passenger?",
      options: ["Driver", "Passenger"],
      field: "RIDER_TYPE"
    },
    tripFare: {
      question: "How much fare did you pay to reach this trip Destn (in Rupees) (not applicable for private vehicles)",
      field: "TRIP_FARE"
    },
    tripParking: {
      question: "Did you pay any parking cost ?",
      options: ["YES", "NO"],
      field: "TRIP_PARKING"
    },
    tripParkingCost: {
      question: "If yes, then how much did you pay? (in Rupees) (not applicable for transit)",
      field: "TRIP_PARKING_COST"
    },
    familyCount: "How many persons came with you",
    tripFamilyMemberCount: {
      question: "No. of family members",
      field: "FAMILY_MEMBERS"
    },
    tripNonFamilyMemberCount: {
      question: "No. of non family members",
      field: "NON_FAMILY_MEMBERS"
    },
    tripStopsDone: {
      question: "Did you stop anywhere to do something/activity (other than changing modes) on the way to this trip Destn?",
      options: ["YES", "NO"],
      field: "TRIP_STOPS"
    },
    tripStopCount: {
      question: "If yes, how many times did you stop before reaching this trip Destn?",
      field: "TRIP_STOP_COUNT"
    },
    nextTripNonTransit: {
      question: "If this Trip is a complete non-transit trip, then did you use any non-transit travel mode other than the main mode?",
      options: ["YES", "NO"],
      field: "NEXT_NON_TRANSIT"
    },
    nextTripNonTransitMode: {
      question: "If yes, please specify the other mode used:",
      options: ["Walk", "Bicycle", , "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "NEXT_NON_TRANSIT_MODE"
    },

  },
  pageSix: {
    title: "Trip Information",
    title1: "Trip Stop1",
    tripStop1Purpose: {
      question: "Purpose of 1st Stop",
      options: ["Work/Business", "Return Home", "Employer Business/work related", "Education(School/College/University/Learning/Tuition/Trainer)", "Shopping(Milk,Water Can/groceries/Clothes/Household Appliances/Vehicles/Jewelery)", "Socialisation/Recreation/Leisure/Entertainment/movie/Sports Events/Visiting Relative OR Friends/Theatre/Drama/Marriage", "Relegious", "Household Maintenance(Fetching Water/Paying Bills/Bank/Dry Cleaners/Post-Office/Vehicle Maintenance-Repair/Pet Care", "Eat-Out/Meals/Snacks/Drinks", "Health/Medical and Personal Care(Kair-cut Salon/Spa/Beauty parlour of oneself or other persons)", "Pick-up/Drop-off/Escorting Children OR Adults(School/College/Sports/Dance or Music Classes)And other Than Healt Care Tips", "Social Service/ Community Service/Unpaid Voluntering", "Playing Sports/Exercise/Gym/Yoga Sessions/Swimming/Walking/Pet Walking", "Out of Station Travel"],
      field: "TRIP1_PURPOSE"
    },
    tripStop1InTheRoute: {
      question: "Is the 1st Stop on the route to Destn.1?",
      options: ["Yes,On the Route", "No"],
      field: "TRIP1_IN_THE_ROUTE"
    },
    tripStop1AddlDistance: {
      question: "What is the additional distance did you travel?(Km)",
      field: "TRIP1_ADDITIONAL_DISTANCE"
    },
    tripStop1MainModeUsage: {
      question: "Is the travel mode used to reach 1st Stop same as the main mode ? ",
      options: [" Yes, Same as main mode", "No"],
      field: "TRIP1_MAIN_MODE_USAGE"
    },
    tripStop1OtherMode: {
      question: "What is the other mode used to reach 1st Stop?",
      options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "TRIP1_OTHER_MODE"
    },
    tripStop1Tt: {
      question: "How much travel time did you take to reach the 1 st Stop? (in minutes)",
      field: "TRIP1_TRAVEL_TIME"
    },
    tripStop1TimeSpent: {
      question: "How much time did you spend at the 1st Stop location? (in minutes)",
      field: "TRIP1_TIME_SPENT"
    },
    title2: "Trip Stop2",
    tripStop2Purpose: {
      question: "Purpose of 2nd Stop",
      options: ["Work/Business", "Return Home", "Employer Business/work related", "Education(School/College/University/Learning/Tuition/Trainer)", "Shopping(Milk,Water Can/groceries/Clothes/Household Appliances/Vehicles/Jewelery)", "Socialisation/Recreation/Leisure/Entertainment/movie/Sports Events/Visiting Relative OR Friends/Theatre/Drama/Marriage", "Relegious", "Household Maintenance(Fetching Water/Paying Bills/Bank/Dry Cleaners/Post-Office/Vehicle Maintenance-Repair/Pet Care", "Eat-Out/Meals/Snacks/Drinks", "Health/Medical and Personal Care(Kair-cut Salon/Spa/Beauty parlour of oneself or other persons)", "Pick-up/Drop-off/Escorting Children OR Adults(School/College/Sports/Dance or Music Classes)And other Than Healt Care Tips", "Social Service/ Community Service/Unpaid Voluntering", "Playing Sports/Exercise/Gym/Yoga Sessions/Swimming/Walking/Pet Walking", "Out of Station Travel"],
      field: "TRIP2_PURPOSE"
    },
    tripStop2InTheRoute: {
      question: "Is the 2nd Stop on the route to Destn.1?",
      options: ["Yes,On the Route", "No"],
      field: "TRIP2_IN_THE_ROUTE"
    },
    tripStop2AddlDistance: {
      question: "What is the additional distance you travelled?",
      field: "TRIP2_ADDITIONAL_DISTANCE"
    },
    tripStop2MainModeUsage: {
      question: "Is the travel mode used to reach 2nd Stop same as the main mode ? ",
      options: [" Yes, Same as main mode", "No"],
      field: "TRIP2_MAIN_MODE_USAGE"
    },
    tripStop2OtherMode: {
      question: "What is the other mode used to reach 2nd Stop?",
      options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "TRIP2_OTHER_MODE"
    },
    tripStop2Tt: {
      question: "How much travel time did you take to reach the 2nd Stop? (in minutes)",
      field: "TRIP2_TRAVEL_TIME"
    },
    tripStop2TimeSpent: {
      question: "How much time did you spend at the 2nd Stop location? (in minutes)",
      field: "TRIP2_TIME_SPENT"
    },
    tpNotHomeReason: {
      question: "Your destination of the last trip is not Home, Can you please specify the reason",
      field: "TP_NOT_HOME_REASON"
    },
    moreTrips: {
      question: "Have you made more trips on that day?",
      options: ["YES", "NO"],
      field: "MORE_TRIPS"
    },
    oosQuestion: "Have you made more trips on that day?",
    oosVillage: {
      question: "Name of the Village/City",
      field: "OOS_VILLAGE"
    },
    oosDistrict: {
      question: "Which District",
      field: "OOS_DISTRICT"
    },
    oosState: {
      question: "Which State",
      field: "OOS_STATE"
    },
    oosMode: {
      question: "What is the travel mode used for out-of-station travel?",
      options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "OOS_MODE"
    },
    sourceTransitStationAddress: {
      question: "From which transit station (bus stand/bus stop/railway station/airport) did you leave for out-of-station travel?",
      field: ['SOURCE_TRANSIT_STATION_LONG', 'SOURCE_TRANSIT_STATION_LAT', 'SOURCE_TRANSIT_STATION_ADDRESS',]

    },
    sourceTrasitStationMode: {
      question: "What is the travel mode used to reach the transit station (bus stand/bus stop/railway station/airport)?",
      options: ["Walk", "Bicycle", "Bus", "Metro", "Own Two Wheeler", "Own Car", "Auto-rickshaw", "App based two wheeler(bounce,vogo,etc..", "App based Car(Ola,Uber,etc..", "Office Car", "Rented car", "Car Pool/Bike Pool(With Friend/colleagues only)", "Train", "Airplane", "School Bus/Van", "Others"],
      field: "SOURCE_TRANSIT_STATION_MODE"
    }
  },
  enumeratorRegistration: {
    title: "Enumerator Registration",
    enumeratorName: {
      question: "Enumerator Name",
      field: "ENUMERATOR_NAME"
    },
    enumeratorEmail: {
      question: "Enumerator Email",
      field: "ENUMERATOR_EMAIL"
    },
    enumeratorUserRole: {
      question: "Enter User Role",
      field: "EUMERATOR_USER_ROLE"
    }
  },
  summary: {
    head: ["Person No.", "Gender", "Age(In Years)", "Occupation", "Any trips made on last working weekday?", "Number of Trips", "Trip Date", "Actions"],
    genderSummary: {
      question: "Gender",
      options: ["Male", "Female", "Other"],
      field: "GENDER_SUMMARY"
    },
    ageSummary: {
      question: "Age",
      options: ["5-12 years", "13-16 years", "17-18 years", "19-25 years", "26-35 years", "36-45 years", "46-60 years", "> 60 years"],
      field: "AGE_SUMMARY"
    },
    occupationSummary: {
      question: "Occupation",
      options: ["Unemployed", "Jobseeker", "Homemaker", "Student (full-time) ", "Student (part-time) ", "Employed_Govt. (full-time) ", "Employed_Govt. (part-time) ", "Employed_Private (full-time) ", "Employed_Private (part-time) ", "Self Employed", "Daily Wage", "Retired", "Others"],
      field: "OCCUPATION_SUMMARY"
    },
    tripMadeLWWDSummary: {
      question: "Did you go out of your home on last working weekday",
      options: ["Yes", "No", "Denied"],
      field: "TRIPS_MADE_LWWD_SUMMARY"
    },
    noOfTripsSummary: {
      question: "Number of Trips",
      field: "NO_OF_TRIPS_SUMMARY"
    },
    tripDateSummary: {
      question: "Trip Date",
      field: "TRIP_DATE_SUMMARY"
    },
  },
  tripSummary: {
    head: ["Trips", "Trip Origin Address", "Trip Destination Address", "Trip Main Purpose", "Trip Total Time", "Actions"],
  },
}
