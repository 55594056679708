// Default values of the survey variables
//The variables mentioned here are global variables and can be accessed across the survey

export const emptyState = {
    personNo: 1,
    tripNo:0,
    hhRefNo: "",
    //States to store the previous destination for the trip
    tripDest:"",
    tripDestLat:"",
    tripDestLong:"",
    isEditing: false,
    pageZero: {
        wardNo: 1,
        userRole:"",    
        enumeratorCode: "",
        surveyDate: "",
        locationCode: "",
        enumeratorLat: "",
        enumeratorLong: "",
    },
        pageOne: {
            enumeratorLat: "",
            enumeratorLong: "",
            houseAddressLat: "",
            houseAddressLong: "",
            houseAddresstext: "",
            phoneNumber: "",
            landlineNumber: "",
            houseType: "",
            houseOwnership: -10,
            houseSize: "",
            houseMRV: 0,
            totalNoOfPersons: 0,
            totalNoMale: 0,
            totalNoFemale: 0,
            otherGenderCount: 0,
            kidsBelowFive: 0,
            schoolStudent: 0,
            collegeStudent: 0,
            adultEmployees: 0,
            adultRetired: 0,
            adultUnemployed: 0,
            speciallyAbled: 0,
            currentPage: "HH-Page1"
        },

        pageTwo: {
            fourWheelerPetrol: 0,
            fourWheelerCNG: 0,
            fourWheelerElectric: 0,
            twoWheelerPetrol: 0,
            twoWheelerCNG: 0,
            twoWheelerElectric: 0,
            autoRickshawPetrol: 0,
            autoRickshawCNG: 0,
            autoRickshawElectric: 0,
            others: "",
            othersPetrol: 0,
            othersCNG: 0,
            othersElectric: 0,
            bicycle: 0,
            fourWheelerUsedAsTaxi: 0,
            fourWheelerEmployerProvided: 0,
            hhIncome: 0,
            noDailyDelivery: false,
            milk: false,
            newspaper: false,
            otherDailyDelivery: false,
            foodDeilvery: -10,
            groceryDelivery: -10,
            otherDelivery: -10,
            remarks:"",
            currentPage: "HH-Page2"
        },
    
    pageThree: {
        enumeratorLat: "",
        enumeratorLong: "",
        answeredOnBehalf:-10,
        gender: -10,//
        age: -10,//
        relationWithPerson1: -10,//
        education: -10,///
        occupation: -10,//
        profession: -10,//
        income:-10,//
        workplaceAddressLat: "",//
        workplaceAddressLong: "",//
        workplaceAddressText: "",//
        schoolAddressLat: "",//
        schoolAddressLong: "",//
        schoolAddressText: "",//
        employerProvidedTravelOption: -10,//
        daysWorkedFromHome: -10,//
        daysWorkedFromHomeBeforeCOVID: -10,//
        drivingLicense: -10,//
        starttime:0,//
        currentPage: "PI-Page3"
    },
    pageFour: {
        privatePublicNone: false,
        privatePublicTwoWheeler: false,
        privatePublicMetro: false,
        privatePublicOwnCar: false,
        privatePublicBicycle: false,
        privatePublicBMTC: false,
        privatePublicCarPool: false,
        intermediatePublicNone: false,
        intermediatePublicAutoRickshaw: false,
        intermediatePublicRideHailingTwoWheeler: false,
        intermediatePublicOfficeCabVan: false,
        intermediatePublicRideHailingCar: false,
        intermediatePublicTaxi: false,
        intermediatePublicRentalVehicle: false,
        transitPassesNone: false,
        transitPassesDailyBus: false,
        transitPassesWeeklyBus: false,
        transitPassesMonthlyBus: false,
        transitPassesStudent: false,
        transitPassesMetro: false,
        transitPassesPremiumVajra: false,
        transitPassesVayuVajra: false,
        transitPassesOther: false,
        workTravel: 0,
        workTravelBeforeCOVID: 0,
        outHome: -10,
        dateOfTrip: "",
        noOfTrips: 0,
        remarksPI:"",
        currentPage: "PI-Page2"
    },
    timeSpentInTI:0,
    pageFive: {
        enumeratorLat: "",
        enumeratorLong: "",
        legNo:0,
        legs:[],
        legMode:-10,
        legTt:0,
        legCost:0,
        index:-10,
        tripOriginAddress: "",
        tripOriginLat: "",
        tripOriginLong: "",
        tripStartTime: "",
        tripDestAddress: "",
        tripDestLat: "",
        tripDestLong: "",
        tripMainPurpose: "",
        tripotherPurpose: "",
        tripEndtime: "",
        tripTotaltime: 0,
        tripDesttimeSpent: 0,
        tripMainMode:"",
        riderType:-10,
        tripFare: 0,
        tripParking:-10,
        tripParkingCost: 0,
        tripFamilyMbrCount: 0, 
        tripNonFamilyMbrCount: 0, 
        tripStopsDone: 0,
        tripStopCount: 0,
        nextTripNonTransitModeUsage: -10, 
        nextTripNonTransitMode: -10,
        currentPage: "TI-Page1"
    },
    pageSix:{
        tripStop1Purpose:-10,
        tripStop1InTheRoute:-10,
        tripStop1AddlDistance:0,
        tripStop1MainModeUsage:-10,
        tripStop1OtherMode:-10,
        tripStop1Tt:0,
        tripStop1TimeSpent:0,
        tripStop2Purpose:-10,
        tripStop2InTheRoute:-10,
        tripStop2AddlDistance:0,
        tripStop2MainModeUsage:-10,
        tripStop2OtherMode:-10,
        tripStop2Tt:0,
        tripStop2TimeSpent:0,
        tpNotHomeReason:"",
        moreTrips:-10,
        oosVillage:"",
        oosDistrict:"",
        oosState:"",
        oosMode:-10,
        sourceTransitStationAddress:"",
        sourceTransitStationLat:"",
        sourceTransitStationLong:"",
        sourceTrasitStationMode:-10,
        currentPage: "TI-Page2"
    },
    enumeratorRegistration:{
        enumeratorName:"",
        enumeratorEmail:"",
        userRole:""
    },
    summary:{
        personNumberSummary:"",
        genderSummary:"",
        ageSummary:"",
        occupationSummary:"",
        tripMadeLWWDSummary:"",
        noOfTripsSummary:"",
        tripDateSummary:"",
    },
    tripSummary:{
        currentPersonNo:"",
    }
};
let initialState;
// If a certain field is empty, check if value is present in localStorage
// let initialState = emptyState
if (JSON.parse(localStorage.getItem("localState"))) initialState = JSON.parse(localStorage.getItem("localState"))
else initialState = emptyState

export default initialState
