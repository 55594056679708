import React, { useEffect } from "react";
import RadioInput from "../Components/RadioInput";
import { StateContext } from "../contexts/StateContext";
import { useContext } from "react";
// import Search from "../Components/Search";
import { QuestionData } from "../Data/QuestionData";
import { Link, useNavigate } from "react-router-dom";
// import Auto from "../Components/Auto"
import TextInput from "../Components/TextInput";
import NumInput from "../Components/NumInput";
import { verifyCarsUsedAsTaxi, verifyCarsEmployerProvided } from "../Utils/SurveyChecks";
import CheckBox from "../Components/CheckBoxInput";
import fetchData from '../contexts/API';
import toast from "react-hot-toast";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


// main function

export default function Page2() {

    const navigate = useNavigate();
    const { state, handleInputChange, handleIsEditing, flushPersonInfo } = useContext(StateContext);
    useEffect(() => {
        document.title = "Page2 | Travel Demand Digital Survey Tool"
    }, [])
    const apiUrl = '/page2';
    const postData = {
        personNo: state.personNo,
        fourWheelerPetrol: state.pageTwo.fourWheelerPetrol,
        fourWheelerCNG: state.pageTwo.fourWheelerCNG,
        fourWheelerElectric: state.pageTwo.fourWheelerElectric,
        twoWheelerPetrol: state.pageTwo.twoWheelerPetrol,
        twoWheelerCNG: state.pageTwo.twoWheelerCNG,
        twoWheelerElectric: state.pageTwo.twoWheelerElectric,
        autoRickshawPetrol: state.pageTwo.autoRickshawPetrol,
        autoRickshawCNG: state.pageTwo.autoRickshawCNG,
        autoRickshawElectric: state.pageTwo.autoRickshawElectric,
        others: state.pageTwo.others,
        othersPetrol: state.pageTwo.othersPetrol,
        othersCNG: state.pageTwo.othersCNG,
        othersElectric: state.pageTwo.othersElectric,
        bicycle: state.pageTwo.bicycle,
        fourWheelerUsedAsTaxi: state.pageTwo.fourWheelerUsedAsTaxi,
        fourWheelerEmployerProvided: state.pageTwo.fourWheelerEmployerProvided,
        hhMonthlyIncome: state.pageTwo.hhIncome,
        noDailyDelivery: state.pageTwo.noDailyDelivery,
        milk: state.pageTwo.milk,
        newspaper: state.pageTwo.newspaper,
        otherDailyDelivery: state.pageTwo.otherDailyDelivery,
        food: state.pageTwo.foodDeilvery,
        grocery: state.pageTwo.groceryDelivery,
        otherDelivery: state.pageTwo.otherDelivery,
        remarks: state.pageTwo.remarks,
        // currentPage: state.pageTwo.currentPage,
        hhRefNo: state.hhRefNo,
        hhCp2Time: ""
    };



    const {
        fourWheelerPetrol,
        fourWheelerCNG,
        fourWheelerElectric,
        fourWheelerUsedAsTaxi,
        fourWheelerEmployerProvided
    } = state.pageTwo;

    var carsUsedAsTaxiCheck = verifyCarsUsedAsTaxi(fourWheelerPetrol, fourWheelerCNG, fourWheelerElectric, fourWheelerUsedAsTaxi);
    var carsEmployerProvidedCheck = verifyCarsEmployerProvided(fourWheelerPetrol, fourWheelerCNG, fourWheelerElectric, fourWheelerEmployerProvided);

    const handleClickNext = (event) => {
        event.preventDefault();
        if (!carsUsedAsTaxiCheck)
            toast.error("Invalid entry sum of all cars used as taxi should be less than or equal to Total no of cars")

        else if (!carsEmployerProvidedCheck)
            toast.error("Invalid entry sum of all cars employer provided should be less than or equal to Total no of cars")

        else {
            postData.hhCp2Time = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                fractionalSecondDigits: 3,
                timeZone: 'IST'
            }).format(Date.now())
            fetchData(apiUrl, postData)
                .then((responseData) => {
                    if (responseData.status == 1) {
                        handleIsEditing(false)
                        flushPersonInfo()
                        navigate('/page3');
                        toast.success("Household Information saved! Do not press back!")
                        // toast.success("Household Information saved! Do not press back!")
                    }
                    else toast.error(responseData.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        }// navigate('/Page3')
    }

    return (
        <form onSubmit={handleClickNext}>
            <div className='flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4 px-5'>
                <div className='w-full py-[2%] font-semibold'>{QuestionData.pageTwo.title}</div>
                <div className='w-full p-[1%] font-semibold'>{QuestionData.pageTwo.fourWheeler.question}</div>
                <div className="flex w-full justify-start items-center">
                    <NumInput
                        questionData={QuestionData.pageTwo.fourWheeler.petrolDiesel}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.fourWheelerPetrol}
                        width={"33%"}
                        autoFocus={true}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.fourWheeler.cng}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.fourWheelerCNG}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.fourWheeler.electric}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.fourWheelerElectric}
                        width={"33%"}
                    />

                </div>
                <div className='w-full p-[1%] font-semibold'>{QuestionData.pageTwo.twoWheeler.question}</div>
                <div className="flex justify-start items-center">
                    <NumInput
                        questionData={QuestionData.pageTwo.twoWheeler.petrolDiesel}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.twoWheelerPetrol}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.twoWheeler.cng}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.twoWheelerCNG}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.twoWheeler.electric}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.twoWheelerElectric}
                        width={"33%"}
                    />
                </div>
                <div className='p-[1%] font-semibold'>{QuestionData.pageTwo.autoRickshaw.question}</div>
                <div className="flex justify-start items-center">
                    <NumInput
                        questionData={QuestionData.pageTwo.autoRickshaw.petrolDiesel}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.autoRickshawPetrol}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.autoRickshaw.cng}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.autoRickshawCNG}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.autoRickshaw.electric}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.autoRickshawElectric}
                        width={"33%"}
                    />
                </div>
                <div className="flex p-[1%]  w-full justify-start items-center">
                    <TextInput
                        questionData={QuestionData.pageTwo.others.main}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.others}
                    />
                </div>
                <div className="flex justify-start items-center">
                    <NumInput
                        questionData={QuestionData.pageTwo.others.petrolDiesel}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.othersPetrol}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.others.cng}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.othersCNG}
                        width={"33%"}
                    />
                    <NumInput
                        questionData={QuestionData.pageTwo.others.electric}
                        handleInputChange={handleInputChange}
                        value={state.pageTwo.othersElectric}
                        width={"33%"}
                    />
                </div>
                <NumInput
                    questionData={QuestionData.pageTwo.bicycle}
                    handleInputChange={handleInputChange}
                    value={state.pageTwo.bicycle}
                    width={"50%"}
                />
                <NumInput
                    questionData={QuestionData.pageTwo.fourWheelerUsedAsTaxi}
                    handleInputChange={handleInputChange}
                    value={state.pageTwo.fourWheelerUsedAsTaxi}
                    required={true}
                    width={"50%"}
                />
                <NumInput
                    questionData={QuestionData.pageTwo.fourWheelerEmployerProvided}
                    handleInputChange={handleInputChange}
                    value={state.pageTwo.fourWheelerEmployerProvided}
                    required={true}
                    width={"50%"}
                />
                <RadioInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageTwo.hhIncome}
                    value={state.pageTwo.hhIncome}
                    handleInputChange={handleInputChange}
                />
                {/* </div>
            <div className="flex flex-col w-full justify-center align-center bg-white rounded-lg mt-4"> */}
                <CheckBox
                    questionData={QuestionData.pageTwo.dailyDelivery}
                    handleInputChange={handleInputChange}
                    value={[state.pageTwo.milk, state.pageTwo.newspaper, state.pageTwo.otherDailyDelivery]}
                    noneOfAbove={state.pageTwo.noDailyDelivery}
                />

                <RadioInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageTwo.food}
                    value={state.pageTwo.foodDeilvery}
                    handleInputChange={handleInputChange}
                />
                <RadioInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageTwo.Groceries}
                    value={state.pageTwo.groceryDelivery}
                    handleInputChange={handleInputChange}
                />
                <RadioInput bgcolor={"bg-slate-200"}
                    questionData={QuestionData.pageTwo.Others}
                    value={state.pageTwo.otherDelivery}
                    handleInputChange={handleInputChange}
                />
                <TextInput
                    questionData={QuestionData.pageTwo.Remarks}
                    handleInputChange={handleInputChange}
                    value={state.pageTwo.remarks}

                />
                {/* <CheckBox
                    questionData={QuestionData.pageTwo.otherDelivery}
                    handleInputChange={handleInputChange}
                    value={[state.pageTwo.foodDeilvery, state.pageTwo.groceryDelivery, state.pageTwo.otherDelivery]}
                /> */}
            </div>
            <div className="flex flex-1 justify-between items-center">
                <Link to={'/page1'} className="bg-other hover:bg-slate-900 text-white py-2 px-2 rounded my-8 flex flex-row-reverse justify-between items-center gap-x-3">
                    Back <FaArrowLeft />
                </Link>
                <button type='submit' className="bg-other hover:bg-slate-900 text-white flex justify-between items-center gap-x-3  py-2 px-2 rounded my-8">
                    Add Person <FaArrowRight />
                </button>
            </div>
        </form>
    )

}



