// Types of all actions for the dispatch function
// These are used in the reducer function to update the state
// The dispatch function is passed to the components that need to update the state
const StateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TRIP_NO":
      return {
        ...state,
        tripNo: action.payload,
      };
    case "UPDATE_PERSON_NO":
      return {
        ...state,
        personNo: action.payload
      };
    case "UPDATE_LEG_NO":
      return {
        ...state,
        legNo: action.payload
      };
    case "UPDATE_TRIP_DEST":
      return {
        ...state,

        tripDest: action.payload
      };
    case "UPDATE_TRIP_DEST_LAT":
      return {
        ...state,

        tripDestLat: action.payload
      };
    case "UPDATE_TRIP_DEST_LONG":
      return {
        ...state,

        tripDestLong: action.payload
      };

    case "UPDATE_HH_REF_NO":
      return {
        ...state,
        hhRefNo: action.payload
      };
    case "UPDATE_WARD_NO":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          wardNo: action.payload
        }
      };
    case "UPDATE_USER_ROLE":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          userRole: action.payload
        }
      };
    case "UPDATE_ENUMERATOR_CODE":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          enumeratorCode: action.payload
        }
      };
    case "UPDATE_SURVEY_DATE":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          surveyDate: action.payload
        }
      }
    case "UPDATE_LOCATION_CODE":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          locationCode: action.payload
        }
      };
    case "UPDATE_ENUMERATOR_LAT":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          enumeratorLat: action.payload
        }
      };
    case "UPDATE_ENUMERATOR_LONG":
      return {
        ...state,
        pageZero: {
          ...state.pageZero,
          enumeratorLong: action.payload
        }
      };
    case "UPDATE_PAGE1_ENUMERATOR_LAT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          enumeratorLat: action.payload
        }
      };
    case "UPDATE_PAGE1_ENUMERATOR_LONG":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          enumeratorLong: action.payload
        }
      };
    case "UPDATE_ADDRESS_TEXT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseAddresstext: action.payload
        }
      };
    case "UPDATE_ADDRESS_LONG":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseAddressLong: action.payload
        }
      };
    case "UPDATE_ADDRESS_LAT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseAddressLat: action.payload
        }
      };
    case "UPDATE_PHONE_NUMBER":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          phoneNumber: action.payload
        }
      };
    case "UPDATE_LANDLINE_NUMBER":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          landlineNumber: action.payload
        }
      };
    case "UPDATE_HOUSE_OWNERSHIP":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseOwnership: action.payload
        }
      };
    case "UPDATE_HOUSE_TYPE":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseType: action.payload
        }
      };
    case "UPDATE_HOUSE_SIZE":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseSize: action.payload
        }
      };
    case "UPDATE_MONTHLY_RENTAL":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseMRV: action.payload
        }
      };
    case "UPDATE_TOTAL_NO_OF_PERSONS":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          totalNoOfPersons: action.payload
        }
      };
    case "UPDATE_TOTAL_NO_MALE":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          totalNoMale: action.payload
        }
      };
    case "UPDATE_TOTAL_NO_FEMALE":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          totalNoFemale: action.payload
        }
      };
    case "UPDATE_OTHER_GENDER_COUNT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          otherGenderCount: action.payload
        }
      };
    case "UPDATE_KIDS_BELOW_FIVE":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          kidsBelowFive: action.payload
        }
      };
    case "UPDATE_SCHOOL_STUDENT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          schoolStudent: action.payload
        }
      };
    case "UPDATE_COLLEGE_STUDENT":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          collegeStudent: action.payload
        }
      };
    case "UPDATE_ADULT_EMPLOYEES":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          adultEmployees: action.payload
        }
      };
    case "UPDATE_ADULT_RETIRED":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          adultRetired: action.payload
        }
      };
    case "UPDATE_ADULT_UNEMPLOYED":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          adultUnemployed: action.payload
        }
      };
    case "UPDATE_SPECIALY_ABLED":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          speciallyAbled: action.payload
        }
      };
    case "UPDATE_FOUR_WHEELER_PETROL":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          fourWheelerPetrol: action.payload
        }
      };
    case "UPDATE_FOUR_WHEELER_CNG":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          fourWheelerCNG: action.payload
        }
      };
    case "UPDATE_FOUR_WHEELER_ELECTRIC":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          fourWheelerElectric: action.payload
        }
      };
    case "UPDATE_TWO_WHEELER_PETROL":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          twoWheelerPetrol: action.payload
        }
      };
    case "UPDATE_TWO_WHEELER_CNG":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          twoWheelerCNG: action.payload
        }
      };
    case "UPDATE_TWO_WHEELER_ELECTRIC":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          twoWheelerElectric: action.payload
        }
      };
    case "UPDATE_AUTO_RICKSHAW_PETROL":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          autoRickshawPetrol: action.payload
        }
      };
    case "UPDATE_AUTO_RICKSHAW_CNG":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          autoRickshawCNG: action.payload
        }
      };
    case "UPDATE_AUTO_RICKSHAW_ELECTRIC":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          autoRickshawElectric: action.payload
        }
      };
    case "UPDATE_OTHERS":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          others: action.payload
        }
      };
    case "UPDATE_OTHERS_PETROL":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          othersPetrol: action.payload
        }
      };
    case "UPDATE_OTHERS_CNG":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          othersCNG: action.payload
        }
      };
    case "UPDATE_OTHERS_ELECTRIC":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          othersElectric: action.payload
        }
      };
    case "UPDATE_BICYCLE":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          bicycle: action.payload
        }
      };
    case "UPDATE_FOUR_WHEELER_USED_AS_TAXI":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          fourWheelerUsedAsTaxi: action.payload
        }
      };
    case "UPDATE_FOUR_WHEELER_EMPLOYER_PROVIDED":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          fourWheelerEmployerProvided: action.payload
        }
      };
    case "UPDATE_HH_INCOME":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          hhIncome: action.payload
        }
      };
    case "UPDATE_NO_DAILY_DELIVERY":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          noDailyDelivery: action.payload
        }
      };
    case "UPDATE_MILK":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          milk: action.payload
        }
      };
    case "UPDATE_NEWSPAPER":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          newspaper: action.payload
        }
      };
    case "UPDATE_OTHER_DAILY_DELIVERY":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          otherDailyDelivery: action.payload
        }
      };
    case "UPDATE_FOOD_DELIVERY":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          foodDeilvery: action.payload
        }
      };
    case "UPDATE_GROCERY_DELIVERY":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          groceryDelivery: action.payload
        }
      };
    case "UPDATE_OTHER_DELIVERY":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          otherDelivery: action.payload
        }
      };
    case "UPDATE_REMARKS":
      return {
        ...state,
        pageTwo: {
          ...state.pageTwo,
          remarks: action.payload
        }
      };
    case "UPDATE_PAGE3_ENUMERATOR_LAT":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          enumeratorLat: action.payload
        }
      };
    case "UPDATE_PAGE3_ENUMERATOR_LONG":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          enumeratorLong: action.payload
        }
      };
    case "UPDATE_ANSWERED_ON_BEHALF":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          answeredOnBehalf: action.payload
        }
      };
    case "UPDATE_GENDER":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          gender: action.payload
        }
      };
    case "UPDATE_AGE":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          age: action.payload
        }
      };
    case "UPDATE_RELATION_WITH_PERSON1":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          relationWithPerson1: action.payload
        }
      };
    case "UPDATE_EDUCATION":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          education: action.payload
        }
      };
    case "UPDATE_OCCUPATION":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          occupation: action.payload
        }
      };
    case "UPDATE_PROFESSION":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          profession: action.payload
        }
      };
    case "UPDATE_INCOME":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          income: action.payload
        }
      };
    case "UPDATE_WORKPLACE_ADDRESS_LONG":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          workplaceAddressLong: action.payload
        }
      };
    case "UPDATE_WORKPLACE_ADDRESS_LAT":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          workplaceAddressLat: action.payload
        }
      };
    case "UPDATE_WORKPLACE_ADDRESS_NAME":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          workplaceAddressText: action.payload
        }
      };
    case "UPDATE_SCHOOL_ADDRESS_LONG":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          schoolAddressLong: action.payload
        }
      };
    case "UPDATE_SCHOOL_ADDRESS_LAT":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          schoolAddressLat: action.payload
        }
      };
    case "UPDATE_SCHOOL_ADDRESS_NAME":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          schoolAddressText: action.payload
        }
      };
    case "UPDATE_EMPLOYER_TRAVEL_OPTION":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          employerProvidedTravelOption: action.payload
        }
      };
    case "UPDATE_DAYS_WORKED_FROM_HOME":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          daysWorkedFromHome: action.payload
        }
      };
    case "UPDATE_BEFORE_COVID":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          daysWorkedFromHomeBeforeCOVID: action.payload
        }
      };
    case "UPDATE_DRIVING_LICENSE":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          drivingLicense: action.payload
        }
      };
    case "UPDATE_START_TIME":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          starttime: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_NONE":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicNone: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_OWN_TWO_WHEELER":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicTwoWheeler: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_METRO_TRAIN":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicMetro: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_OWN_CAR":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicOwnCar: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_BICYCLE":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicBicycle: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_BMTC_BUS":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicBMTC: action.payload
        }
      };
    case "UPDATE_PRIVATE_PUBLIC_CARPOOL_BIKE_POOL":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          privatePublicCarPool: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_NONE":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicNone: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_AUTO_RICKSHAW":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicAutoRickshaw: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_RIDE_HAILING_TWO_WHEELER":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicRideHailingTwoWheeler: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_OFFICE_CAB_VAN":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicOfficeCabVan: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_RIDE_HAILING_CAR":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicRideHailingCar: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_TAXI":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicTaxi: action.payload
        }
      };
    case "UPDATE_INTERMEDIATE_PUBLIC_RENTAL_VEHICLE":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          intermediatePublicRentalVehicle: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_NONE":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesNone: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_DAILY_BUS":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesDailyBus: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_WEEKLY_BUS":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesWeeklyBus: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_MONTHLY_BUS":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesMonthlyBus: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_STUDENT":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesStudent: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_METRO":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesMetro: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_PREMIUM_VAJRA":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesPremiumVajra: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_VAYU_VAJRA":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesVayuVajra: action.payload
        }
      };
    case "UPDATE_TRANSIT_PASSES_OTHER":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          transitPassesOther: action.payload
        }
      };
    case "UPDATE_WORK_TRAVEL":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          workTravel: action.payload
        }
      };
    case "UPDATE_WORK_TRAVEL_BEFORE_COVID":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          workTravelBeforeCOVID: action.payload
        }
      };
    case "UPDATE_OUT_HOME":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          outHome: action.payload
        }
      };
    case "UPDATE_DATE_OF_TRIP":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          dateOfTrip: action.payload
        }
      };
    case "UPDATE_TRIP_TIMES":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          noOfTrips: action.payload
        }
      };
    case "UPDATE_REMARKS_PI":
      return {
        ...state,
        pageFour: {
          ...state.pageFour,
          remarksPI: action.payload
        }
      };
    case "UPDATE_TIME_SPENT_IN_TI":
      return {
        ...state,
        timeSpentInTI: state.timeSpentInTI + action.payload
      };
    case "UPDATE_PAGE5_ENUMERATOR_LAT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          enumeratorLat: action.payload
        }
      };
    case "UPDATE_PAGE5_ENUMERATOR_LONG":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          enumeratorLong: action.payload
        }
      };
    case "APPEND_LEGS":
      const newLeg = {
        ...action.payload,
        legNo: state.pageFive.legs.length + 1,
      };
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legs: [...state.pageFive.legs, newLeg],
        },
      };

    case "REMOVE_LEG":
      const removedIndex = action.payload;
      const updatedLegs = state.pageFive.legs.filter((_, index) => index !== removedIndex);
      const updatedLegsWithLegNo = updatedLegs.map((leg, index) => ({
        ...leg,
        "legNo": index + 1,
      }));
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legs: updatedLegsWithLegNo,
        },
      };
    case "EDIT_LEG":
      const { index, values } = action.payload;
      const updatedsLegs = state.pageFive.legs.map((leg, i) => {
        if (i === index) {
          // Edit the specified keys in the dictionary
          return { ...leg, ...values };
        }
        return leg;
      });
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legs: updatedsLegs,
        },
      };
    case "UPDATE_LEG_MODE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legMode: action.payload,
        },
      };
    case "UPDATE_LEG_TRAVEL_TIME":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legTt: action.payload,
        },
      };
    case "UPDATE_LEG_COST":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legCost: action.payload,
        },
      };
    case "UPDATE_INDEX":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          index: action.payload,
        },
      };
    case "UPDATE_TRIP_ORIGIN_ADDRESS_TEXT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripOriginAddress: action.payload,
        },
      };
    case "UPDATE_TRIP_ORIGIN_ADDRESS_LONG":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripOriginLong: action.payload,
        },
      };
    case "UPDATE_TRIP_ORIGIN_ADDRESS_LAT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripOriginLat: action.payload,
        },
      };
    case "UPDATE_TRIP_START_TIME":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripStartTime: action.payload,
        },
      };

    case "UPDATE_TRIP_DESTINATION_ADDRESS_TEXT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripDestAddress: action.payload,
        },
      };

    case "UPDATE_TRIP_DESTINATION_ADDRESS_LONG":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripDestLong: action.payload,
        },
      };
    case "UPDATE_TRIP_DESTINATION_ADDRESS_LAT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripDestLat: action.payload,
        },
      };

    case "UPDATE_TRIP_MAIN_PURPOSE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripMainPurpose: action.payload,
        },
      };

    case "UPDATE_TRIP_OTHER_PURPOSE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripotherPurpose: action.payload,
        },
      };

    case "UPDATE_TRIP_END_TIME":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripEndtime: action.payload,
        },
      };

    case "UPDATE_TRIP_TOTAL_TIME":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripTotaltime: action.payload,
        },
      };

    case "UPDATE_TRIP_DEST_TIME_SPENT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripDesttimeSpent: action.payload,
        },
      };

    case "UPDATE_TRIP_MAIN_MODE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripMainMode: action.payload,
        },
      };

    case "UPDATE_RIDER_TYPE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          riderType: action.payload,
        },
      };

    case "UPDATE_TRIP_FARE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripFare: action.payload,
        },
      };

    case "UPDATE_TRIP_PARKING":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripParking: action.payload,
        },
      };

    case "UPDATE_TRIP_PARKING_COST":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripParkingCost: action.payload,
        },
      };

    case "UPDATE_FAMILY_MEMBERS":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripFamilyMbrCount: action.payload,
        },
      };

    case "UPDATE_NON_FAMILY_MEMBERS":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripNonFamilyMbrCount: action.payload,
        },
      };

    case "UPDATE_TRIP_STOPS":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripStopsDone: action.payload,
        },
      };

    case "UPDATE_TRIP_STOP_COUNT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          tripStopCount: action.payload,
        },
      };

    case "UPDATE_NEXT_NON_TRANSIT":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          nextTripNonTransitModeUsage: action.payload,
        },
      };

    case "UPDATE_NEXT_NON_TRANSIT_MODE":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          nextTripNonTransitMode: action.payload,
        },
      };
    case "UPDATE_TRIP1_PURPOSE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1Purpose: action.payload,
        },
      };
    case "UPDATE_TRIP1_IN_THE_ROUTE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1InTheRoute: action.payload,
        },
      };

    case "UPDATE_TRIP1_ADDITIONAL_DISTANCE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1AddlDistance: action.payload,
        },
      };

    case "UPDATE_TRIP1_MAIN_MODE_USAGE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1MainModeUsage: action.payload,
        },
      };

    case "UPDATE_TRIP1_OTHER_MODE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1OtherMode: action.payload,
        },
      };

    case "UPDATE_TRIP1_TRAVEL_TIME":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1Tt: action.payload,
        },
      };

    case "UPDATE_TRIP1_TIME_SPENT":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop1TimeSpent: action.payload,
        },
      };

    case "UPDATE_TRIP2_PURPOSE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2Purpose: action.payload,
        },
      };

    case "UPDATE_TRIP2_IN_THE_ROUTE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2InTheRoute: action.payload,
        },
      };

    case "UPDATE_TRIP2_ADDITIONAL_DISTANCE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2AddlDistance: action.payload,
        },
      };

    case "UPDATE_TRIP2_MAIN_MODE_USAGE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2MainModeUsage: action.payload,
        },
      };

    case "UPDATE_TRIP2_OTHER_MODE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2OtherMode: action.payload,
        },
      };

    case "UPDATE_TRIP2_TRAVEL_TIME":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2Tt: action.payload,
        },
      };

    case "UPDATE_TRIP2_TIME_SPENT":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tripStop2TimeSpent: action.payload,
        },
      };

    case "UPDATE_TP_NOT_HOME_REASON":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          tpNotHomeReason: action.payload,
        },
      };

    case "UPDATE_MORE_TRIPS":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          moreTrips: action.payload,
        },
      };

    case "UPDATE_OOS_VILLAGE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          oosVillage: action.payload,
        },
      };

    case "UPDATE_OOS_DISTRICT":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          oosDistrict: action.payload,
        },
      };

    case "UPDATE_OOS_STATE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          oosState: action.payload,
        },
      };

    case "UPDATE_OOS_MODE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          oosMode: action.payload,
        },
      };

    case "UPDATE_SOURCE_TRANSIT_STATION_ADDRESS":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          sourceTransitStationAddress: action.payload,
        },
      };

    case "UPDATE_SOURCE_TRANSIT_STATION_LONG":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          sourceTransitStationLong: action.payload,
        },
      };
    case "UPDATE_SOURCE_TRANSIT_STATION_LAT":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          sourceTransitStationLat: action.payload,
        },
      };

    case "UPDATE_SOURCE_TRANSIT_STATION_MODE":
      return {
        ...state,
        pageSix: {
          ...state.pageSix,
          sourceTrasitStationMode: action.payload,
        },
      };

    case "UPDATE_ENUMERATOR_NAME":
      return {
        ...state,
        enumeratorRegistration: {
          ...state.enumeratorRegistration,
          enumeratorName: action.payload,
        },
      };

    case "UPDATE_ENUMERATOR_EMAIL":
      return {
        ...state,
        enumeratorRegistration: {
          ...state.enumeratorRegistration,
          enumeratorEmail: action.payload,
        },
      };

    case "UPDATE_EUMERATOR_USER_ROLE":
      return {
        ...state,
        enumeratorRegistration: {
          ...state.enumeratorRegistration,
          userRole: action.payload,
        },
      };

    case "UPDATE_GENDER_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          genderSummary: action.payload,
        },
      };

    case "UPDATE_AGE_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          ageSummary: action.payload,
        },
      };

    case "UPDATE_OCCUPATION_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          occupationSummary: action.payload,
        },
      };

    case "UPDATE_TRIPS_MADE_LWWD_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          tripMadeLWWDSummary: action.payload,
        },
      };

    case "UPDATE_NO_OF_TRIPS_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          noOfTripsSummary: action.payload,
        },
      };

    case "UPDATE_TRIP_DATE_SUMMARY":
      return {
        ...state,
        summary: {
          ...state.summary,
          tripDateSummary: action.payload,
        },
      };
    case "UPDATE_CURRENT_PERSON_NO":
      return {
        ...state,
        tripSummary: {
          ...state.tripSummary,
          currentPersonNo: action.payload
        }
      };
    case "EDIT_PERSON_INFO":
      return {
        ...state,
        pageThree: {
          ...state.pageThree,
          answeredOnBehalf: action.payload.data.answered_on_behalf,
          gender: action.payload.data.gender,
          age: action.payload.data.person_age,
          relationWithPerson1: action.payload.data.relation,
          education: action.payload.data.education,
          occupation: action.payload.data.occupation,
          profession: action.payload.data.profession,
          income: action.payload.data.monthly_income,
          workplaceAddressLat: action.payload.data.workplace_address_lat,
          workplaceAddressLong: action.payload.data.workplace_address_long,
          workplaceAddressText: action.payload.data.workplace_address_text,
          schoolAddressLat: action.payload.data.school_address_lat,
          schoolAddressLong: action.payload.data.school_address_long,
          schoolAddressText: action.payload.data.school_address_text,
          employerProvidedTravelOption: action.payload.data.emp_travel_option,
          daysWorkedFromHome: action.payload.data.wfh_days,
          daysWorkedFromHomeBeforeCOVID: action.payload.data.before_covid_wfh_days,
          drivingLicense: action.payload.data.driving_licence
        },
        pageFour: {
          ...state.pageFour,
          privatePublicTwoWheeler: action.payload.data.own_two_wheeler,
          privatePublicOwnCar: action.payload.data.own_car,
          privatePublicBMTC: action.payload.data.bmtc_bus,
          privatePublicMetro: action.payload.data.metro,
          privatePublicBicycle: action.payload.data.bicycle,
          privatePublicCarPool: action.payload.data.carpool_or_bikepool,
          intermediatePublicAutoRickshaw: action.payload.data.auto_rickshaw,
          intermediatePublicOfficeCabVan: action.payload.data.office_cab,
          intermediatePublicTaxi: action.payload.data.taxi,
          intermediatePublicRideHailingTwoWheeler: action.payload.data.ride_hailing_bike,
          intermediatePublicRideHailingCar: action.payload.data.ride_hailing_car,
          intermediatePublicRentalVehicle: action.payload.data.rental_vehicle,
          transitPassesDailyBus: action.payload.data.daily_bus_pass,
          transitPassesWeeklyBus: action.payload.data.weekly_bus_pass,
          transitPassesMonthlyBus: action.payload.data.monthly_bus_pass,
          transitPassesStudent: action.payload.data.student_bus,
          transitPassesPremiumVajra: action.payload.data.premium_vajra_pass,
          transitPassesVayuVajra: action.payload.data.vayu_vajra_gold_pass,
          transitPassesMetro: action.payload.data.metro_pass,
          transitPassesOther: action.payload.data.other_types_of_pass,
          transitPassesNone: action.payload.data.not_used_any_pass,
          workTravel: action.payload.data.wd_avg_tt,
          workTravelBeforeCOVID: action.payload.data.wd_avg_tt_pre_covid,
          outHome: action.payload.data.working_weekday,
          tripDate: action.payload.data.trip_date,
          numberOfTrips: action.payload.data.number_of_trips,
          remarksPI: action.payload.data.remarks
        },
      }
    case "FLUSH_PERSON_SUMMARY":
      return {
        ...state,
        summary: {
          personNumberSummary: "",
          genderSummary: "",
          ageSummary: "",
          occupationSummary: "",
          tripMadeLWWDSummary: "",
          noOfTripsSummary: "",
          tripDateSummary: "",
        }
      }
    case "FLUSH_HH_INFO":
      return {
        ...state,
        pageOne: {
          ...state.pageOne,
          houseAddressLat: "",
          houseAddressLong: "",
          houseAddresstext: "",
          phoneNumber: "",
          landlineNumber: "",
          houseType: "",
          houseOwnership: -10,
          houseSize: "",
          houseMRV: 0,
          totalNoOfPersons: 0,
          totalNoMale: 0,
          totalNoFemale: 0,
          otherGenderCount: 0,
          kidsBelowFive: 0,
          schoolStudent: 0,
          collegeStudent: 0,
          adultEmployees: 0,
          adultRetired: 0,
          adultUnemployed: 0,
          speciallyAbled: 0,
          currentPage: "HH-Page1"
        },

        pageTwo: {
          ...state.pageTwo,
          fourWheelerPetrol: 0,
          fourWheelerCNG: 0,
          fourWheelerElectric: 0,
          twoWheelerPetrol: 0,
          twoWheelerCNG: 0,
          twoWheelerElectric: 0,
          autoRickshawPetrol: 0,
          autoRickshawCNG: 0,
          autoRickshawElectric: 0,
          others: "",
          othersPetrol: 0,
          othersCNG: 0,
          othersElectric: 0,
          bicycle: 0,
          fourWheelerUsedAsTaxi: 0,
          fourWheelerEmployerProvided: 0,
          hhIncome: 0,
          noDailyDelivery: false,
          milk: false,
          newspaper: false,
          otherDailyDelivery: false,
          foodDeilvery: -10,
          groceryDelivery: -10,
          otherDelivery: -10,
          remarks: "",
        },
      }
    case "FLUSH_PERSON_INFO":
      return {
        ...state,
        pageThree: {
          answeredOnBehalf: -10,
          gender: -10,//
          age: -10,//
          relationWithPerson1: -10,//
          education: -10,///
          occupation: -10,//
          profession: -10,//
          income: -10,//
          workplaceAddressLat: "",//
          workplaceAddressLong: "",//
          workplaceAddressText: "",//
          schoolAddressLat: "",//
          schoolAddressLong: "",//
          schoolAddressText: "",//
          employerProvidedTravelOption: -10,//
          daysWorkedFromHome: -10,//
          daysWorkedFromHomeBeforeCOVID: -10,//
          drivingLicense: -10,//
          starttime: 0,//
          currentPage: "PI-Page3"
        },
        pageFour: {
          ...state.pageFour,
          privatePublicNone: false,
          privatePublicTwoWheeler: false,
          privatePublicMetro: false,
          privatePublicOwnCar: false,
          privatePublicBicycle: false,
          privatePublicBMTC: false,
          privatePublicCarPool: false,
          intermediatePublicNone: false,
          intermediatePublicAutoRickshaw: false,
          intermediatePublicRideHailingTwoWheeler: false,
          intermediatePublicOfficeCabVan: false,
          intermediatePublicRideHailingCar: false,
          intermediatePublicTaxi: false,
          intermediatePublicRentalVehicle: false,
          transitPassesNone: false,
          transitPassesDailyBus: false,
          transitPassesWeeklyBus: false,
          transitPassesMonthlyBus: false,
          transitPassesStudent: false,
          transitPassesMetro: false,
          transitPassesPremiumVajra: false,
          transitPassesVayuVajra: false,
          transitPassesOther: false,
          workTravel: 0,
          workTravelBeforeCOVID: 0,
          outHome: -10,
          dateOfTrip: "",
          noOfTrips: 0,
          remarksPI: "",
        },
      };
    case "FLUSH_TRIP_INFO":
      return {
        ...state,
        pageFive: {
          // legTable:{
          //     legMode:-10,
          //     legTt:0,
          //     legCost:0
          // },
          legs: [],
          legNo: 0,
          legMode: -10,
          legTt: 0,
          legCost: 0,
          tripOriginAddress: "",
          tripOriginLat: "",
          tripOriginLong: "",
          tripStartTime: "",
          tripDestAddress: "",
          tripDestLat: "",
          tripDestLong: "",
          tripMainPurpose: "",
          tripotherPurpose: "",
          tripEndtime: "",
          tripTotaltime: 0,
          tripDesttimeSpent: 0,
          tripMainMode: "",
          riderType: -10,
          tripFare: 0,
          tripParking: -10,
          tripParkingCost: 0,
          tripFamilyMbrCount: 0,
          tripNonFamilyMbrCount: 0,
          tripStopsDone: 0,
          tripStopCount: 0,
          nextTripNonTransitModeUsage: -10,
          nextTripNonTransitMode: -10,
          currentPage: "TI-Page1"
        },
        pageSix: {
          tripStop1Purpose: -10,
          tripStop1InTheRoute: -10,
          tripStop1AddlDistance: 0,
          tripStop1MainModeUsage: -10,
          tripStop1OtherMode: -10,
          tripStop1Tt: 0,
          tripStop1TimeSpent: 0,
          tripStop2Purpose: -10,
          tripStop2InTheRoute: -10,
          tripStop2AddlDistance: 0,
          tripStop2MainModeUsage: -10,
          tripStop2OtherMode: -10,
          tripStop2Tt: 0,
          tripStop2TimeSpent: 0,
          tpNotHomeReason: "",
          moreTrips: -10,
          oosVillage: "",
          oosDistrict: "",
          oosState: "",
          oosMode: -10,
          sourceTransitStationAddress: "",
          sourceTransitStationLat: "",
          sourceTransitStationLong: "",
          sourceTrasitStationMode: -10,
          currentPage: "TI-Page2"
        },
      };

    case "FLUSH_NEW_SURVEY":
      return {
        ...state,
        pageZero: {
          wardNo: 1,
          locationCode: "",
          ...state.pageZero
        },
      }
    case "IS_EDITING":
      return {
        ...state,
        isEditing: action.payload.isEditing
      }

    case "EDIT_TRIP":
      return {
        ...state,
        pageFive: {
          ...state.pageFive,
          legs: action.payload.data.leg_details,
          tripOriginAddress: action.payload.data.trip_origin_address,
          tripOriginLat: action.payload.data.trip_origin_lat,
          tripOriginLong: action.payload.data.trip_origin_long,
          tripStartTime: action.payload.data.trip_start_time,
          tripDestAddress: action.payload.data.trip_dest_address,
          tripDestLat: action.payload.data.trip_dest_lat,
          tripDestLong: action.payload.data.trip_dest_long,
          tripMainPurpose: action.payload.data.trip_main_purpose,
          tripotherPurpose: action.payload.data.trip_other_purpose,
          tripEndtime: action.payload.data.trip_end_time,
          tripTotaltime: action.payload.data.trip_total_time,
          tripDesttimeSpent: action.payload.data.trip_dest_time_spent,
          tripMainMode: action.payload.data.trip_main_mode,
          riderType: action.payload.data.rider_type,
          tripFare: action.payload.data.trip_fare,
          tripParking: action.payload.data.trip_parking,
          tripParkingCost: action.payload.data.trip_parking_cost,
          tripFamilyMbrCount: action.payload.data.trip_family_mbr_count,
          tripNonFamilyMbrCount: action.payload.data.trip_non_family_mbr_count,
          tripStopsDone: action.payload.data.trip_stops_done,
          tripStopCount: action.payload.data.trip_stop_count,
          nextTripNonTransitModeUsage: action.payload.data.next_trip_non_transit_mode_usage,
          nextTripNonTransitMode: action.payload.data.next_trip_non_transit_mode
        },
        pageSix: {
          ...state.pageSix,
          tripStop1Purpose: action.payload.data.trip_stop1_purpose,
          tripStop1InTheRoute: action.payload.data.trip_stop1_in_the_route,
          tripStop1AddlDistance: action.payload.data.trip_stop1_addl_distance,
          tripStop1MainModeUsage: action.payload.data.trip_stop1_main_mode_usage,
          tripStop1OtherMode: action.payload.data.trip_stop1_other_mode,
          tripStop1Tt: action.payload.data.trip_stop1_tt,
          tripStop1TimeSpent: action.payload.data.trip_stop1_time_spent,
          tripStop2Purpose: action.payload.data.trip_stop2_purpose,
          tripStop2InTheRoute: action.payload.data.trip_stop2_in_the_route,
          tripStop2AddlDistance: action.payload.data.trip_stop2_addl_distance,
          tripStop2MainModeUsage: action.payload.data.trip_stop2_main_mode_usage,
          tripStop2OtherMode: action.payload.data.trip_stop2_other_mode,
          tripStop2Tt: action.payload.data.trip_stop2_tt,
          tripStop2TimeSpent: action.payload.data.trip_stop2_time_spent,
          tpNotHomeReason: action.payload.data.tp_not_home_reason,
          moreTrips: action.payload.data.more_trips,
          oosVillage: action.payload.data.oos_village,
          oosDistrict: action.payload.data.oos_district,
          oosState: action.payload.data.oos_state,
          oosMode: action.payload.data.oos_mode,
          sourceTransitStationAddress: action.payload.data.source_transit_station_address,
          sourceTransitStationLat: action.payload.data.oos_lat,
          sourceTransitStationLong: action.payload.data.oos_long,
          sourceTrasitStationMode: action.payload.data.source_transit_station_mode
        }
      };
    default:
      return state;
  }
}

export default StateReducer;





